import { v4 as uuidV4 } from 'uuid';
import { store } from '../config/redux-store';
import formatNumber from './formatNumber';

const calculatePercentageOrQuantity = ({ key, newList, parentIndex, _value }) => {
  const parentQuantity = Number(newList[parentIndex]?.quantity || 0);
  if (parentQuantity === 0 || Number.isNaN(parentQuantity)) return 0;
  return key === 'quantity' ? (_value * 100) / parentQuantity : (_value * parentQuantity).toFixed(2);
};

const updateListOnEdit = ({
  originalList,
  type,
  value,
  row,
  multipleEdit,
  childrenColumnName,
  index,
  key,
  object,
  objectKey
}) => {
  const reduxState = store.getState();
  const { refurbishItemType } = reduxState.setup.enums;
  const _value = key === 'percentage' ? value / 100 : value;
  const newList = [...originalList];
  if (type === refurbishItemType?.parent) {
    const parentIndex = newList.findIndex(_item => _item.id === row.idParent);

    if (multipleEdit) {
      multipleEdit.forEach(pair => {
        newList[parentIndex][childrenColumnName][index][pair?.key] = pair?.value;
      });
    } else {
      newList[parentIndex][childrenColumnName][index][key] = value;
    }

    if (['quantity', 'percentage'].includes(key)) {
      const result = calculatePercentageOrQuantity({ key, newList, parentIndex, _value });
      const oppositeKey = key === 'quantity' ? 'percentage' : 'quantity';
      newList[parentIndex][childrenColumnName][index][oppositeKey] = result;
    }
  } else {
    newList[index][key] = value;
    if (key === 'price') {
      const levels = newList[index]?.[childrenColumnName] || [];
      levels.forEach((_, levelIndex) => {
        newList[index][childrenColumnName][levelIndex][key] = value || 0;
      });
    }
    if (object && objectKey) newList[index][objectKey] = object;

    if (key === 'quantity') {
      const levels = newList[index]?.[childrenColumnName] || [];
      levels.forEach((level, levelIndex) => {
        if (!value) {
          newList[index][childrenColumnName][levelIndex][key] = 0;
          newList[index][childrenColumnName][levelIndex].percentage = 0;
        } else {
          newList[index][childrenColumnName][levelIndex][key] = (value * (level?.percentage / 100)).toFixed(2);
        }
      });
    }
  }

  return newList;
};

const updateListOnDelete = ({ originalList, idParent, childrenColumnName, id }) => {
  let newList = [...originalList];

  if (idParent) {
    const parentIndex = newList.findIndex(_item => _item.id === idParent);
    const newChildren = newList[parentIndex][childrenColumnName].filter(_item => _item.id !== id);
    newList[parentIndex][childrenColumnName] = newChildren?.length ? newChildren : null;
    newList[parentIndex].quantity = newChildren?.length
      ? newChildren.reduce((acc, curr) => acc + Number(curr.quantity), 0)
      : 0;
  } else {
    newList = originalList.filter(_item => _item.id !== id);
  }

  return newList;
};

const formatAppropriation = ({ appropriation, idParent, parent }) => {
  const reduxState = store.getState();
  const { refurbishItemType } = reduxState.setup.enums;
  const appropriationQuantity = appropriation.quantity || 0;
  return {
    idUnit: appropriation?.unit?.id,
    name: appropriation?.name,
    unit: appropriation?.unit,
    price: parent.price || appropriation.price,
    type: refurbishItemType?.parent,
    id: uuidV4(),
    idParent,
    percentage: parent.quantity ? (appropriationQuantity * 100) / parent.quantity : 100,
    quantity: appropriationQuantity,
    refurbishItem: {
      name: appropriation?.name,
      id: appropriation?.id
    },
    idRefurbishItem: appropriation?.id
  };
};

const generateChildrenList = (item, childrenColumnName, newQuantity = 0) => {
  const newParentQuantity = formatNumber(item.quantity + (newQuantity || 0));

  return item?.[childrenColumnName]?.map(i => ({
    ...i,
    percentage: newParentQuantity ? (i.quantity / newParentQuantity) * 100 : 0
  }));
};

const formatCountToUseInFilterTab = ({ count, statusField, statusEnum }) => {
  return count?.reduce(
    (acc, { count: numRows, [statusField]: idStatus }) => {
      Object.entries(statusEnum).forEach(([key, value]) => {
        if (value !== idStatus) return;
        acc[key] = Number(numRows);
        acc.all += Number(numRows);
      });

      return acc;
    },
    { all: 0 }
  );
};

export {
  updateListOnEdit,
  updateListOnDelete,
  calculatePercentageOrQuantity,
  formatAppropriation,
  generateChildrenList,
  formatCountToUseInFilterTab
};
