import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar, faPlus } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import AvatarSelect from '../Select/AvatarSelect';
import DatePicker from '../Datepicker/Datepicker';
import Input from '../Input/Input';
import Label from '../Label/Label';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { getInitials, replaceDecimalNumber } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import { useContextHook } from '../../contexts/GeneralContext';
import useOutsideClick from '../../_Hooks/useOutsideClick';
import { Paragraph } from '../Text/Text';
import Avatar from '../AvatarEditor/Avatar';

const FormInputLabel = styled(Label)`
  color: ${colors.neutral600};
  font-weight: ${fonts.weight500};
  margin-bottom: 0;
  width: ${spaces.space16};
  flex-shrink: 0;

  ${props =>
    props.$isMobile &&
    css`
      font-size: ${fonts.sizeSm};
      font-weight: ${fonts.weight500};
    `}
`;

const FormInputDiv = styled(Div)`
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral600};
  height: ${spaces.space4};
  border: 1px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  padding: 0 ${spaces.space1};
  border-radius: ${spaces.space0};
  width: calc(100% - ${spaces.space16});
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 0;
  }

  .remove-icon {
    cursor: pointer;
    font-size: ${fonts.sizeMd};
    color: transparent;
    transition: color 0.3s ease;
  }

  ${props =>
    !props.readOnly &&
    css`
      &:hover {
        background-color: ${colors.neutral75};
        border-color: ${colors.neutral75};

        .remove-icon {
          color: ${colors.neutral600};

          &:hover {
            color: ${colors.neutral700};
          }
        }

        @media (max-width: ${breakpoints.tablet}) {
          background-color: transparent;
          border-color: transparent;
        }
      }
    `}
`;

const StyledFormItemDiv = styled(Div)`
  ${props =>
    props.$isMobile
      ? css`
          flex-direction: column;
          gap: 2px;
          align-items: flex-start;
          padding: ${spaces.space2} 0 11px 0;
          border-bottom: 1px solid ${colors.neutral100};
        `
      : css`
          width: 100%;
        `}
  width: ${props => props.width || '100%'};
`;

const FormItemCreatorV2 = () => {
  const {
    type,
    label,
    value,
    onChange,
    tooltip,
    placeholder,
    readOnly,
    icon,
    iconColor,
    handleFunctions,
    property,
    error,
    unit,
    decimalCount,
    clearable,
    externalOnChange = f => f,
    externalOnBlur = f => f,
    minDate,
    maxDate,
    ...props
  } = useContextHook();

  let element;
  const { setField, handleChange, handleBlur, handleChangeSelect, handleClear } = handleFunctions;
  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  let _value = value;

  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const handleInputBlur = () => {
    setIsInputFocused(false);
    if (externalOnBlur) {
      externalOnBlur(value || 0);
    }
  };

  useEffect(() => {
    if (!isOpen && isInputFocused) {
      handleInputBlur();
    }
  }, [isOpen, isInputFocused]);

  switch (type) {
    case 'date':
      element = (
        <DatePicker
          suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
          className="form-component"
          format="D MMM. YYYY"
          open
          autoFocus
          style={{ flexGrow: 1 }}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          value={value ? dayjs(value) : null}
          onChange={date => {
            setField(property)(date);
            setIsOpen(false);
            externalOnChange(date);
          }}
          disabled={readOnly}
          minDate={minDate}
          maxDate={maxDate}
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      if (value) _value = dayjs(value).format('D MMM. YYYY');
      break;
    case 'avatar':
      element = (
        <AvatarSelect
          style={{ flexGrow: 1 }}
          type="text"
          user={value}
          onChange={selectedId => {
            handleChangeSelect(property, selectedId, false, false);
            setIsOpen(false);
          }}
          className="form-component"
          cleanValue={null}
          open
          disabled={readOnly}
          {...props}
        />
      );
      _value = value?.id ? (
        <Avatar id={`${value?.id}-${value?.name}`} size="small" src={value?.avatarFullpath}>
          <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
            {getInitials(value?.name)}
          </Label>
        </Avatar>
      ) : (
        <>
          {readOnly ? (
            '-'
          ) : (
            <Div gap={spaces.space1}>
              <Avatar id={`${value?.id}-${value?.name}`} size="small" src={value?.avatarFullpath} dashed>
                <FontAwesomeIcon icon={faPlus} color={colors.neutral400} style={{ verticalAlign: '-1px' }} />
              </Avatar>
              <Label marginBottom="0px" color={colors.neutral400}>
                Selecione
              </Label>
            </Div>
          )}
        </>
      );
      break;
    case 'textarea':
      element = (
        <Input
          value={value}
          onChange={handleChange(property)}
          onBlur={handleBlur(property)}
          className="form-component"
          type="textarea"
          placeholder={placeholder}
          minRows={isMobile ? 1 : 3}
          {...props}
        />
      );
      break;
    case 'number':
      element = (
        <Input
          value={formatCurrency(value, {
            decimalCount:
              decimalCount !== undefined && decimalCount !== null ? decimalCount : replaceDecimalNumber(value)
          })}
          onChange={e => {
            const newValue = e.target.value.length ? e.target.value : 0;
            setField(property)(newValue);
          }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={handleInputBlur}
          className="form-component"
          placeholder={placeholder}
          readOnly={readOnly}
          unit={unit}
          autoFocus
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '-';
      if (value !== null && value !== undefined)
        _value = `${formatCurrency(value, {
          decimalCount: decimalCount !== undefined && decimalCount !== null ? decimalCount : replaceDecimalNumber(value)
        })} ${unit || ''}`;
      break;
    default:
      element = (
        <Input
          value={value}
          onChange={handleChange(property)}
          onBlur={handleBlur(property)}
          className="form-component"
          placeholder={placeholder}
          readOnly={readOnly}
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = value || placeholder || '-';
  }

  const ref = useRef(null);
  useOutsideClick(
    ref,
    () => {
      setIsOpen(false);
    },
    null,
    { classes: ['ant-picker-panel'], depth: 20 }
  );

  return (
    <StyledFormItemDiv $isMobile={isMobile} width={props.width} ref={ref}>
      <FormInputLabel $isMobile={isMobile}>{label}</FormInputLabel>
      {isOpen ? (
        element
      ) : (
        <FormInputDiv onClick={() => !readOnly && setIsOpen(true)} readOnly={readOnly}>
          {icon && (
            <Div
              width={spaces.space2}
              height={spaces.space2}
              align="center"
              justify="center"
              margin={`0 ${spaces.space0} 0 0`}
              style={{ flexShrink: 0, fontSize: fonts.sizeMd }}
            >
              <FontAwesomeIcon icon={icon} color={iconColor} />
            </Div>
          )}
          {tooltip ? (
            <Tooltip placement="bottomRight" title={value}>
              <Paragraph
                type={isMobile ? 'medium' : 'small'}
                ellipsis
                style={{ width: '100%' }}
                weight={readOnly ? fonts.weight600 : 'auto'}
              >
                {_value}
              </Paragraph>
            </Tooltip>
          ) : (
            <Paragraph
              type={isMobile ? 'medium' : 'small'}
              ellipsis
              style={{ width: '100%' }}
              color={readOnly || value !== null ? colors.neutral600 : colors.neutral400}
              weight={readOnly ? fonts.weight600 : 'auto'}
            >
              {_value}
            </Paragraph>
          )}
          {!readOnly && clearable && value !== '' && value !== null && value !== undefined && (
            <Div width={spaces.space3} height={spaces.space3} align="center" justify="center" style={{ flexShrink: 0 }}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                onClick={e => {
                  e.stopPropagation();
                  handleClear(property);
                  externalOnBlur && externalOnBlur(null);
                }}
                className="remove-icon"
              />
            </Div>
          )}
        </FormInputDiv>
      )}
    </StyledFormItemDiv>
  );
};

FormItemCreatorV2.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  readOnly: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  iconColor: PropTypes.string,
  handleFunctions: PropTypes.shape({
    setField: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChangeSelect: PropTypes.func.isRequired
  }).isRequired,
  property: PropTypes.string.isRequired,
  error: PropTypes.string
};

export default FormItemCreatorV2;
