import styled from 'styled-components';
import Button from '../Button/Button';

const StyledFloatingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const DraggableDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export { StyledFloatingButton, DraggableDiv };
