import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Popover } from 'antd';
import Button from '../Button/Button';
import { performedHoursSchema, performedHoursMapping } from '../../lib/mapping/Form/performedHoursSchema';
import Form from '../Form/Form';
import useCRUD from '../../_Hooks/useCRUD';
import { useContextHook as useRefurbish } from '../../contexts/Context';
import useViewport from '../../_Hooks/useViewport';

import { Header, Content, Footer } from './AddPerformedHourDropdown.styled';
import { Paragraph } from '../Text/Text';
import { colors, spaces } from '../../styles/style';

const AddPerformedHourDropdown = ({ idCompany, afterSubmit = f => f, generalTask }) => {
  const { user } = useSelector(state => state.authReducer);
  const { execution } = useSelector(state => state.setup.enums.refurbishStatus);
  const { data: refurbish } = useRefurbish();
  const [responsible, setResponsible] = useState(user);
  const [timePerformed, setTimePerformed] = useState(0);
  const { isMobile } = useViewport(window.innerWidth);
  const [openHoursDropdown, setOpenHoursDropdown] = useState(false);

  const idRefurbish = refurbish?.id;

  const { handleCreate: createPerformedHour } = useCRUD({
    model: 'performedHours',
    immediatelyLoadData: false
  });

  const _performedHoursMapping = performedHoursMapping({
    idRefurbish,
    idCompany,
    execution,
    responsible,
    setResponsible,
    timePerformed,
    setTimePerformed,
    generalTask,
    isMobile: isMobile()
  });

  const handleSubmit = formData => {
    const values = { ...formData, idRefurbish: undefined, creationType: 'add' };
    if (!values.idTask || !values.timePerformed) return;
    createPerformedHour({
      values,
      displayToast: 'Horas adicionadas com sucesso.',
      refresh: false
    }).then(resp => {
      afterSubmit(resp?.idTask);
      setOpenHoursDropdown(false);
    });
  };

  const stopProp = e => {
    e.stopPropagation();
  };

  const header = (
    <Header onMouseDown={stopProp}>
      <Paragraph>Registrar hora trabalhada</Paragraph>
      <Button type="text" padding={0} onClick={() => setOpenHoursDropdown(false)}>
        <FontAwesomeIcon icon={faXmark} color={colors.neutral400} size="lg" />
      </Button>
    </Header>
  );

  const content = (
    <Content onMouseDown={stopProp}>
      <Form
        schema={performedHoursSchema}
        mapping={_performedHoursMapping}
        id="performedHoursForm"
        onSubmit={handleSubmit}
        displayButtons={false}
        data={{
          idResponsible: responsible.id,
          date: new Date(),
          idTask: null,
          idRefurbish: null,
          timePerformed: 0
        }}
        childWidth="100%"
        childHeight={spaces.space6}
      >
        <Footer>
          <Button id="add-performed-hour" type="primary" htmlType="submit">
            Adicionar hora
          </Button>
        </Footer>
      </Form>
    </Content>
  );

  return (
    <Popover
      content={content}
      title={header}
      trigger="click"
      open={openHoursDropdown}
      placement="topRight"
      onOpenChange={newOpen => setOpenHoursDropdown(newOpen)}
    >
      <Button id="draggable-add-hour" type="link">
        Registrar horas trabalhadas
      </Button>
    </Popover>
  );
};

AddPerformedHourDropdown.propTypes = {
  idCompany: PropTypes.number,
  afterSubmit: PropTypes.func,
  generalTask: PropTypes.bool
};

export default AddPerformedHourDropdown;
