import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import useCRUD from '../../_Hooks/useCRUD';
import { TabContent } from '../Modal/EditItemModal.styles';
import CatalogCard from '../Card/CatalogCard';
import Select from '../Select/Select';
import { ITEMS_PAGE, images } from '../../lib/mapping/Tabs/catalogTab';
import { spaces } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';
import { useContextHook as useParentModel } from '../../contexts/Context';
import { Paragraph } from '../Text/Text';

const ExternalBaseTab = ({
  cardProps,
  isComposition,
  onSelect,
  keyword,
  tabIndex,
  offset,
  setOffset,
  fixedType,
  disableCardButton,
  updateFilters = true
}) => {
  const { data: parentModel, handleUpdate: updateParentModel } = useParentModel();
  const { sinapiFilter } = parentModel || {};
  const { refurbishItemType, externalBase } = useSelector(state => state.setup.enums);
  const { socialCharges, refurbishItemTypes } = useSelector(state => state.setup.systemData);
  const [queryString, setQueryString] = useState();
  const [displayList, setDisplayList] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [state, setState] = useState(sinapiFilter?.state || parentModel?.state || 'SP');
  const [monthReference, setMonthReference] = useState(sinapiFilter?.monthReference);
  const [priceWithSocialCharges, setPriceWithSocialCharges] = useState(sinapiFilter?.priceWithSocialCharges || false);
  const [itemType, setItemType] = useState(
    !fixedType || fixedType === refurbishItemType.composition || fixedType?.includes(refurbishItemType.composition)
      ? refurbishItemType.composition
      : refurbishItemType.product
  );
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const { loading, handleGet } = useCRUD({ model: 'item/sinapi', immediatelyLoadData: false });
  const { list: months } = useCRUD({ model: 'item/sinapi/month' });

  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const handleSearchSuggestion = value => {
    setQueryString({ ...queryString, offset: 1, keyword: value, where: {} });
  };

  useEffect(() => {
    if (!queryString || !state || !monthReference || !itemType) return;

    const { keyword: _keyword, where } = queryString || {};
    const type = where?.type || itemType;

    handleGet({
      refetchOptions: {
        onlyNull: true,
        where: { ...where, type, source: externalBase.all, name: _keyword },
        offset,
        limit: ITEMS_PAGE
      },
      generateLoading: offset <= 1
    }).then(resp => {
      if (!resp.length && offset > 1) setHasReachedEnd(true);
      if (offset === 1) setDisplayList(resp);
      else setDisplayList(prev => prev?.concat(resp));
    });
  }, [queryString]);

  useEffect(() => {
    const hasItemsToLoad = !hasReachedEnd && !loading;
    if (offset > 1 && hasItemsToLoad) setQueryString({ ...queryString, offset });
  }, [offset]);

  useEffect(() => {
    if (months?.length) {
      setMonthReference(sinapiFilter?.monthReference || months[0]);
    }
  }, [months]);

  useEffect(() => {
    if (tabIndex === 1) {
      setOffset(1);
      setHasSearched(true);
      setHasReachedEnd(false);
      setQueryString({
        ...queryString,
        offset: 1,
        keyword,
        where: {
          state,
          priceWithSocialCharges,
          monthReference,
          type: itemType
        }
      });
      if (parentModel && updateFilters) {
        updateParentModel({
          updatePathOptions: `/${parentModel?.id}`,
          values: { sinapiFilter: { state, monthReference, priceWithSocialCharges } },
          noLoading: true
        });
      }
    }
  }, [priceWithSocialCharges, state, monthReference, itemType, keyword, tabIndex]);

  return (
    <TabContent>
      <Space size={8}>
        <div style={{ width: 200 }}>
          <div>
            <Paragraph type="small">Estado</Paragraph>
          </div>
          <Select
            placeholder="Selecione..."
            dataType="states"
            value={state}
            onChange={setState}
            dropdownMatchSelectWidth={false}
            id="select-state"
          />
        </div>
        <div>
          <div>
            <Paragraph type="small">Mês de referência</Paragraph>
          </div>
          <Select
            placeholder="Selecione..."
            options={months?.map(month => ({ label: month, value: month }))}
            value={monthReference}
            onChange={setMonthReference}
            dropdownMatchSelectWidth={false}
            id="select-month-reference"
            allowSearch={false}
            firstAsDefault
          />
        </div>
        <div style={{ width: 200 }}>
          <div>
            <Paragraph type="small">Desoneração</Paragraph>
          </div>
          <Select
            placeholder="Selecione..."
            options={socialCharges}
            value={priceWithSocialCharges}
            onChange={setPriceWithSocialCharges}
            dropdownMatchSelectWidth={false}
            id="select-social-charges"
          />
        </div>
        <div style={{ width: 200 }}>
          <div>
            <Paragraph type="small">Tipo</Paragraph>
          </div>
          <Select
            options={refurbishItemTypes?.filter(r => {
              return (
                (!fixedType || fixedType === r?.value || (Array.isArray(fixedType) && fixedType?.includes(r?.value))) &&
                !r.hideFilter
              );
            })}
            placeholder="Selecione..."
            value={itemType}
            allowClear
            onChange={setItemType}
            dropdownMatchSelectWidth={false}
            id="select-item-type"
          />
        </div>
      </Space>

      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          {!displayList.length ? (
            <NoContentInfo keyword={keyword} onClick={handleSearchSuggestion} />
          ) : (
            <div style={{ paddingTop: spaces.space2 }} id="external-library">
              {displayList &&
                Array.isArray(displayList) &&
                displayList?.map(_data => (
                  <React.Fragment key={_data?.id}>
                    <CatalogCard
                      {...cardProps}
                      tags={[_data?.state, _data?.monthReference, _data?.socialCharges]}
                      renderInComposition={isComposition}
                      id={_data?.id}
                      data={{ ..._data }}
                      onSelect={onSelect}
                      className="catalogItem"
                      disableCardButton={disableCardButton}
                      addButtonId="add-external-base"
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </>
      )}
    </TabContent>
  );
};

ExternalBaseTab.propTypes = {
  cardProps: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  isComposition: PropTypes.bool,
  keyword: PropTypes.string,
  tabIndex: PropTypes.number,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
  fixedType: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disableCardButton: PropTypes.bool,
  updateFilters: PropTypes.bool
};

export default ExternalBaseTab;
