import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { Div, colors, spaces } from '../../styles/style';
import { Paragraph, Title } from '../Text/Text';
import useViewport from '../../_Hooks/useViewport';
import { capitalize } from '../../lib/helpers/helper';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import Button from '../Button/Button';

const HeaderItem = styled(Div)`
  gap: ${spaces.space1};
`;

const PurchaseHeader = ({
  readOnly,
  code,
  data,
  refurbish,
  linkedSolicitation,
  linkedQuote,
  type = 'purchase',
  isCustomer = false
}) => {
  const {
    purchaseSolicitationStatusColor,
    purchaseOrderStatusColor,
    purchaseQuoteStatusColor,
    priority: priorityEnum = {}
  } = useSelector(state => state.setup.enums);
  const { isMobile } = useViewport(window.innerWidth);

  const _isMobile = isMobile();

  const priorityColorsMap = {
    [priorityEnum.high.id]: colors.red500,
    [priorityEnum.average.id]: colors.orange400,
    [priorityEnum.low.id]: colors.primary600
  };

  const DeadlineLine = (
    <HeaderItem>
      <strong>Prazo:</strong>
      <Paragraph>{dayjs(data?.responseDate).format('DD/MM/YYYY')}</Paragraph>
    </HeaderItem>
  );

  const typeMap = {
    order: {
      responsibleLineExtra: <></>,
      title: 'Ordem de compra',
      statusColor: purchaseOrderStatusColor[data?.idOrderStatus],
      shouldRenderRefurbishLine: false
    },
    purchase: {
      responsibleLineExtra: (
        <HeaderItem>
          <strong>Criado por:</strong>
          <Paragraph>{data?.createdBy?.name}</Paragraph>
        </HeaderItem>
      ),
      title: 'Solicitação de compra',
      statusColor: purchaseSolicitationStatusColor[data?.idPurchaseStatus],
      shouldRenderRefurbishLine: true
    },
    quote: {
      responsibleLineExtra: (
        <>
          {data?.responseDate && DeadlineLine}
          <HeaderItem>
            <strong>Criado por:</strong>
            <Paragraph>{data?.createdBy?.name}</Paragraph>
          </HeaderItem>
        </>
      ),
      title: 'Cotação',
      statusColor: purchaseQuoteStatusColor[data?.idQuoteStatus],
      shouldRenderRefurbishLine: false,
      howDoesItWorksButtonId: 'HowQuotationDetailsWork'
    }
  };

  const RefurbishLine = (
    <HeaderItem>
      <strong>Projeto:</strong>
      <Paragraph>{refurbish?.name || data?.refurbish?.name}</Paragraph>
    </HeaderItem>
  );

  const NameLine = (
    <HeaderItem>
      <strong>Nome:</strong>
      <Paragraph>{data?.name}</Paragraph>
    </HeaderItem>
  );

  const ResponsibleLine = (
    <>
      {typeMap[type].responsibleLineExtra}
      {data?.responsible ? (
        <HeaderItem>
          <strong>Responsável:</strong>
          <Paragraph>{data?.responsible?.name}</Paragraph>
        </HeaderItem>
      ) : null}
      <HeaderItem>
        <strong>Data de criação:</strong>
        <Paragraph>{dayjs(data?.createdAt).format('DD/MM/YYYY [às] HH[h]mm')}</Paragraph>
      </HeaderItem>
    </>
  );

  const LinkedLine = (
    <>
      {linkedSolicitation && (
        <HeaderItem>
          <strong>Solicitação vinculada:</strong>
          <Paragraph color={colors.primary500}>#{linkedSolicitation}</Paragraph>
        </HeaderItem>
      )}
      {linkedQuote && (
        <HeaderItem>
          <strong>Cotação vinculada:</strong>
          <Paragraph color={colors.primary500}>#{linkedQuote}</Paragraph>
        </HeaderItem>
      )}
    </>
  );

  const ActionHeader = (
    <HeaderItem>
      {type === 'quote' && data?.id && (
        <Button
          id="view-as-client"
          type="primary"
          onClick={() => window.open(`/qs/${data?.id}`, '_blank')}
          icon={<FontAwesomeIcon size="lg" icon={faEye} />}
          text
        >
          {!isMobile() ? 'Ver como fornecedor' : ''}
        </Button>
      )}
      <HowDoesItWorksButton id={typeMap[type].howDoesItWorksButtonId} />
    </HeaderItem>
  );

  const title = (
    <Title id="purchase-header-title">
      {typeMap[type].title} <span style={{ color: colors.primary500 }}>#{data?.code || code}</span>
    </Title>
  );

  const StatusAndPriority = (
    <Div gap={spaces.space2} direction={_isMobile ? 'column' : 'row'} align={_isMobile ? 'flex-start' : 'center'}>
      <HeaderItem>
        <strong>Status:</strong>
        <Paragraph color={colors[typeMap[type].statusColor || 'orange400']} id="purchase-status">
          {data?.purchaseStatus?.name || 'Rascunho'}
        </Paragraph>
      </HeaderItem>
      <HeaderItem>
        <strong>Prioridade:</strong>
        <Paragraph color={priorityColorsMap[data?.priority]}>
          {capitalize(Object.values(priorityEnum).find(value => value?.id === data?.priority)?.name)}
        </Paragraph>
      </HeaderItem>
    </Div>
  );

  if (isCustomer) {
    return (
      <Div direction="column" align="flex-start" gap={spaces.space2}>
        {title}
        <Div direction="column" gap={spaces.space2} align="left">
          {RefurbishLine}
          {NameLine}
          {DeadlineLine}
          {StatusAndPriority}
        </Div>
      </Div>
    );
  }

  return readOnly ? (
    <Div justify="space-between" align="flex-start" $fullWidth padding={spaces.space2}>
      <Div direction="column" align="flex-start" gap={spaces.space1}>
        {title}
        {(refurbish || data?.refurbish) && RefurbishLine}
        {NameLine}
        {ResponsibleLine}
        {StatusAndPriority}
        {(refurbish || data?.refurbish) && typeMap[type].shouldRenderRefurbishLine && RefurbishLine}
        {LinkedLine}
      </Div>
      {!_isMobile && ActionHeader}
    </Div>
  ) : (
    <Div justify="space-between" padding={spaces.space2} $fullWidth>
      <Div
        justify="flex-start"
        gap={spaces.space3}
        wrap="wrap"
        direction={_isMobile && 'column'}
        align={_isMobile ? 'flex-start' : 'center'}
      >
        <HeaderItem>
          <strong>Código:</strong>
          <Paragraph>{data?.code || code}</Paragraph>
        </HeaderItem>
        {LinkedLine}
        <HeaderItem>
          <strong>Status:</strong>
          <Paragraph color={colors[purchaseSolicitationStatusColor[data?.idPurchaseStatus] || 'orange400']}>
            {data?.purchaseStatus?.name || 'Rascunho'}
          </Paragraph>
        </HeaderItem>
      </Div>
      {!_isMobile && ActionHeader}
    </Div>
  );
};

PurchaseHeader.propTypes = {
  readOnly: PropTypes.bool,
  code: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  refurbish: PropTypes.instanceOf(Object),
  linkedSolicitation: PropTypes.string,
  linkedQuote: PropTypes.string,
  type: PropTypes.string,
  isCustomer: PropTypes.bool
};

export default PurchaseHeader;
