import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Space } from 'antd';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import useViewport from '../../_Hooks/useViewport';

import { Configuration, ConfigurationCard, ContentCreditConfirmation, Grid } from './PaymentConfiguration.styles';
import { useContextHook } from '../../contexts/GeneralContext';
import useCRUD from '../../_Hooks/useCRUD';

import { columns as paymentConfigurationColumns } from '../../lib/mapping/TableOrList/paymentConfigurationColumns';

import { Div, colors, spaces } from '../../styles/style';

import {
  calculatePercentage,
  calculatePriceByPercentage,
  generateInstallments,
  recalculateInstallments
} from '../../lib/helpers/installment';
import { useIsMount } from '../../_Hooks/useIsMount';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import Segment from '../Select/Segmented';
import PaymentRecurrence from './PaymentRecurrence';
import CardPaymentType from '../Card/CardPaymentType';
import { calculateInstallments, getPaymentLabel } from '../../lib/helpers/payment';
import VobiPayPayment from '../VobiPay/VobiPayPayment';
import { Paragraph, Subtitle } from '../Text/Text';
import ConfirmModal from '../Modal/ConfirmModal';
import InstallmentConfiguration from './InstallmentConfiguration';
import { paymentChildren } from '../../lib/mapping/Dropdown/paymentDropdown';
import PaymentDropDown from '../Dropdown/PaymentDropDown';
import AttachmentList from '../List/AttachmentList';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import { Content } from '../../_Pages/Payments/Payment.style';

const PaymentConfiguration = ({ onSubmitDropdown, isV2, idRefurbish }) => {
  const { user } = useSelector(state => state.authReducer) || {};
  const {
    values,
    setField,
    isView,
    isPublic,
    errors,
    touched,
    isRevision,
    isCustomerView,
    valuesChanged,
    handleSave,
    handleBlur,
    handleLoad,
    _user,
    hasPermissionVP = false,
    isConciliation
  } = useContextHook();

  const {
    name,
    installments,
    paymentTypes,
    total: chargeTotal,
    value: billValue,
    id,
    billingDate,
    isCharge,
    billType,
    paymentRecurrence,
    recurrenceId,
    isVobiPay,
    installmentCount,
    dueDate,
    companyCustomer,
    company,
    paidValue,
    openAmount,
    paymentComplete,
    dueDateDiscount,
    dueDateDiscountType,
    dueDateLimitDays,
    fine,
    fineType,
    interest,
    subTotal,
    allInterest,
    allDueDateDiscount
  } = values || {};
  const optionsPayment = [
    { value: false, label: 'Parcelamento', isRecurrence: false },
    {
      value: true,
      label: 'Recorrência',
      isRecurrence: true,
      disabled: paymentComplete,
      tooltip: paymentComplete ? 'Lançamentos com recorrência não podem ter produtos/serviços habilitados.' : null
    }
  ];
  const _billValue = id && paymentComplete ? subTotal + allInterest - allDueDateDiscount || 0 : billValue || 0;
  const total = isCharge || paymentComplete ? chargeTotal || 0 : _billValue;
  const totalToCalculateInstallment = paymentComplete
    ? (total || 0) - (allInterest || 0) + (allDueDateDiscount || 0)
    : total;
  const {
    installmentStatuses,
    paymentStatuses,
    vobiPayPaymentType,
    maxLimitPayment,
    fineInterestDiscountType: { percentage },
    maxInstallmentLength = 60,
    billType: billTypeEnum
  } = useSelector(state => state.setup.enums);
  const defaultTypesNameList = paymentTypes?.map(type => type?.label || type?.name);
  const [options, setOptions] = useState([]);
  const [numberOfInstalments, setNumberOfInstalments] = useState(installments?.length || 0);
  const isMount = useIsMount();
  const isRecurrence = paymentRecurrence || recurrenceId;
  const [_paymentRecurrence, setPaymentRecurrence] = useState(isRecurrence);
  const [installmentToPay, setInstallmentToPay] = useState(null);
  const [highlightDiv, setHighlightDiv] = useState(1);
  const [successModal, setSuccessModal] = useState({ open: false });
  const [openFilesModal, setOpenFilesModal] = useState({ open: false, list: [] });
  const [openRequestSave, setOpenRequestSave] = useState(false);

  useEffect(() => {
    setInstallmentToPay(
      installments?.find(installment => installment?.idInstallmentStatus === installmentStatuses.pendingPayment)?.id
    );
  }, [paidValue, openAmount]);

  const countInstallments = id && isVobiPay ? installments?.length : installmentCount;

  const vobiPayPaymentTypes = isVobiPay && id ? paymentTypes?.map(type => type?.id) : paymentTypes;
  const disabledToCreditType = vobiPayPaymentTypes?.includes(vobiPayPaymentType?.creditCard);
  const { handleGet: handleGetInstallment } = useCRUD({
    model: 'installment',
    immediatelyLoadData: false
  });

  const { data: fees } = useCRUD({
    model: 'pay',
    pathOptions: `/accounts/fees`,
    immediatelyLoadData: !isCustomerView && !isPublic && hasPermissionVP && isVobiPay
  });

  const { isMobile } = useViewport(window.innerWidth);

  const row = useRef();
  const installmentsRef = useRef(installments);

  useEffect(() => {
    installmentsRef.current = installments;
  }, [installments]);

  const handleChange = (index, field, value) => {
    row.current = {
      index,
      installment: installments[index]
    };
    row.current.installment[field] = value;
    if (field === 'percentage') row.current.installment.price = calculatePriceByPercentage(value, total);
    else if (field === 'price') row.current.installment.percentage = calculatePercentage(value, total);
    else {
      installments[index] = row.current.installment;
      setField('installments')(installments);
      row.current = null;
    }
  };

  const installmentHandleBlur = e => {
    const result = recalculateInstallments({ current: row.current, value: total, installments });
    if (!result) return;
    if (result.error) {
      toast.error(result.error);
      return;
    }
    handleBlur('installments', e);
    setField('installments')(result?.installments || result);
    row.current = null;
  };

  const updateFieldWhenChangingNumberOfInstallments = number => {
    const newInstallments = generateInstallments({
      quantity: number,
      total: totalToCalculateInstallment,
      installments,
      otherData: {
        paymentName: name,
        idCompany: user?.idCompany,
        idPayment: id,
        billingDate
      }
    });
    if (!Array.isArray(newInstallments) && newInstallments.error) {
      toast.error(newInstallments.error);
      return;
    }
    setNumberOfInstalments(newInstallments.length);
    setField('installments')(newInstallments);
  };

  useEffect(() => {
    const array = [
      {
        label: 'Selecione',
        value: 0
      }
    ];

    for (let i = 1; i <= maxInstallmentLength; i++) {
      array.push({ label: `${i}x`, value: i });
    }

    setOptions(array);
  }, []);

  useEffect(() => {
    if (isMount || isView) return;
    updateFieldWhenChangingNumberOfInstallments(numberOfInstalments);
  }, [total]);

  useEffect(() => {
    if (options.length > 0) {
      setNumberOfInstalments(installments?.length || 0);
    }
  }, [installments, options]);

  const showInstallmentActions = item => {
    const isViewOrEdit = isView || id != null;

    const customerVobiPay = isCustomerView && id && isVobiPay;

    const installmentHasId = item ? item.id : installments?.some(i => i?.id);

    return (
      installmentHasId &&
      isViewOrEdit &&
      !isPublic &&
      values?.idPaymentStatus !== paymentStatuses.cancelled &&
      values?.idPaymentStatus !== paymentStatuses.draft &&
      !isRevision &&
      !customerVobiPay
    );
  };

  const getPaymentConditionTooltipText = () => {
    if (isCharge)
      return `Para interagir o valor total ${isV2 ? 'do pagamento' : 'da cobrança'} deve ser diferente de zero`;
    return `Para interagir o valor da ${billType === 'expense' ? 'despesa' : 'receita'} deve ser diferente de zero`;
  };

  const handleSegmentedChange = e => {
    setPaymentRecurrence(e);
    setField('paymentRecurrence')(e);
  };

  const refreshRow = (idInstallment, isUploadFile) => {
    if (!installments.length || !idInstallment) return;
    handleGetInstallment({
      refetchOptions: {
        where: { id: idInstallment },
        include: ['installmentStatus', 'paymentType']
      }
    }).then(result => {
      if (!result?.length) return;
      const _installments = [...installmentsRef.current];
      const [newInstallment] = result;
      const index = _installments.findIndex(item => item.id === idInstallment);
      if (index !== -1) {
        if (isUploadFile) _installments[index] = { ..._installments[index], countFile: newInstallment?.countFile };
        else
          _installments[index] = {
            ...newInstallment,
            dueDate: _installments[index]?.dueDate,
            percentage: _installments[index]?.percentage,
            description: _installments[index]?.description
          };
      }

      setField('installments')(_installments);
      installmentsRef.current = _installments;
    });
  };

  const handleSubmitDropdown = (arg1, arg2, isUploadFile) => {
    const idInstallment = arg1?.id || arg2?.id || arg2?.[0];
    refreshRow(idInstallment, isUploadFile);
    onSubmitDropdown && onSubmitDropdown();
  };

  const RenderCards = (
    <ConfigurationCard className="hide-on-print">
      <Subtitle type="secondary">Forma de pagamento</Subtitle>
      {vobiPayPaymentTypes?.map(type => (
        <React.Fragment key={type}>
          <CardPaymentType
            value={
              calculateInstallments({ totalValue: chargeTotal, maxLimitPayment })?.find(
                data => data?.value === countInstallments
              )?.price || chargeTotal
            }
            installmentCount={countInstallments}
            paymentTypes={vobiPayPaymentTypes}
            disabled
            type={vobiPayPaymentType[type]}
            fees={fees}
            idPayment={id}
            tooltipText="Não é possível alterar a forma de pagamento após criação da cobrança"
          />
        </React.Fragment>
      ))}
    </ConfigurationCard>
  );

  const RenderModalCreditPayment = (
    <ConfirmModal
      submitText="Entendi"
      hideCancel
      alignButtonRight
      modalWidth={576}
      title={`Pagamento ${successModal?.type === 'CONFIRMED' ? 'confirmado' : 'não realizado'}`}
      onClose={() => setSuccessModal({ open: false })}
      onSubmit={() => setSuccessModal({ open: false })}
    >
      <ContentCreditConfirmation>
        <FontAwesomeIcon
          icon={successModal?.type === 'CONFIRMED' ? faCircleCheck : faCircleExclamation}
          size="3x"
          color={successModal?.type === 'CONFIRMED' ? colors.green400 : colors.red300}
        />
        <Subtitle>
          {successModal?.type === 'CONFIRMED' ? 'Pagamento efetuado com sucesso' : 'Transação não autorizada'}
        </Subtitle>
        <Paragraph type="small">
          {successModal?.type === 'CONFIRMED'
            ? 'Seu pagamento foi confirmado e seu fornecedor já foi notificado.'
            : 'Verifique os dados do cartão de crédito e tente novamente.'}
        </Paragraph>
      </ContentCreditConfirmation>
    </ConfirmModal>
  );

  const handleHighlight = () => {
    setHighlightDiv(prev => prev + 1);
  };
  const showCustomerDetails = isVobiPay && (isCustomerView || isPublic);

  const handleOpenModalFiles = filesList => {
    setOpenFilesModal({ open: true, list: filesList });
  };

  const hasUnsavedChanges = () => {
    if (valuesChanged && Object?.values(touched)?.includes(true)) {
      setOpenRequestSave(true);
      return true;
    }
    return false;
  };

  const validateKeys = ['edit', 'unpaid-installment', 'generate-pay-slip'];

  const columns = paymentConfigurationColumns({
    billType,
    disabledToCreditType,
    errors,
    handleBlur,
    handleChange,
    handleHighlight,
    handleSave,
    handleSubmitDropdown,
    idRefurbish,
    installmentHandleBlur,
    installmentStatuses,
    isCharge,
    isCustomerView,
    isMobile,
    isView,
    numberOfInstalments: installments.length,
    setInstallmentToPay,
    showCustomerDetails,
    showInstallmentActions,
    touched,
    valuesChanged,
    installmentToPay,
    isVobiPay,
    isPublic,
    handleOpenModalFiles,
    payment: values,
    hasUnsavedChanges,
    validateKeys
  });

  const mobileParams = {
    disabledToCreditType,
    errors,
    handleBlur,
    handleChange,
    handleHighlight,
    installmentHandleBlur,
    setInstallmentToPay,
    isVobiPay,
    isPublic,
    touched,
    showInstallmentActions,
    handleOpenModalFiles,
    actionDropdown: item => (
      <PaymentDropDown
        key={`action${item.id}`}
        item={item}
        idRefurbish={idRefurbish}
        isCustomerView={isCustomerView || isPublic}
        mappingObj={paymentChildren({
          installment: item,
          valuesChanged,
          handleSave,
          isCharge,
          billType,
          touched,
          onItemClick: f => f,
          isVobiPay: !!item?.idPaymentAsaas
        })}
        afterSubmit={handleSubmitDropdown}
        hasUnsavedChanges={hasUnsavedChanges}
        validateKeys={validateKeys}
      />
    )
  };

  const RenderInstallments = (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      {!isCharge && !id && (
        <Segment
          id="portion-type"
          options={optionsPayment}
          value={_paymentRecurrence}
          onChange={handleSegmentedChange}
        />
      )}
      {isRecurrence ? (
        <PaymentRecurrence />
      ) : (
        <InstallmentConfiguration
          installments={installments}
          columns={columns}
          typeLabel={getPaymentConditionTooltipText()}
          total={total}
          disable={isConciliation === 'edit' || isView || isRevision}
          generateInstallments={updateFieldWhenChangingNumberOfInstallments}
          installmentToPay={installmentToPay}
          defaultTypesNameList={defaultTypesNameList}
          onlyTable={isVobiPay}
          mobileParams={mobileParams}
          isCustomerView={isCustomerView || isPublic}
        />
      )}
    </Space>
  );

  const installmentTotal = total / (installmentCount || installments.length);

  const paymentCondition = useMemo(() => {
    if (isVobiPay) {
      return `${installmentCount}x de R$${installmentTotal?.toFixed(2)?.replace('.', ',')}`;
    }
    return `${numberOfInstalments}x`;
  }, [numberOfInstalments, installmentCount]);

  const interestObj = {
    percentage: `${formatCurrency(interest)}%`,
    value: formatCurrency(installmentTotal * (interest / 100), {
      currencySymbol: 'R$ '
    })
  };

  const fineObj = {
    percentage: `${formatCurrency(fineType === percentage?.value ? fine : (fine / installmentTotal) * 100)}%`,
    value: formatCurrency(fineType !== percentage?.value ? fine : installmentTotal * (fine / 100), {
      currencySymbol: 'R$ '
    })
  };

  const dueDateDiscountObj = {
    percentage: `${formatCurrency(
      dueDateDiscountType === percentage?.value ? dueDateDiscount : (dueDateDiscount / installmentTotal) * 100
    )}%`,
    value: formatCurrency(
      dueDateDiscountType !== percentage?.value ? dueDateDiscount : installmentTotal * (dueDateDiscount / 100),
      {
        currencySymbol: 'R$ '
      }
    )
  };
  const renderFineValue = fineType === percentage?.value ? fineObj?.percentage : fineObj?.value;
  const renderDiscountValue =
    dueDateDiscountType === percentage?.value ? dueDateDiscountObj?.percentage : dueDateDiscountObj?.value;
  const RenderFineInterestDiscountInfo = (
    <Grid>
      {formatNumber(interestObj?.value) > 0 ? (
        <Div gap={spaces.space1} direction="column" align="flex-start">
          <Paragraph type="small">Juros ao mês</Paragraph>
          <Paragraph type="small" id="vobiPayDueDate">
            {interestObj?.percentage} {!id ? `(${interestObj?.value})` : null}
          </Paragraph>
        </Div>
      ) : null}
      {formatNumber(fineObj?.value) > 0 ? (
        <Div gap={spaces.space1} direction="column" align="flex-start">
          <Paragraph type="small">Multa</Paragraph>
          <Paragraph type="small" id="vobiPayDueDate">
            {!id ? `${fineObj?.percentage} (${fineObj?.value})` : renderFineValue}
          </Paragraph>
        </Div>
      ) : null}
      {formatNumber(dueDateDiscountObj?.value) > 0 ? (
        <Div gap={spaces.space1} direction="column" align="flex-start">
          <Paragraph type="small">
            Desconto até{' '}
            {dueDateLimitDays === 0
              ? 'o dia do vencimento'
              : `${dueDateLimitDays} dia${dueDateLimitDays > 1 ? 's' : ''} antes do vencimento`}
          </Paragraph>
          <Paragraph type="small" id="vobiPayDueDate">
            {!id ? `${dueDateDiscountObj?.percentage} (${dueDateDiscountObj?.value})` : renderDiscountValue}
          </Paragraph>
        </Div>
      ) : null}
    </Grid>
  );

  const isRestrictedVobiPay = !isCustomerView && !isPublic && isVobiPay;

  const paymentLabel = getPaymentLabel({
    billType,
    isCharge,
    billTypeEnum
  });

  return (
    <>
      <Content>
        {isView || isRevision ? (
          <>
            <Subtitle>Pagamento</Subtitle>
            {isVobiPay && !id ? (
              <Configuration>
                <Grid $gridTemplate="1fr 2fr">
                  {(!id || !isVobiPay) && (
                    <Div gap={spaces.space1} direction="column" align="flex-start">
                      <Paragraph type="small">Condições de pagamento</Paragraph>
                      <Paragraph type="small" id="paymentInstallments">
                        {paymentCondition}
                      </Paragraph>
                    </Div>
                  )}
                  <Div gap={spaces.space1} direction="column" align="flex-start">
                    <Paragraph type="small">{`Vencimento ${countInstallments > 1 ? 'da 1ª parcela' : ''}`}</Paragraph>
                    <Paragraph type="small" id="vobiPayDueDate">
                      {dayjs(dueDate).format('DD/MM/YYYY')}
                    </Paragraph>
                  </Div>
                </Grid>
                {RenderFineInterestDiscountInfo}
              </Configuration>
            ) : (
              <>
                {isRestrictedVobiPay && RenderFineInterestDiscountInfo}
                {RenderInstallments}
              </>
            )}

            {values?.installments?.length > 0 && (
              <>
                {installments?.some(
                  installment => installment?.idInstallmentStatus === installmentStatuses?.pendingPayment
                ) &&
                  installmentToPay &&
                  (isPublic || isCustomerView) &&
                  isVobiPay && (
                    <VobiPayPayment
                      installmentToPay={installments?.find(installment => installment?.id === installmentToPay)}
                      paymentTypes={paymentTypes}
                      handleLoad={handleLoad}
                      installments={installments}
                      companyCustomer={companyCustomer}
                      company={_user?.company?.length ? _user?.company : company}
                      highlightDiv={highlightDiv}
                      setSuccessModal={setSuccessModal}
                    />
                  )}
              </>
            )}
            {isRestrictedVobiPay && RenderCards}
            {successModal.open && RenderModalCreditPayment}
          </>
        ) : (
          <>
            {(!isConciliation || isConciliation === 'edit') && (
              <SimpleAccordion
                initOpen={!!id || installments?.length > 0}
                title={`${
                  !id && !isCharge
                    ? 'Parcelamento ou recorrência'
                    : optionsPayment.find(opt => opt.isRecurrence === !!recurrenceId).label
                } `}
              >
                <Div gap={spaces.space2} direction="column" align="start">
                  {isRestrictedVobiPay && RenderFineInterestDiscountInfo}
                  {RenderInstallments}
                </Div>
              </SimpleAccordion>
            )}
            {isRestrictedVobiPay && RenderCards}
          </>
        )}
      </Content>
      {openFilesModal?.open && (
        <Modal
          width={512}
          open={openFilesModal?.open}
          list={openFilesModal?.list}
          title="Anexos"
          onClose={() => setOpenFilesModal({ open: false, list: [] })}
          footer={
            <>
              <span />
              <Button minWidth="112px" type="primary" onClick={() => setOpenFilesModal({ open: false, list: [] })}>
                Fechar
              </Button>
            </>
          }
        >
          <AttachmentList files={openFilesModal?.list} readOnly enumsName="systemData" />
        </Modal>
      )}
      {openRequestSave && (
        <Modal
          title={`Primeiro você deve salvar a ${paymentLabel}`}
          open={openRequestSave}
          onClose={e => {
            e.stopPropagation();
            setOpenRequestSave(false);
          }}
          width={426}
          submitText="Salvar"
          onSubmit={() => {
            handleSave();
            setOpenRequestSave(false);
          }}
          headerLine
          zIndex={!isMobile() && 1001}
        >
          <Div direction="column" align="start" $fullWidth>
            <Paragraph color={colors.neutral600}>
              Para registrar o pagamento primeiro é necessário salvar as alterações da {paymentLabel}. Deseja salvar?
            </Paragraph>
          </Div>
        </Modal>
      )}
    </>
  );
};

PaymentConfiguration.propTypes = {
  onSubmitDropdown: PropTypes.func,
  isV2: PropTypes.bool,
  idRefurbish: PropTypes.number
};

export default PaymentConfiguration;
