import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';

import BulkAction from './BulkAction';

import { BulkActionsChildrenContainer } from './ItemBulkActions.styles';
import useViewport from '../../_Hooks/useViewport';
import { ButtonBulkAction } from './BulkActions.styles';
import ConfirmModal from '../Modal/ConfirmModal';
import { ContentModal, GridItem, Table } from '../Table/BillingManagerTable.style';
import WarningBar from '../Alert/WarningBar';
import { Subtitle } from '../Text/Text';
import { fonts } from '../../styles/style';

const BillingManagerBulkActions = ({
  selectedItems,
  setSelectRowKeys,
  setSelectedItems,
  setLoading,
  getList = f => f
}) => {
  const plural = useMemo(() => (selectedItems.length > 1 ? 's' : ''), [selectedItems]);
  const counter = selectedItems?.length;
  const { user } = useSelector(state => state.authReducer);
  const [confirmSenNotifications, setConfirmSenNotifications] = useState(false);
  const { isMobile } = useViewport(window.innerWidth);
  const quantitiesToSend = selectedItems.reduce((acc, item) => {
    acc[item.channel] = (acc[item.channel] || 0) + 1;
    return acc;
  }, {});

  const { handleCreate } = useCRUD({
    model: `billingManager`,
    immediatelyLoadData: false
  });

  const { data: company } = useCRUD({
    model: 'company',
    pathOptions: `/${user?.idCompany}`,
    options: {
      attributes: ['id', 'creditSms', 'creditWpp']
    }
  });

  const negativeSms = company?.creditSms < quantitiesToSend?.sms;
  const negativeWpp = company?.creditWpp < quantitiesToSend?.whatsapp;

  const handleClose = () => {
    setSelectRowKeys([]);
    setSelectedItems([]);
  };

  const handleSendNotification = () => {
    setLoading(true);
    return handleCreate({
      values: { billings: selectedItems },
      postPathOptions: '/send',
      displayToast: 'Solicitação enviada com sucesso!',
      refresh: false
    }).then(() => {
      getList();
      setLoading(false);
      handleClose();
    });
  };
  const modalDescription =
    negativeWpp || negativeSms ? (
      <Subtitle weight={fonts.weight400} type="secondary">
        Você não possui créditos suficientes, <strong>algumas mensagens não serão enviadas</strong>. Você poderá
        enviá-las após adicionar mais créditos.
      </Subtitle>
    ) : (
      <Subtitle type="secondary" weight={fonts.weight400}>
        As mensagens a enviar consumirão os créditos da sua conta.
      </Subtitle>
    );

  const listTable = (
    <ContentModal>
      <WarningBar warning="Seus clientes serão notificados após confirmar" width="100%" />
      {modalDescription}
      <Table>
        <GridItem header>Item</GridItem>
        <GridItem header>SMS</GridItem>
        <GridItem header>Whatsapp</GridItem>
        <GridItem header>Email</GridItem>

        <GridItem>
          <strong>Créditos</strong>
        </GridItem>
        <GridItem negative={negativeSms}>{company?.creditSms}</GridItem>
        <GridItem negative={negativeWpp}>{company?.creditWpp}</GridItem>
        <GridItem>Ilimitado</GridItem>

        <GridItem>
          <strong>A enviar</strong>
        </GridItem>
        <GridItem id="creditSms" negative={negativeSms}>
          {quantitiesToSend?.sms || 0}
        </GridItem>
        <GridItem id="creditWpp" negative={negativeWpp}>
          {quantitiesToSend?.whatsapp || 0}
        </GridItem>
        <GridItem id="creditEmail">{quantitiesToSend?.email || 0}</GridItem>
      </Table>
    </ContentModal>
  );

  return (
    <>
      <BulkAction
        text={`${selectedItems.length} ite${plural ? 'n' : 'm'}${plural} ${!isMobile() ? `selecionado${plural}` : ''}`}
        counter={counter}
        handleClose={handleClose}
      >
        <BulkActionsChildrenContainer>
          <ButtonBulkAction id="resendNotifications" type="ghost" onClick={() => setConfirmSenNotifications(true)}>
            Reenviar notificações
          </ButtonBulkAction>
        </BulkActionsChildrenContainer>
      </BulkAction>

      {confirmSenNotifications && (
        <ConfirmModal
          typeCancel="text"
          open={confirmSenNotifications}
          onSubmit={handleSendNotification}
          title="Confirmar envio"
          width={456}
          onClose={() => setConfirmSenNotifications(false)}
        >
          {listTable}
        </ConfirmModal>
      )}
    </>
  );
};

BillingManagerBulkActions.propTypes = {
  selectedItems: PropTypes.instanceOf(Array),
  setSelectedItems: PropTypes.func,
  setSelectRowKeys: PropTypes.func,
  setLoading: PropTypes.func,
  getList: PropTypes.func
};

export default BillingManagerBulkActions;
