const mapping = isV2 => ({
  order: {
    shareLinkTag: 'oc',
    key: 'purchaseOrderId',
    urlText: 'Olá, tudo bem? Gostaria de compartilhar uma ordem de compra com você, acesse agora através desse link: '
  },
  payment: {
    shareLinkTag: 'c',
    key: 'paymentId',
    shareKey: 'invoice',
    shareText: `Habilitar ${isV2 ? 'pagamentos' : 'cobranças'} no portal do cliente`,
    shareReminderText: `Ao habilitar, você permite que o seu cliente veja ${
      isV2 ? 'todos os pagamentos' : 'todas as cobranças'
    } no portal do cliente do projeto que você compartilhou com ele.`,
    knowMoreId: 'knowMoreShareInvoice',
    urlText: `Olá, tudo bem? Gostaria de compartilhar ${
      isV2 ? 'um pagamento' : 'uma cobrança'
    } com você, acesse agora através desse link: `
  },
  'work-report': {
    shareLinkTag: 'w',
    key: 'workReportId',
    shareKey: 'workDiary',
    shareText: 'Compartilhar com o cliente',
    shareReminderText: `Notifique seu cliente de relatórios novos
     e permita com que ele veja todos os
    relatórios concluídos no portal do cliente.`,
    knowMoreId: 'daily-log-share-help',
    urlText: 'Olá, tudo bem? Gostaria de compartilhar um relatório com você, acesse agora através desse link: '
  },
  quote: {
    shareLinkTag: 'ct',
    key: 'quotationId',
    shareKey: 'quote',
    shareText: 'Habilitar cotações no portal do cliente',
    urlText: 'Olá, tudo bem? Gostaria de compartilhar uma cotação com você, acesse agora através desse link: ',
    shareReminderText: `Ao habilitar, você permite que o seu cliente veja os mapas de cotações no portal do cliente
       do projeto que você compartilhou com ele.`,
    knowMoreId: 'knowMoreShareQuotation'
  }
});

export default mapping;
