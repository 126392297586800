import * as Yup from 'yup';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import LinkedTaskSelection from '../../../components/Select/LinkedTaskSelection';
import { idReferenceNull, text, date, name } from '../schema';
import BackgroundColorLabel from '../../../components/Label/BackgroundColoredLabel';
import PerformedHoursDropdown from '../../../components/Dropdown/PerformedHoursDropdown';

const taskSchema = Yup.object().shape({
  name,
  description: text,
  id: text,
  status: idReferenceNull,
  priority: idReferenceNull,
  startDate: date,
  endDate: date,
  idRefurbish: idReferenceNull,
  idResponsible: idReferenceNull
});

const taskMapping = ({
  idTask,
  idRefurbish,
  idTemplate,
  idCompany,
  canChangeRefurbish,
  generalTask,
  refurbishStatusAllowed,
  workDays,
  dayoffs,
  totalPerformedHours,
  onSetDraftOptions,
  onChangeDateRelatedField
}) => {
  const disabled = !idRefurbish && !idTemplate;
  return {
    name: {
      type: 'text',
      name: 'Título da tarefa',
      id: 'title-task'
    },
    idResponsible: {
      name: 'Responsável',
      type: 'select',
      id: 'responsible',
      placeholder: 'Responsável',
      model: 'user',
      allowClear: true,
      modelOptions: { where: { idCompany, userType: { or: ['provider', 'guest'] } }, order: [['name']] }
    },
    duration: {
      type: 'masked',
      name: 'Duração',
      placeholder: '0',
      onlyPlaceholder: true,
      suffix: 'dias',
      onChange: onChangeDateRelatedField('duration'),
      formatValue: value => {
        const intValue = parseInt(value || '0', 10);
        if (Number.isNaN(intValue)) {
          toast.error('Duração inválida.');
          return null;
        }
        return intValue;
      }
    },
    estimativeDuration: {
      type: 'masked',
      name: 'Horas estimadas',
      md: 6
    },
    realDuration: {
      type: 'custom',
      name: 'Horas realizadas',
      md: 6,
      Component: () => (
        <PerformedHoursDropdown
          totalHours={totalPerformedHours}
          idTask={idTask}
          onDraftSubmit={({ createdDrafts, updatedDrafts, deletedDrafts }) => {
            onSetDraftOptions({ createdDrafts, updatedDrafts, deletedDrafts });
          }}
          isForm
        />
      )
    },
    startDate: {
      name: 'Data de início',
      type: 'date',
      allowClear: true,
      hasChildren: true,
      externalOnChange: onChangeDateRelatedField('startDate'),
      md: 6,
      workDays,
      dayoffs
    },
    startHours: {
      name: 'Hora de início',
      type: 'timeHours',
      allowClear: true,
      md: 6,
      parent: { name: 'startDate' }
    },
    endDate: {
      name: 'Data de fim',
      type: 'date',
      allowClear: true,
      hasChildren: true,
      externalOnChange: onChangeDateRelatedField('endDate'),
      md: 6,
      workDays,
      dayoffs
    },
    endHours: {
      name: 'Hora de fim',
      type: 'timeHours',
      allowClear: true,
      parent: { name: 'endDate' },
      md: 6
    },
    priority: {
      type: 'select',
      name: 'Prioridade',
      dataType: 'taskPriority',
      id: 'taskPriority'
    },
    status: {
      type: 'select',
      name: 'Status',
      dataType: 'taskStatus'
    },
    ...(canChangeRefurbish && {
      idRefurbish: {
        name: 'Projeto/Oportunidade',
        type: 'select',
        model: 'refurbish',
        modelOptions: {
          where: { idStatus: refurbishStatusAllowed, or: [{ '$activeStep.isActive$': true }, { idStep: null }] },
          include: ['activeStep'],
          order: [['name'], ['id']]
        },
        hasChildren: true
      }
    }),
    ...(idTemplate
      ? {
          idTemplateStep: {
            name: 'Etapa do template',
            type: 'select',
            readOnly: disabled,
            keepDisabled: true,
            isChangeableOptions: true,
            model: 'templateStep',
            modelOptions: {
              where: {
                or: [{ idTemplate }, { id: 1 }]
              },
              order: [['order']]
            },
            aliasOptions: ({ color, label }) => <BackgroundColorLabel color={color}>{label}</BackgroundColorLabel>
          }
        }
      : {
          idRefurbishStep: {
            name: 'Etapa do projeto',
            type: 'select',
            readOnly: disabled,
            keepDisabled: true,
            isChangeableOptions: true,
            nonEditableTooltipText: 'Primeiro selecione o projeto',
            parent: canChangeRefurbish && {
              name: 'idRefurbish',
              lazyLoad: disabled,
              makeRefetchOptions: ({ field, value }) => ({
                where: {
                  or: [{ [field]: value }, { id: 1 }]
                },
                order: [['order']]
              })
            },
            model: 'refurbishStep',
            modelOptions: {
              where: {
                or: [{ idRefurbish }, { id: 1 }]
              },
              order: [['order']]
            },
            aliasOptions: ({ color, label }) => <BackgroundColorLabel color={color}>{label}</BackgroundColorLabel>
          }
        }),
    ...(!generalTask &&
      idTask && {
        idParent: {
          type: 'custom',
          name: 'Vincular tarefa',
          // eslint-disable-next-line react/prop-types
          Component: ({ setField, value }) => {
            const _onChange = useCallback(_value => {
              return setField(_value, true);
            }, []);
            return (
              <LinkedTaskSelection
                id={idTask}
                idReference={idRefurbish || idTemplate}
                setLinkedTask={_onChange}
                isTemplate={!!idTemplate}
                linkedTask={value}
              />
            );
          }
        }
      }),
    description: {
      placeholder: 'Informe a descrição',
      type: 'editor',
      name: 'Descrição',
      md: 24,
      height: 200
    }
  };
};

export { taskMapping, taskSchema };
