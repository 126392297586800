import { copyLinkButton, deleteAction, taskDetails } from './_dropdown';

const task = [
  deleteAction,
  {
    label: 'Duplicar',
    verb: 'post',
    pathOptions: '/duplicate',
    params: {
      postPathOptions: '/duplicate'
    }
  },
  {
    button: 'copyLink',
    buttonParams: {
      text: true,
      children: 'Copiar link'
    }
  },
  taskDetails,
  {
    label: 'Vincular',
    modal: 'linkTaskModal'
  }
];

const generalTask = [
  deleteAction,
  {
    label: 'Duplicar',
    verb: 'post',
    pathOptions: '/duplicate',
    params: {
      postPathOptions: '/duplicate'
    }
  },
  copyLinkButton,
  taskDetails
];

export { task, generalTask };
