import styled from 'styled-components';
import { spaces } from '../../styles/style';

export const Container = styled.div`
  position: relative;
  display: flex;
  height: ${spaces.space4};
  width: ${spaces.space4};
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
