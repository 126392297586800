import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { columns } from '../../lib/mapping/TableOrList/planningColumns';
import VirtualizedTable from './VirtualizedTable';

import EditPlanningDrawer from '../Drawer/EditPlanningDrawer';
import CenteredLoader from '../Loader/CenteredLoader';
import { indexList } from '../../lib/helpers/helper';
import useSpecification from '../../_Hooks/useSpecification';
import useViewport from '../../_Hooks/useViewport';
import useGantt from '../../_Hooks/useGantt';
import useCRUD from '../../_Hooks/useCRUD';
import createDataProcessor from '../Gantt/dataProcessor';

const TemplatePlanningTable = ({ data, isApply }) => {
  const { isMobile, isMinWidth } = useViewport(window.innerWidth, 1440);

  const { user } = useSelector(state => state.authReducer);
  const { refurbishItemStatus: statusArray } = useSelector(state => state.setup.systemData);
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const { id: idTemplate, plBasedDate, myPlanningListColumns = {} } = data || {};
  const [editItemModalParams, setEditItemModalParams] = useState({});
  const [newList, setNewList] = useState([]);
  const [expandItem, setExpandedItem] = useState(null);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const [dataGantt, setDataGantt] = useState({ data: [], links: [], resources: [] });
  const [listIndexes, setListIndexes] = useState({});

  const expandedRows = useRef([]);
  const linksAdded = useRef([]);

  const idCompany = user?.idCompany;

  const columnsToShow = myPlanningListColumns;

  const {
    list,
    isLoading,
    handleToggleAllRow,
    expandedRowKeys,
    handleUpdate,
    serializedStatusArray,
    handleLoad
  } = useSpecification({
    data,
    setExpandedItem,
    idCompany,
    initExpandedRow: [],
    isApply,
    isTemplate: true,
    setIsDefaultOrder,
    extraInclude: [
      {
        model: 'TemplatePlanningLink',
        as: 'targetLinks'
      }
    ]
  });

  const {
    handleGet: handleGetTemplatePlanningLink,
    handleCreate: handleCreateTemplatePlanningLink,
    handleDelete: handleDeleteTemplatePlanningLink,
    handleUpdate: handleUpdateTemplatePlanningLink
  } = useCRUD({
    model: 'templatePlanningLink',
    options: { where: { idTemplate } },
    immediatelyLoadData: false
  });

  const { ganttInstance, getFormattedLinks, transformAndFindUsers, handleChangePredecessor } = useGantt({
    tasks: dataGantt,
    disabled: isApply,
    listIndexes
  });

  const planningLoad = () => {
    if (list?.length) {
      setNewList(indexList({ currentList: list, childrenColumn: 'children', acc: 1 }));
    }
    setExpandedItem(null);
  };

  const handleChange = values => {
    const { id, ..._values } = values || {};
    return handleUpdate({
      updatePathOptions: `/${id}`,
      values: { ..._values },
      refresh: false
    }).then(() => {
      handleLoad();
    });
  };

  useEffect(() => {
    if (list && idTemplate) {
      planningLoad();
    }
  }, [list, idTemplate]);

  useEffect(() => {
    if (newList) {
      handleGetTemplatePlanningLink().then(links => {
        const result = transformAndFindUsers(newList);
        linksAdded.current = [...linksAdded.current, ...links];

        setDataGantt({
          data: result.data,
          links,
          resources: result.resources
        });

        setListIndexes(result?.indexes);
      });
    }
  }, [newList]);

  const dataProcessor = useMemo(() => {
    return createDataProcessor({
      linkCreate: _data => {
        const { id, ...dataWithoutId } = _data;
        handleCreateTemplatePlanningLink({ values: { idTemplate, ...dataWithoutId } }).then(resp => {
          const actualLink = ganttInstance.getLink(_data?.id);
          actualLink.currentId = resp?.id;
          ganttInstance.refreshLink(actualLink?.id);
          linksAdded.current.push(actualLink);

          handleLoad();
        });
      },
      linkUpdate: _data => {
        handleUpdateTemplatePlanningLink({
          values: { idTemplate, ..._data, id: _data?.currentId || _data?.id }
        }).then(() => {
          handleLoad();
        });
      },

      linkDelete: _data => {
        const linkIndex = linksAdded.current.findIndex(link => link?.id === _data?.id);
        const linkToDelete = linkIndex !== -1 ? linksAdded.current[linkIndex] : _data;

        handleDeleteTemplatePlanningLink({
          idTemplate,
          ...linkToDelete,
          id: linkToDelete?.currentId || linkToDelete?.id
        }).then(() => {
          handleLoad();
        });
      }
    });
  }, [idTemplate]);

  useEffect(() => {
    ganttInstance.createDataProcessor(dataProcessor);
  }, [dataProcessor]);

  useEffect(() => {
    if (ganttInstance) {
      ganttInstance.plugins({
        auto_scheduling: true
      });
    }
  }, [ganttInstance]);

  const _columns = columns({
    handleChange,
    isCustomer: false,
    minWidth: isMinWidth() ? { width: 320 } : { undefined },
    handleToggleAllRow,
    plBasedDate,
    columnsToShow,
    serializedStatusArray,
    statusArray,
    readOnly: isApply,
    isTemplate: true,
    ganttInstance,
    getFormattedLinks,
    handleChangePredecessor
  });

  const handleRowClick = ({ id, type, item }) => {
    if (type !== refurbishItemType.parent && !item && type) {
      setEditItemModalParams({ id, type, show: true });
    }
  };

  const handleAfterSubmit = () => {
    handleLoad();
    setEditItemModalParams({});
  };

  return (
    <>
      {isLoading ? (
        <CenteredLoader />
      ) : (
        <VirtualizedTable
          rowKey="id"
          showCheckBoxes={false}
          expandItem={expandItem}
          childrenColumnName="children"
          expandedRows={expandedRows}
          columns={_columns}
          columnsMapping={columns}
          onClick={handleRowClick}
          list={newList}
          scroll={
            isApply
              ? { y: 340, x: '100%' }
              : { y: isMobile() ? 'calc(100vh - 310px)' : 'calc(100vh - 268px)', x: '100%' }
          }
          rowSelection={false}
          withCommonRow
          isDefaultOrder={isDefaultOrder}
          expandedRowKeys={expandedRowKeys}
          readOnly={isApply}
          refreshColumns
        />
      )}
      {editItemModalParams?.show ? (
        <EditPlanningDrawer
          {...editItemModalParams}
          isTemplate
          open={editItemModalParams?.show}
          onClose={() => {
            setEditItemModalParams({});
          }}
          idCompany={idCompany}
          renderColumns={columnsToShow}
          afterSubmit={handleAfterSubmit}
          dataGantt={dataGantt}
        />
      ) : null}
    </>
  );
};

TemplatePlanningTable.propTypes = {
  data: PropTypes.instanceOf(Object),
  isApply: PropTypes.bool
};

export default TemplatePlanningTable;
