import styled, { css } from 'styled-components';

import { breakpoints, colors, fonts, spaces } from '../../../styles/style';

const StyledButton = styled.button`
  color: ${colors.neutral600};
  font-size: ${fonts.sizeSm};
  &:hover {
    color: ${colors.neutral900};
  }
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const StepNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fonts.sizeMd};
  width: 100%;

  span {
    color: ${colors.neutral600};
    font-size: ${fonts.sizeSm};
  }

  .editItem {
    margin-left: ${spaces.space2};
    display: none;
  }

  &:hover {
    .editItem {
      display: block;
    }
  }
`;

const ColorAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${props => props.paddingLeft}px;
  width: 50%;
`;

const Name = styled.div`
  max-width: 280px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-left: ${spaces.space0};
  overflow: hidden;
`;

const IconsDiv = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 175px;
  margin-left: ${spaces.space1};

  * {
    margin-right: ${spaces.space2};
  }

  svg {
    cursor: pointer;
  }
`;

const ChecklistContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spaces.space1};
  color: ${props => props.$color};
`;

const TaskNameContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .linkedTask {
    display: ${props => props.$showLinkedTask || 'none'};
  }
  &:hover {
    .checkCircle {
      color: ${p => p.$checklistColorHover};
    }
    .linkedTask {
      display: block;
    }
    .renameItem {
      display: block;
    }

    ${props =>
      props.showIconOnHover &&
      css`
        .checkCircle {
          display: block;
        }
      `}
  }


  ${props =>
    props.showIconOnHover &&
    css`
      .checkCircle {
        display: none;
      }
    `}

  @media (max-width: ${breakpoints.tablet}) {
    .checkCircle {
      display: none;
      padding-left: ${spaces.space2};
    }
    padding-left: ${spaces.space2};
    flex: 5;
  }
`;

export {
  StyledButton,
  StepNameContainer,
  ColorAndNameContainer,
  Name,
  IconsDiv,
  ChecklistContainer,
  TaskNameContainer
};
