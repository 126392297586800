import qs from 'qs';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Context as NotificationContext } from '../contexts/Context';
import { Context as NavBarContext } from '../contexts/GeneralContext';
import useAnonymousPermissions from '../_Hooks/useAnonymousPermissions';
import useViewport from '../_Hooks/useViewport';
import LoginRoute from './Login/LoginRoutes';

import Authentication from '../_Pages/Authentication';
import Embed from '../_Pages/Embed';
import Logout from '../_Pages/Logout';
import Main from '../_Pages/Main';

import AnonymousViewAlert from '../components/CustomerViewAlert/AnonymousViewAlert';
import CustomerViewAlert from '../components/CustomerViewAlert/CustomerViewAlert';
import MainHeader from '../components/Header/MainHeader';
import MobileMainHeader from '../components/Header/MobileMainHeader';
import MobileSubHeader from '../components/Header/MobileSubHeader';
import CenteredLoader from '../components/Loader/CenteredLoader';
import NavBar from '../components/NavBar/NavBar';
import routeToTitleMapping from '../lib/helpers/routeTitleMappings';
import { navItems } from '../lib/mapping/Menu/mainMenu';

import UserEventService from '../lib/gtm/user';
import { breakpoints, colors } from '../styles/style';
import css from '../_Pages/Main.module.scss';
import PaymentViewPublic from '../_Pages/Payments/PaymentViewPublic';
import WorkReportViewPublic from '../_Pages/Tools/WorkReport/WorkReportViewPublic';

import RedirectLink from '../_Pages/RedirectLink';
import AccessAsUserAlert from '../components/User/AccessAsUserAlert';
import OrderViewPublic from '../_Pages/Purchases/Order/OrderViewPublic';
import QuoteSupplierViewPublic from '../_Pages/Purchases/Order/QuoteSupplierViewPublic';
import QuotationViewPublic from '../_Pages/Purchases/Quote/QuotationViewPublic';
import ReceiptView from '../_Pages/Payments/ReceiptView';

const ProviderRoutes = lazy(() => import('./Provider/ProviderRoutes'));
const OperatorRoutes = lazy(() => import('./Operator/OperatorRoutes'));
const CustomerRoutes = lazy(() => import('./Customer/CustomerRoutes'));
const InviteScreen = lazy(() => import('../_Pages/CustomerInvite'));
const AuthInstagram = lazy(() => import('../_Pages/AuthInstagram'));
const CatalogRoutes = lazy(() => import('./Common/CatalogRoutes'));
const CompanyOnboard = lazy(() => import('../_Pages/Onboard/CompanyOnboard'));

const MainRouterContainer = styled.div`
  display: flex;

  background-color: ${colors.neutral75};

  @media (min-width: ${breakpoints.tablet}) {
    height: 100%;
    max-height: ${props => (props.$isCustomerView ? 'calc(100% - 50px)' : '100%')};
  }
`;

const MainRouterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media (min-width: ${breakpoints.tablet}) {
    overflow: auto;
  }
`;

const userEventService = UserEventService();

const MainRoutes = () => {
  const [toggle, setToggle] = useState(false);
  const [isOpenNavBar, setIsOpenNavBar] = useState(false);
  const [selected, setSelected] = useState('dashboard');
  const location = useLocation();
  const params = useParams();
  const { rootPage, page, subpage } = params;
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { updatePermissions } = useAnonymousPermissions();

  const { user = null } = useSelector(state => state.authReducer) || {};

  const refurbish = useSelector(state => state.refurbishReducer);

  const { userType: userTypeEnum, companyStatus } = useSelector(state => state.setup.enums) || {};
  const { id, userType, type, company } = user || {};
  const { idStatus } = company || {};
  const notificationOptions = {
    where: { idConsumer: id },
    include: [
      {
        model: 'user',
        as: 'producer'
      },
      {
        model: 'user',
        as: 'consumer'
      }
    ],
    limit: 100,
    order: [
      ['id', 'DESC'],
      ['createdAt', 'DESC']
    ]
  };
  const isLogged = !!user?.id;
  const loadNotifications = !user?.anonymous && userType !== userTypeEnum?.operator?.value && !user?.accessedBy?.id;

  const { redirect, redirect2 } = qs.parse(location?.search || '', { ignoreQueryPrefix: true }) || {};

  const redirectUrl = `${redirect || ''}${redirect2 ? `&redirect=${redirect2}` : ''}`;

  const V2customer = refurbish?.version && userType === 'customer';

  const paths = navItems[`${userType}${V2customer ? refurbish.version : ''}`] || {};
  const found = paths[selected];

  let title;
  const rootPageObject = routeToTitleMapping[rootPage];

  if (rootPageObject) {
    const pageTitle = rootPageObject[page];
    if (pageTitle && pageTitle.constructor === Object)
      title = subpage ? pageTitle[subpage] || pageTitle.subpage : pageTitle.default;
    else if (typeof pageTitle === 'string') title = pageTitle;
    else title = routeToTitleMapping[rootPage].fallback;
  }

  title = (title && title.replace('{user.name}', (user || {}).name)) || routeToTitleMapping.general.default;

  const handleToggle = () => setToggle(!toggle);

  const handleOpenCloseNavBar = () => setIsOpenNavBar(prevValue => !prevValue);

  const handleSelected = select => {
    if (!toggle) handleToggle();
    if (select !== selected) {
      setSelected(select);
    }
  };

  useEffect(() => {
    const myDiv = document.getElementById('containerDiv');
    if (myDiv && _isMobile) {
      myDiv.scrollTop = 0;
    }
    userEventService.pageview();
    setToggle(false);
  }, [location]);

  useEffect(() => {
    if (isLogged) userEventService.identify();
  }, [isLogged]);

  useEffect(() => {
    if (!location?.pathname?.includes('convite')) {
      updatePermissions();
    }
  }, []);

  return (
    <Authentication>
      {isLogged ? (
        <Suspense fallback={<CenteredLoader />}>
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/o/:id/:tab?" component={RedirectLink} />
            <Route path="/o2/:id/:tab?" component={RedirectLink} />
            <Route path="/a/:id/:tab?" component={RedirectLink} />
            <Route path="/n/:id/:tab?" component={RedirectLink} />
            <Route path="/t/:id" component={RedirectLink} />
            <Route path="/p/:id?" component={RedirectLink} />
            <Route path="/pg/:action/:id?" component={RedirectLink} />
            <Route path="/r/:action/:id?" component={RedirectLink} />
            <Route path="/d/:action/:id?" component={RedirectLink} />
            <Route path="/s/:id" component={RedirectLink} />
            <Route path="/oc/:id" component={RedirectLink} />
            <Route path="/ct/:id" component={RedirectLink} />
            <Route path="/c/:id" component={PaymentViewPublic} />
            <Route path="/w/:id" component={WorkReportViewPublic} />
            <Route path="/qs/:id" component={() => <QuoteSupplierViewPublic isViewAsSupplier />} />
            <Route path="/rc/:id" component={ReceiptView} />
            {idStatus === companyStatus?.onBoarding?.id ? (
              <Route
                path="/informacoes-adicionais"
                render={() => <Embed page="informacoes-adicionais" height="100%" />}
              />
            ) : null}
            <Route path="/convite/:entityType" component={InviteScreen} />
            <Route path="/auth/" component={AuthInstagram} />
            <Route path="/onboarding/:step?" component={CompanyOnboard} />
            <Route
              render={() => (
                <div id="containerDiv" className={css['c-mail-router']}>
                  <NotificationContext
                    model="activity"
                    options={notificationOptions}
                    immediatelyLoadData={loadNotifications}
                    shouldUpdate={{ current: null }}
                  >
                    <NavBarContext data={{ isOpenNavBar, handleOpenCloseNavBar, setIsOpenNavBar }}>
                      {user.isCustomerView ? <CustomerViewAlert /> : null}
                      <AnonymousViewAlert />
                      {user?.accessedBy ? <AccessAsUserAlert /> : null}
                      <MainRouterContainer
                        className="main-router-container"
                        $isCustomerView={user?.isCustomerView || user?.anonymous}
                      >
                        <NavBar
                          toggle={toggle}
                          handleToggle={handleToggle}
                          onSelect={select => handleSelected(select)}
                          selected={selected}
                          noShowCollapse={found?.noShowCollapse}
                          found={found}
                          paths={paths}
                        />
                        <MainRouterContent id="main-content">
                          <MainHeader noShowCollapse={found?.noShowCollapse} toggle={toggle} level={2} title={title} />
                          <MobileMainHeader title={title} user={user} />
                          <MobileSubHeader />
                          <Main toggle={toggle} noShowCollapse={found?.noShowCollapse} redirectUrl={redirectUrl}>
                            <Switch>
                              {user?.userType === userTypeEnum?.provider.value && (
                                <Route path="/profissional" component={ProviderRoutes} />
                              )}
                              {user?.userType === userTypeEnum?.operator.value && (
                                <Route path="/operador" component={OperatorRoutes} />
                              )}
                              {user?.userType === userTypeEnum?.customer.value && (
                                <Route path="/cliente" component={CustomerRoutes} />
                              )}
                              {(user?.userType === userTypeEnum?.customer.value ||
                                user?.userType === userTypeEnum?.provider.value ||
                                user?.userType === userTypeEnum?.operator.value) && (
                                <Route path="/catalogo" component={CatalogRoutes} />
                              )}
                              <Route path="*" render={() => <Redirect to={`/${type}/dashboard`} />} />
                            </Switch>
                          </Main>
                        </MainRouterContent>
                      </MainRouterContainer>
                    </NavBarContext>
                  </NotificationContext>
                </div>
              )}
            />
          </Switch>
        </Suspense>
      ) : (
        <Suspense fallback={<p />}>
          <div id="containerDiv" className={`${css['c-mail-router']} ${css['c-main--background-white']}`}>
            <Switch>
              <Route path="/convite/:entityType" component={InviteScreen} />
              <Route path="/ct/:id" component={QuotationViewPublic} />
              <Route path="/c/:id" component={PaymentViewPublic} />
              <Route path="/oc/:id" component={OrderViewPublic} />
              <Route path="/qs/:id" component={QuoteSupplierViewPublic} />
              <Route path="/w/:id" component={WorkReportViewPublic} />
              <Route path="/login" component={LoginRoute} />
              <Route path="/rc/:id" component={ReceiptView} />
              <Route
                path="*"
                render={({ match }) => {
                  const { url } = match;
                  const _url = url?.includes('logout') || url === '/' ? '' : url;
                  return (
                    <Redirect
                      to={{
                        pathname: '/login',
                        search: _url ? `?redirect=${_url}` : null
                      }}
                    />
                  );
                }}
              />
            </Switch>
          </div>
        </Suspense>
      )}
    </Authentication>
  );
};

export default MainRoutes;
