import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faFilter, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faFilter as faFilterHover } from '@fortawesome/pro-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Select, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CenteredLoader from '../Loader/CenteredLoader';
import FilterButton from './FilterButton';
import FilterDefault from './FilterDefault';
import MobileSubHeader from '../Header/MobileSubHeader';
import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';
import { filterForm } from '../../lib/mapping/Form/filterForm';
import { mappingMobileSubHeader } from '../../lib/mapping/Header/mappingMobileSubHeader';
import { clearFilter } from '../../lib/helpers/filterFunctions';
import FilterMobile from './FilterMobile';
import { Container, StyledDivFlex } from './FiltersByModel.style';
import { colors } from '../../styles/style';
import { Subtitle } from '../Text/Text';

const FiltersByModel = ({
  options,
  onFilter,
  onGroup = f => f,
  _groupedBy,
  appliedFilters = {},
  setAppliedFilters,
  model = 'specification-filters',
  groupArray = 'specificationGroupBy',
  pathOptions = '',
  showGroupButtons,
  reverse,
  textSearchKey = 'name',
  fromMapping,
  ExtraAction,
  flexDirection = 'row',
  topHeaderMobile = 49,
  object,
  noPadding,
  onlyFilterIconMobile,
  disableFilterButtonMobile,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const {
    location: { pathname }
  } = useHistory();
  const paths = pathname.split('/');
  const systemData = useSelector(state => state.setup.systemData);
  const arrGroupBy = systemData[groupArray];
  const groupedBy = _groupedBy || arrGroupBy?.[0];

  const [showFilter, setShowFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [, setSearchText] = useState();

  const { title } =
    mappingMobileSubHeader[paths[1]]?.[paths[2]]?.[paths[3]] ||
    mappingMobileSubHeader[paths[1]]?.[paths[2]] ||
    mappingMobileSubHeader[paths[1]] ||
    {};

  const { list: filters, loading: loadingFilter, handleGet: getFilters } = useCRUD({
    model,
    pathOptions,
    options,
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (showFilter) {
      if (object) {
        setCurrentFilters(object);
      } else if (fromMapping) {
        setCurrentFilters(filterForm[model] || filterForm.common);
      } else {
        getFilters().then(resp => setCurrentFilters(resp));
      }
    }
  }, [showFilter]);

  const handleDefaultFilter = filterName => (values, name) => {
    const newObj = { ...appliedFilters };
    newObj[name] = values.map(v => ({ ...v, filterName }));
    setAppliedFilters(newObj);
    onFilter(newObj);
    setShowFilter(false);
  };

  const handleChangeGroupByOptions = group => {
    onGroup(group);
    setShowGroupModal(false);
  };

  const handleClearFilter = () => {
    const newFilters = clearFilter(appliedFilters);
    setAppliedFilters(newFilters || {});
    onFilter(newFilters);
    setShowFilter(false);
  };

  useEffect(() => {
    const keys = Object.entries(appliedFilters);

    if (!keys?.length) {
      setSearchText();
      setHasFilter(false);
    }

    keys.forEach(([key, value]) => {
      if (key === textSearchKey) {
        if (value === undefined) setSearchText();
        return;
      }

      const filterIndex = filters.findIndex(item => item.name === key);
      if (filterIndex !== -1) {
        const newArr = [...filters];
        newArr[filterIndex].options = value;
        setCurrentFilters(newArr);
      }

      setHasFilter(Array.isArray(value) && value.findIndex(item => item.checked === true) !== -1);
    });
    setShowFilter(false);
  }, [appliedFilters]);

  return isMobile() ? (
    <>
      {!onlyFilterIconMobile ? (
        <MobileSubHeader goBack fixed top={topHeaderMobile}>
          {groupedBy ? (
            <Button text onClick={() => setShowGroupModal(true)}>
              <Subtitle>{groupedBy.label}</Subtitle>
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          ) : (
            <Subtitle>{title || 'Vobi'}</Subtitle>
          )}
          <StyledDivFlex>
            {ExtraAction && <ExtraAction />}
            {!disableFilterButtonMobile && (
              <FilterMobile
                handleDefaultFilter={handleDefaultFilter}
                currentFilters={currentFilters}
                hasFilter={hasFilter}
                clearFilter={handleClearFilter}
                onShowFilter={setShowFilter}
                appliedFilters={appliedFilters}
              />
            )}
          </StyledDivFlex>
        </MobileSubHeader>
      ) : (
        <StyledDivFlex>
          {ExtraAction && <ExtraAction />}
          <FilterMobile
            handleDefaultFilter={handleDefaultFilter}
            currentFilters={currentFilters}
            hasFilter={hasFilter}
            clearFilter={handleClearFilter}
            onShowFilter={setShowFilter}
            appliedFilters={appliedFilters}
          />
        </StyledDivFlex>
      )}
      {showGroupModal && (
        <Modal title="Visualização" open hideFooter onClose={() => setShowGroupModal(false)}>
          {arrGroupBy.map(item => (
            <Button text key={item.value} onClick={() => handleChangeGroupByOptions(item)}>
              {item.label}
            </Button>
          ))}
        </Modal>
      )}
    </>
  ) : (
    <Container showGroupButtons={showGroupButtons} reverse={reverse} {...props}>
      <div style={{ display: 'flex', flexDirection, justifyContent: 'flex-start' }}>
        {showGroupButtons && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Visualizar por:
            <Select
              id="select-show-visible"
              dropdownMatchSelectWidth={160}
              style={{
                color: colors.primary600
              }}
              suffixIcon={<FontAwesomeIcon icon={faCaretDown} color={colors.neutral600} />}
              defaultValue={groupedBy.label}
              options={arrGroupBy}
              bordered={false}
              onSelect={item => handleChangeGroupByOptions(arrGroupBy.find(group => group.value === item))}
            />
          </div>
        )}

        <Space style={{ marginTop: flexDirection === 'column' ? '15px' : '0px' }}>
          <Button id="filter-button" text size="small" onClick={() => setShowFilter(!showFilter)} padding="0">
            <FontAwesomeIcon icon={showFilter ? faFilterHover : faFilter} size="lg" />
            Filtrar
          </Button>

          {showFilter ? (
            <>
              {loadingFilter ? (
                <CenteredLoader text="" />
              ) : (
                <>
                  {currentFilters?.map?.(filter =>
                    filter?.options || filter?.dataType ? (
                      <div key={filter?.name}>
                        <FilterButton buttonId={filter?.name} text={filter?.filterName} position="bottom-right">
                          <FilterDefault
                            options={filter?.dataType ? systemData[filter?.dataType] : filter?.options}
                            name={filter?.name}
                            extra={filter?.extra}
                            appliedFilters={appliedFilters}
                            onClick={handleDefaultFilter(filter?.filterName)}
                            onlyOne={filter?.onlyOne}
                          />
                        </FilterButton>
                      </div>
                    ) : null
                  )}
                </>
              )}
            </>
          ) : null}
        </Space>
      </div>
    </Container>
  );
};

FiltersByModel.propTypes = {
  options: PropTypes.instanceOf(Object),
  onFilter: PropTypes.func,
  onGroup: PropTypes.func,
  _groupedBy: PropTypes.instanceOf(Object),
  appliedFilters: PropTypes.instanceOf(Object),
  setAppliedFilters: PropTypes.func,
  model: PropTypes.string,
  pathOptions: PropTypes.string,
  showGroupButtons: PropTypes.bool,
  reverse: PropTypes.bool,
  groupArray: PropTypes.string,
  textSearchKey: PropTypes.string,
  fromMapping: PropTypes.bool,
  ExtraAction: PropTypes.instanceOf(Object),
  flexDirection: PropTypes.string,
  topHeaderMobile: PropTypes.number,
  object: PropTypes.instanceOf(Object),
  noPadding: PropTypes.bool,
  onlyFilterIconMobile: PropTypes.bool,
  disableFilterButtonMobile: PropTypes.bool
};

export default FiltersByModel;
