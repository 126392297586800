import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, radius, spaces } from '../../styles/style';

export const StyledDiv = styled.div`
  padding: ${props => (props.noPadding ? 0 : spaces.space0)};
  ${props =>
    props.withBorder
      ? css`
          border: 1px solid ${colors.neutral200};
          border-radius: ${radius.radius1};
        `
      : css`
          border: none;
        `};
  ${props =>
    props.withError &&
    css`
      border: 1px solid ${colors.red500};
      border-radius: ${radius.radius1};
    `};
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: ${spaces.space3};
  min-width: ${props => props.width && props.width};
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: ${props => (props.align ? props.align : 'center')};

  @media (max-width: ${breakpoints.tablet}) {
    flex-basis: 100%;
    font-size: ${fonts.sizeSm};
    padding: ${props => (props.noPadding ? 0 : `0 ${spaces.space0}`)};
  }

  svg {
    display: none;
  }

  ${props =>
    props.$fixedHoverStyle &&
    !props.disabled &&
    css`
      border: ${() => !props.disabled && `1px solid ${colors.neutral100}`};
      border-radius: ${radius.radius1};
      cursor: ${() => props.cursor || 'text'};

      svg {
        display: ${() => !props.disabled && 'flex'};
      }
    `};

  &:hover {
    border: ${props => !props.disabled && `1px solid ${colors.neutral500}`};
    border-radius: ${radius.radius1};
    cursor: ${props => props.cursor || 'text'};

    svg {
      display: ${props => !props.disabled && 'flex'};
    }
  }
`;
export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fonts.sizeSm};
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: ${spaces.space1};
    cursor: pointer;
  }
  .renameItem {
    display: none;
  }
  &:hover {
    .renameItem {
      display: block;
    }
  }
`;
