import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import Drawer from '../Drawer/Drawer';
import Watcher from '../Watcher/Watcher';
import useViewport from '../../_Hooks/useViewport';
import Checklist from '../Checklist/Checklist';
import { taskMapping, taskSchema } from '../../lib/mapping/Form/taskSchema';
import Form from '../Form/Form';
import CenteredLoader from '../Loader/CenteredLoader';
import { getBrazilianDate, getMinutesFromHHMM } from '../../lib/helpers/helper';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import DuplicateTaskButton from '../Task/DuplicateTaskButton';
import RemoveTaskButton from '../Task/RemoveTaskButton';
import { Div, spaces } from '../../styles/style';
import ConfirmModal from './ConfirmModal';
import useDisabledDate from '../../_Hooks/useDisabledDate';
import { countValidDays } from '../Timeline/GanttHelperFunctions';
import { Subtitle } from '../Text/Text';

const EditTaskModal = ({
  id = '',
  initData = {},
  onClose = f => f,
  title = 'Editar',
  model = 'task',
  options = {},
  idRefurbish,
  idTemplate,
  idCompany,
  canChangeRefurbish = false,
  afterSubmit = f => f,
  generalTask = false,
  copyLinkParam = 't'
}) => {
  const { data, setData, handleUpdate, handleCreate, loading } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options: {
      ...options,
      include: [...(options?.include || []), 'files', 'performedHours']
    },
    immediatelyLoadData: !!id
  });

  const { handleGet: getRefurbish, loading: loadingRefurbish } = useCRUD({
    model: 'refurbish',
    options: {
      include: ['refurbishDayoffs'],
      attributes: ['workingDays']
    },
    immediatelyLoadData: false
  });

  const _idRefurbish = data?.idRefurbish || idRefurbish;

  const { checklist } = data || {};

  const transformChecklist = list => {
    const orderList = list.map((item, index) => ({ ...item, order: item?.order || index + 1 }));

    return orderList.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
  };

  const [_checklist, setChecklist] = useState();
  const [files, setFiles] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [editingData, setEditingData] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [workDays, setWorkDays] = useState(null);
  const [dayoffs, setDayoffs] = useState(null);
  const [newValues, setNewValues] = useState(null);

  const [draftOptions, setDraftOptions] = useState({});
  const hasChangedValuesRef = useRef(false);
  const forceUpdate = useRef(false);

  const { isMobile } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const { company } = user;
  const { execution, activeOpportunity } = useSelector(state => state.setup.enums.refurbishStatus);

  const onSetDraftOptions = drafts => {
    setDraftOptions(drafts);
    hasChangedValuesRef.current = true;
  };

  const { returnToOriginalUrl } = useReplaceUrl({
    preventTrigger: !copyLinkParam || !!idTemplate || !id,
    urlToReplace: `/${copyLinkParam}/${id}`
  });

  const { findValidRange, changeDate } = useDisabledDate({ workDays, dayoffs });

  const handleSubmit = values => {
    const {
      realDuration,
      estimativeDuration,
      startDate,
      endDate,
      startHours,
      endHours,
      totalPerformedHours,
      ..._values
    } = values;

    const _startDate = startDate
      ? dayjs(startDate)
          ?.hour(dayjs(startHours || '00:00', 'HH:mm')?.hour())
          ?.minute(dayjs(startHours || '00:00', 'HH:mm')?.minute())
          ?.second(0)
      : startDate;
    const _endDate = endDate
      ? dayjs(endDate)
          ?.hour(dayjs(endHours || '23:59', 'HH:mm')?.hour())
          ?.minute(dayjs(endHours || '23:59', 'HH:mm')?.minute())
          ?.second(0)
      : endDate;

    const func = !id ? handleCreate : handleUpdate;
    const _data = {
      idRefurbish: _idRefurbish,
      ..._values,
      startDate: _startDate,
      endDate: _endDate,
      ...(realDuration && { realDuration: getMinutesFromHHMM(realDuration) }),
      ...(estimativeDuration && { estimativeDuration: getMinutesFromHHMM(estimativeDuration) }),
      checklist: _checklist,
      idRefurbishStep: values.idRefurbishStep || 1,
      idResponsible: values?.idResponsible || null,
      files,
      removedFiles,
      draftOptions,
      source: generalTask ? 'General' : 'Project'
    };
    setEditingData(_data);
    return func({ values: _data, noLoading: true, refresh: false }).then(resp => {
      if (!resp?.error) {
        returnToOriginalUrl();
        onClose(resp);
        afterSubmit({ item: resp });
      }
    });
  };

  useEffect(() => {
    setChecklist(transformChecklist(checklist || []));
  }, [checklist]);

  useEffect(() => {
    if (data && data.id) {
      setFiles(data.files || []);
    }
  }, [data]);

  useEffect(() => {
    const _getRefurbish = _idRefurbish
      ? getRefurbish({ refetchPathOptions: `/${_idRefurbish}` }).then(refurbish => ({
          workDaysObject: refurbish?.workingDays,
          dayoffsObject: refurbish?.refurbishDayoffs
        }))
      : Promise.resolve({
          workDaysObject: company?.workingDays,
          dayoffsObject: company?.companyDayoffs
        });

    _getRefurbish.then(({ workDaysObject, dayoffsObject }) => {
      setWorkDays(idTemplate ? null : workDaysObject);
      setDayoffs(idTemplate ? null : dayoffsObject);
    });
  }, [_idRefurbish]);

  const onChangeDateRelatedField = field => val => {
    setNewValues(prev => {
      const _prev = prev || {};

      const { duration: _duration, startDate, endDate } = {
        ...initData,
        ...data,
        ..._prev
      };

      if (
        (field === 'duration' && !startDate && !endDate) ||
        (field === 'startDate' && !_duration && !endDate) ||
        (field === 'endDate' && !startDate && !_duration)
      )
        return { ..._prev, [field]: val };

      if (field !== 'duration' && !val) return { ..._prev, startDate: null, endDate: null };

      let start = field === 'startDate' ? val : startDate;
      let end = field === 'endDate' ? val : endDate;
      const duration = field === 'duration' ? val || 0 : _duration;

      if ((field === 'duration' && startDate) || (field === 'startDate' && _duration)) {
        end = changeDate(
          end,
          getBrazilianDate(start)
            .add(duration - 1, 'day')
            .hour(23)
            .minute(59)
        );
      } else if ((field === 'duration' && !startDate) || (field === 'endDate' && !startDate)) {
        start = changeDate(
          start,
          getBrazilianDate(end)
            .subtract(duration - 1, 'day')
            .hour(0)
            .minute(0)
        );
      } else if ((field === 'startDate' && endDate) || (field === 'endDate' && startDate)) {
        return {
          ..._prev,
          startDate: start,
          endDate: end,
          duration: countValidDays(start, end, { workDays, dayoffs })
        };
      }

      [start, end] = findValidRange(start, end, duration);

      return { ..._prev, startDate: start, endDate: end, duration };
    });
  };

  const _taskMapping = useMemo(
    () =>
      taskMapping({
        idRefurbish: _idRefurbish,
        idTemplate,
        idCompany,
        canChangeRefurbish,
        idTask: id,
        generalTask,
        refurbishStatusAllowed: [execution.id, ...activeOpportunity],
        workDays,
        dayoffs,
        totalPerformedHours: data?.totalPerformedHours,
        setDraftOptions,
        onSetDraftOptions,
        onChangeDateRelatedField
      }),
    [
      _idRefurbish,
      workDays,
      dayoffs,
      setDraftOptions,
      data?.totalPerformedHours,
      data?.startDate,
      data?.endDate,
      data?.duration
    ]
  );

  const HeaderExtraButton = useCallback(() => {
    return id ? (
      <Div gap={spaces.space0} padding={`0 0 0 ${spaces.space1}`} id="header-drawer-task">
        <Watcher idTask={id} idCompany={idCompany} />
        <ShareLinkDropdown id={id} urlContext="t" />
        <DuplicateTaskButton idTask={id} onDuplicated={task => onClose(task)} />
        <RemoveTaskButton idTask={id} onDeleted={() => onClose(data)} />
      </Div>
    ) : null;
  }, [id, data, idCompany]);

  const onRemoveFile = file => {
    if (file?.id) setRemovedFiles(prev => [...prev, file.id]);
  };

  const onFormChange = e => {
    if (e?.idRefurbish && data?.idRefurbish !== e?.idRefurbish) {
      setData(prev => ({ ...prev, ...e, idRefurbish: e?.idRefurbish, idRefurbishStep: null }));
      forceUpdate.current = true;
    }
    if (e?.idTemplate && data?.idTemplate !== e?.idTemplate) {
      setData(prev => ({ ...prev, ...e, idTemplate: e?.idTemplate, idTemplateStep: null }));
      forceUpdate.current = true;
    }
  };

  const _loading = loadingRefurbish || loading;

  return (
    <>
      <Drawer
        open
        formId="taskForm"
        title={title}
        onClose={() => {
          if (hasChangedValuesRef.current) {
            setShowConfirmModal(true);
            return null;
          }
          returnToOriginalUrl();
          onClose();
          return null;
        }}
        width={isMobile() ? '100%' : '60%'}
        headerExtraContent={HeaderExtraButton}
        styleDiv={{ display: 'flex', alignItems: 'center' }}
        headerStyle={{ display: 'flex', alignItems: 'center' }}
      >
        {_loading ? (
          <CenteredLoader />
        ) : (
          <Div direction="column" gap={spaces.space1} align="start" $fullWidth>
            <Form
              loading={_loading}
              schema={taskSchema}
              mapping={_taskMapping}
              initialParentValues={{
                startDate: data?.startDate || initData?.startDate,
                endDate: data?.endDate || initData?.endDate
              }}
              data={
                !id
                  ? { ...initData, ...data, ...editingData }
                  : {
                      ...data,
                      ...(data?.startDate && { startHours: dayjs(data?.startDate).format('HH:mm') }),
                      ...(data?.endDate && { endHours: dayjs(data?.endDate).format('HH:mm') })
                    }
              }
              newValues={newValues}
              id="taskForm"
              onSubmit={handleSubmit}
              displayButtons={false}
              onValueChanged={e => {
                hasChangedValuesRef.current = e;
              }}
              forceUpdate={forceUpdate}
              onFormChange={onFormChange}
            />
            <Subtitle>Checklist</Subtitle>
            <Checklist
              list={_checklist ? [..._checklist] : []}
              onChange={list => {
                setChecklist(transformChecklist(list || []));
                hasChangedValuesRef.current = true;
              }}
            />
            {!idTemplate && (
              <ItemAttachmentContent
                data={{ files }}
                setFiles={e => {
                  hasChangedValuesRef.current = true;
                  return setFiles(e);
                }}
                idRefurbish={_idRefurbish}
                textEditor={false}
                hasDescription={false}
                hideType
                onRemoveFile={onRemoveFile}
              />
            )}
          </Div>
        )}
      </Drawer>
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar."
          formId="taskForm"
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onClose={(_, opts) => {
            setShowConfirmModal(false);
            if (opts?.discard) {
              returnToOriginalUrl();
              onClose();
            }
          }}
        />
      )}
    </>
  );
};

EditTaskModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initData: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
  title: PropTypes.string,
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.number,
  idTemplate: PropTypes.number,
  idCompany: PropTypes.number,
  canChangeRefurbish: PropTypes.bool,
  afterSubmit: PropTypes.func,
  generalTask: PropTypes.bool,
  copyLinkParam: PropTypes.string
};

export default EditTaskModal;
