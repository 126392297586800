import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered, faPaperclip, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faFlag, faCheckCircle, faDiagramNested } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';

import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import EditableInput from '../../../components/Input/EditableInput';
import ColorPickerDropDown from '../../../components/Dropdown/ColorPickerDropDown';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import TaskActionDropdown from '../../../components/Dropdown/TaskActionDropdown';
import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import MaskedInput from '../../../components/Input/MaskedInput';
import PerformedHoursDropdown from '../../../components/Dropdown/PerformedHoursDropdown';

import { getBrazilianDate, getInitials, toHHMM, validDate } from '../../helpers/helper';
import RangePicker from '../../../components/Datepicker/RangePicker';
import Avatar from '../../../components/AvatarEditor/Avatar';

import { Div, breakpoints, colors, spaces } from '../../../styles/style';
import {
  StyledButton,
  StepNameContainer,
  ColorAndNameContainer,
  IconsDiv,
  ChecklistContainer,
  TaskNameContainer
} from './scheduleColumns.style';
import ExpandAllButton from '../../../components/Button/ExpandAllButton';
import { Paragraph } from '../../../components/Text/Text';
import { countValidDays } from '../../../components/Timeline/GanttHelperFunctions';

const handleParentName = _row => {
  if (_row?.idParent) {
    return _row.parent?.name;
  }
  return null;
};

const isTask = (_row, generalTask) => {
  return generalTask || !!(_row?.idTemplateStep || _row?.idRefurbishStep);
};

const columns = ({
  isMobile,
  handleChange,
  handleAdd,
  loadData,
  priorityList,
  statusList,
  serializedStatusArray,
  serializedPrioritiesArray,
  taskStatusEnum,
  refurbishStatusEnum,
  userType,
  idReference,
  referKey,
  isApply,
  idCompany,
  printColumns = [],
  generalTask,
  isDefaultOrder,
  setCurrentData,
  lastAdd,
  handleToggleAllRow,
  workDays,
  dayoffs
}) => {
  const isTemplate = referKey === 'idTemplate';
  const isPrint = !!printColumns?.length;
  const shouldRenderMoreColumns = window.innerWidth >= breakpoints?.wide?.split('px')[0];
  const getWorkDays = row => {
    return row?.refurbish?.workingDays || (generalTask && row?.idRefurbish ? null : workDays);
  };
  const getDayoffs = row => {
    return row?.refurbish?.refurbishDayoffs || (generalTask && row?.idRefurbish ? null : dayoffs);
  };

  const allColumns = [
    {
      title: () =>
        generalTask || isTemplate ? (
          'Tarefas'
        ) : (
          <ExpandAllButton
            handleToggleAllRow={handleToggleAllRow}
            title="Etapas/tarefas"
            tooltip="Abrir/fechar todos as etapas"
          />
        ),
      printTitle: generalTask || isTemplate ? 'Tarefas' : 'Etapas/tarefas',
      dataIndex: 'name',
      key: 'name',
      width: generalTask || isApply ? 300 : 450,
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        const { tasks, taskCount, onClick = f => f, longPressEvent = () => {} } = row;
        if (isPrint) {
          return <div style={{ paddingLeft: '8px' }}>{row?.name || '-'}</div>;
        }

        if (!isTask(row, generalTask) && !generalTask) {
          const _taskCount = tasks != null ? tasks.length : taskCount || 0;
          const isGeneral = row.id === 1;
          return (
            <StepNameContainer onClick={onClick} key={`step-container-${row.id}`}>
              <ColorAndNameContainer paddingLeft={isGeneral || isMobile ? 20 : null}>
                {!isGeneral && !isMobile ? (
                  <ColorPickerDropDown
                    color={row.color ? row.color : colors.neutral300}
                    onSelect={value =>
                      handleChange(
                        {
                          id: row.id,
                          color: value
                        },
                        false
                      )
                    }
                  />
                ) : null}
                {row?.id === 1 ? (
                  <div id="step-name">{val}</div>
                ) : (
                  <EditableInput
                    id="step-name"
                    key={`name${row.id}`}
                    value={val}
                    row={row}
                    onChange={name => handleChange({ id: row.id, name }, false)}
                    disabled={row.isDisabled || isApply}
                    initEdit={lastAdd?.current === row.id}
                    onClick={onClick}
                    rowId={row.id}
                    width="90%"
                  />
                )}
              </ColorAndNameContainer>
              <IconsDiv>
                {!isApply ? (
                  <Tooltip placement="top" title="Adicionar tarefa">
                    <FontAwesomeIcon
                      id="add-task"
                      icon={faPlus}
                      color={colors.primary600}
                      onClick={e => {
                        e.stopPropagation();
                        handleAdd(row.id, true);
                      }}
                    />
                  </Tooltip>
                ) : null}

                {isGeneral || isApply ? (
                  <div style={{ width: 36 }} />
                ) : (
                  <TaskActionDropdown
                    item={row}
                    idReference={idReference}
                    referKey={referKey}
                    referModel={isTemplate ? 'template-step' : 'refurbish-step'}
                    afterSubmit={() => loadData({})}
                    idCompany={idCompany}
                  />
                )}
                {_taskCount != null ? (
                  <Tooltip title={_taskCount && !isTemplate ? `Selecionar todas as tarefas` : null}>
                    <StyledButton
                      id="task-count"
                      type="primary"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      text
                    >
                      {_taskCount} tarefa{_taskCount !== 1 && 's'}
                    </StyledButton>
                  </Tooltip>
                ) : null}
              </IconsDiv>
            </StepNameContainer>
          );
        }

        const totalItemsChecklist = row.checklist?.length;
        const totalItemsChecklistDone = row.checklist?.filter(item => item.checked).length;
        const checklistIconColor =
          totalItemsChecklist === totalItemsChecklistDone ? colors.green300 : colors.neutral400;

        const checklistIconColorHover =
          totalItemsChecklist === totalItemsChecklistDone ? colors.green300 : colors.neutral600;

        return (
          <TaskNameContainer
            key={`task-name-${row.id}`}
            onClick={onClick}
            showIconOnHover={row.status !== 4}
            $checklistColorHover={checklistIconColorHover}
            $showLinkedTask={row.idParent}
          >
            {isMobile ? (
              <p {...(longPressEvent(row) || {})}>{val}</p>
            ) : (
              <EditableInput
                id="task-name"
                style={{ alignSelf: 'center' }}
                key={`name${row?.id}`}
                value={val}
                row={row}
                onChange={name => handleChange({ id: row?.id, name })}
                initEdit={lastAdd?.current === row.id}
                disabled={isApply}
                rowId={row.id}
                width="75%"
              />
            )}
            {row?.checklist && totalItemsChecklist !== 0 && (
              <Tooltip title="Checklist">
                <ChecklistContainer $color={checklistIconColor}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FontAwesomeIcon icon={faBarsStaggered} />
                  </div>

                  <div style={{ fontSize: '12px', marginLeft: '3px', fontWeight: 500, paddingTop: '1px' }}>
                    {totalItemsChecklistDone}/{totalItemsChecklist}
                  </div>
                </ChecklistContainer>
              </Tooltip>
            )}
            {row?.countFile > 0 ? <FontAwesomeIcon icon={faPaperclip} /> : null}
            {!isApply && (
              <TooltipIcon
                style={{ height: 16, width: 16 }}
                className="checkCircle showHover"
                text={row.status !== taskStatusEnum.finished.id ? 'Concluir tarefa' : 'Reabrir tarefa'}
                icon={faCheckCircle}
                iconColor={row.status === taskStatusEnum?.finished?.id ? colors.green300 : colors.neutral600}
                onClick={e => {
                  e.stopPropagation();
                  return !isApply
                    ? handleChange(
                        {
                          id: row.id,
                          status:
                            row.status !== taskStatusEnum.finished.id
                              ? taskStatusEnum.finished.id
                              : taskStatusEnum.execution.id
                        },
                        true,
                        row.status !== taskStatusEnum.finished.id
                      )
                    : null;
                }}
              />
            )}
            {!generalTask && (
              <TooltipIcon
                style={{ height: 16, width: 16 }}
                className="linkedTask showHover"
                text={handleParentName(row) || 'Vincular tarefa'}
                icon={faDiagramNested}
                iconColor={row.idParent ? colors.primary600 : colors.neutral600}
                onClick={e => {
                  e.stopPropagation();
                  if (!isApply) {
                    setCurrentData(row);
                  }
                }}
              />
            )}
          </TaskNameContainer>
        );
      },
      shouldCellUpdate: () => true
    },
    ...(generalTask
      ? [
          {
            title: 'Projeto/Oportunidade',
            dataIndex: ['refurbish', 'name'],
            key: 'idRefurbish',
            width: 160,
            sorter: true,
            renderMobile: true,
            render: (val, row) => {
              const { id, refurbish } = row;
              if (isPrint) {
                return row?.refurbish?.name;
              }

              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <ColumnWrapper
                    id="task-refurbish-name"
                    value={refurbish}
                    model="refurbish"
                    onSelect={_idRefurbish => {
                      return handleChange({ id, idRefurbish: _idRefurbish });
                    }}
                    options={{
                      order: [['name']]
                    }}
                    cleanValue={null}
                    canCreate={false}
                  />
                  {refurbish && (
                    <Link
                      to={`/${userType}/${
                        refurbish?.idStatus === refurbishStatusEnum.execution.id ? 'projetos' : 'oportunidades'
                      }/perfil/${refurbish.id}/${refurbish?.version === 'V2' ? 'tarefas' : 'cronograma'}`}
                      style={{ margin: spaces.space1 }}
                    >
                      <FontAwesomeIcon icon={faExternalLink} color={colors.primary600} size="xs" />
                    </Link>
                  )}
                </div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    ...(generalTask
      ? [
          {
            title: 'Etapa do projeto',
            dataIndex: ['refurbishStep', 'name'],
            key: 'idRefurbishStep',
            width: 140,
            sorter: true,
            renderMobile: true,
            render: (val, row) => {
              const { id, refurbishStep } = row;

              if (isPrint) {
                return refurbishStep?.name || '-';
              }
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <ColumnWrapper
                    id="task-refurbish-step"
                    value={refurbishStep}
                    disabled={!row?.refurbish?.id}
                    model={row?.refurbish?.id && 'refurbishStep'}
                    options={{
                      where: { or: [{ idRefurbish: row?.refurbish?.id }, { id: 1 }] },
                      order: [['name']]
                    }}
                    onSelect={idRefer => handleChange({ id, idRefurbishStep: idRefer })}
                    cleanValue={1}
                    canCreate={false}
                  />
                </div>
              );
            },
            shouldCellUpdate: false
          }
        ]
      : []),
    {
      title: 'Inicio',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 80,
      renderMobile: true,
      sorter: true,
      onCell: () => ({
        colSpan: 2
      }),
      render: (val, row) => {
        const { longPressEvent = () => {} } = row;
        const { id, onClick = f => f } = row;

        if (isPrint) {
          return (
            <div
              style={{
                marginLeft: row.startDate ? '0' : '60px',
                color: row.isDelayed && 'red'
              }}
              {...(longPressEvent(row) || {})}
            >
              {`${validDate(row.startDate)} - ${validDate(row.endDate)}`}
            </div>
          );
        }
        return id !== 1 ? (
          <div
            key={`endDate-${row.id}`}
            role="presentation"
            onClick={onClick}
            style={{ alignSelf: 'center', width: '100%' }}
            {...(longPressEvent(row) || {})}
          >
            <RangePicker
              id="calendar"
              taskCustomCalendar
              size="small"
              format="DD MMM"
              value={[
                row.startDate ? getBrazilianDate(row.startDate) : null,
                row.endDate ? getBrazilianDate(row.endDate) : null
              ]}
              onChange={value =>
                handleChange(
                  {
                    id,
                    startDate: value.start,
                    endDate: value.end,
                    taskBasedDate: value.taskBasedDate,
                    duration: value.duration
                  },
                  isTask(row, generalTask)
                )
              }
              disabled={isMobile || isApply}
              isDelayed={row.isDelayed}
              duration={row.duration}
              buttonProps={{ text: true }}
              isParent={!isTask(row, generalTask)}
              taskBasedDate={row.taskBasedDate}
              workDays={getWorkDays(row)}
              dayoffs={getDayoffs(row)}
            />
          </div>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.startDate !== nextRecord.startDate ||
        prevRecord.endDate !== nextRecord.endDate ||
        prevRecord.isDelayed !== nextRecord.isDelayed
    },
    {
      title: 'Final',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 80,
      renderMobile: true,
      sorter: true,
      onCell: () => ({
        colSpan: 0
      }),
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.startDate !== nextRecord.startDate ||
        prevRecord.endDate !== nextRecord.endDate ||
        prevRecord.isDelayed !== nextRecord.isDelayed
    },
    {
      title: 'Duração',
      dataIndex: 'duration',
      key: 'duration',
      width: 120,
      renderMobile: true,
      sorter: true,
      render: (val, row) => {
        if (!isTask(row, generalTask)) return null;

        const _duration =
          row?.duration ||
          countValidDays(
            row.startDate ? getBrazilianDate(row.startDate) : null,
            row.endDate ? getBrazilianDate(row.endDate) : null,
            { workDays: getWorkDays(row), dayoffs: getDayoffs(row) }
          );

        if (isPrint) {
          return <Paragraph>{_duration} dias</Paragraph>;
        }

        return (
          <Div justify="space-between" gap={spaces.space1}>
            <MaskedInput
              id="task-duration"
              $noBorder
              type="number"
              size="small"
              value={Number(_duration)}
              onBlur={value => {
                if (val !== value) handleChange({ id: row.id, duration: value }, true, null);
              }}
              style={{ maxWidth: spaces.space9 }}
              formatValue={value => {
                const intValue = parseInt(value || '0', 10);
                if (Number.isNaN(intValue)) {
                  toast.error('Duração inválida.');
                  return _duration;
                }
                return intValue;
              }}
              placeholder="0"
              onlyPlaceholder
            />
            <span> dias</span>
          </Div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.duration !== nextRecord.duration ||
        prevRecord.startDate !== nextRecord.startDate ||
        prevRecord.endDate !== nextRecord.endDate
    },
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas estimadas',
            dataIndex: 'estimativeDuration',
            key: 'estimativeDuration',
            width: 120,
            render: (val, row) => {
              if (isPrint || isApply) return <div>{`${toHHMM(row.estimativeDuration) || ''}`}</div>;

              return (
                <MaskedInput
                  id="time-extimated"
                  readOnly={!isTask(row, generalTask)}
                  type="tel"
                  $noBorder
                  value={row.estimativeDuration}
                  onBlur={value => handleChange({ id: row.id, estimativeDuration: value }, true, null)}
                  style={{ maxWidth: '90px' }}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.estimativeDuration !== nextRecord.estimativeDuration
          }
        ]
      : []),
    ...(shouldRenderMoreColumns
      ? [
          {
            title: 'Horas realizadas',
            dataIndex: 'estimativeDuration',
            key: 'estimativeDuration',
            width: 120,
            render: (val, row) => {
              const isTaskRow = isTask(row, generalTask);

              if (isPrint || isApply || !isTaskRow)
                return <div>{`${toHHMM(isTaskRow ? row.totalPerformedHours : row.realDuration) || ''}`}</div>;
              return (
                <PerformedHoursDropdown
                  totalHours={row.totalPerformedHours}
                  isApply={isApply}
                  idTask={row.id}
                  afterSubmit={() => loadData({})}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
          }
        ]
      : []),
    {
      title: 'Responsável',
      dataIndex: ['user', 'name'],
      key: 'idResponsible',
      width: 120,
      sorter: !isPrint,
      render: (val, row) => {
        if (isPrint) {
          return <div>{row?.user?.name || '-'}</div>;
        }
        const { id, user } = row;
        return isTask(row, generalTask) ? (
          <ColumnWrapper
            id="task-responsible"
            model="user"
            options={{ where: { isActive: true, idCompany }, order: [['name']] }}
            value={user}
            onSelect={idResponsible => handleChange({ id, idResponsible })}
            cleanValue={null}
            canCreate={false}
            IconSelector={({ item }) => (
              <Avatar size="small" src={item.avatarFullpath}>
                {getInitials(item.name)}
              </Avatar>
            )}
            disabled={isApply}
          >
            {user && (
              <Avatar size="small" src={user?.avatarFullpath}>
                {getInitials(user?.name)}
              </Avatar>
            )}
          </ColumnWrapper>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idResponsible !== nextRecord.idResponsible || !isDefaultOrder
    },
    {
      title: 'Prioridade',
      dataIndex: 'priority',
      key: 'priority',
      width: 100,
      renderMobile: true,
      sorter: !isPrint,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedPrioritiesArray[val];

        if (isPrint) return <div>{value?.label || '-'}</div>;

        return isTask(row, generalTask) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.priority ? <StatusSelector icon={faFlag} status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={priority => handleChange({ id, priority })}
                loadList={priorityList}
                cleanValue={null}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector icon={faFlag} status={item} />}
                disabled={isApply}
                align="center"
              >
                {row?.priority ? <StatusSelector icon={faFlag} status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.priority !== nextRecord.priority || !isDefaultOrder
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: isPrint ? 120 : 48,
      colSpan: isPrint ? 1 : 2,
      renderMobile: true,
      sorter: !isPrint,
      render: (val, row) => {
        const { id } = row;
        const { longPressEvent = () => {} } = row;
        const value = serializedStatusArray[val];
        const statusSerialized = serializedStatusArray[val] || val;

        if (isPrint) return <div>{statusSerialized?.label}</div>;
        return isTask(row, generalTask) ? (
          <>
            {isMobile ? (
              <div style={{ flex: 1 }} {...(longPressEvent(row) || {})}>
                {row?.status ? <StatusSelector status={value} /> : '-'}
              </div>
            ) : (
              <ColumnWrapper
                value={value}
                _key="value"
                onSelect={status =>
                  handleChange(
                    { id, status },
                    true,
                    status === taskStatusEnum.finished.id,
                    status === taskStatusEnum.cancelled.id
                  )
                }
                loadList={statusList}
                canCreate={false}
                showClean={false}
                IconSelector={({ item }) => <StatusSelector status={item} />}
                disabled={isApply}
                align="center"
                id={`status-${id}`}
              >
                {row?.status ? <StatusSelector status={value} /> : '-'}
              </ColumnWrapper>
            )}
          </>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status || !isDefaultOrder
    },
    {
      title: '',
      key: '',
      dataIndex: 'action',
      colSpan: 0,
      width: 70,
      renderMobile: true,
      align: 'left',
      render: (val, row) => {
        if (isPrint) return <div />;

        return isTask(row, generalTask) && !isApply ? (
          <div role="presentation" onClick={e => e.stopPropagation()} style={{ ...(isMobile && { flex: 1 }) }}>
            <TaskActionDropdown
              item={row}
              idReference={idReference}
              afterSubmit={loadData}
              generalTask={generalTask || isTemplate}
              referKey={referKey}
              idCompany={idCompany}
              isTask={isTask(row, generalTask)}
            />
          </div>
        ) : null;
      },
      shouldCellUpdate: (prevRecord, nextRecord) => JSON.stringify(prevRecord) !== JSON.stringify(nextRecord)
    }
  ];

  if (isPrint) {
    return allColumns.filter(c => printColumns.includes(c.key) && !c.onlyMobile && c.dataIndex);
  }

  return isMobile ? allColumns.filter(c => c.renderMobile) : allColumns.filter(c => !c.onlyMobile);
};

export { columns };
