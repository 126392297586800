import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFileInvoiceDollar,
  faConstruction,
  faFolderOpen,
  faUserGroup,
  faHandshake,
  faLoveseat,
  faCog,
  faFile,
  faClipboardListCheck,
  faNotebook,
  faGaugeHigh,
  faScrewdriverWrench,
  faCoins,
  faBagShopping,
  faMoneyCheckDollar,
  faBook,
  faListTree,
  faRulerTriangle,
  faCircleDollar,
  faHandHoldingDollar,
  faList,
  faGrid2Plus,
  faUsers,
  faPersonDigging,
  faCartFlatbedBoxes,
  faCommentsDollar
} from '@fortawesome/pro-duotone-svg-icons';

const operator = {
  dashboard: {
    key: 'dashboard',
    to: '/operador/dashboard',
    noShowCollapse: true,
    label: 'Dashboard',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  catalog: {
    key: 'catalog',
    to: '/operador/catalogo',
    label: 'Minha biblioteca',
    canRead: true,
    roles: ['admin'],
    icon: <FontAwesomeIcon icon={faLoveseat} />,
    children: [
      {
        key: 'vobi',
        to: '/operador/catalogo',
        label: 'Produtos Vobi'
      },
      {
        key: 'categorias',
        to: '/operador/catalogo/minhas-categorias',
        label: 'Categorias'
      },
      {
        key: 'produtos',
        to: '/operador/biblioteca',
        label: 'Produtos'
      },
      {
        key: 'marcas',
        to: '/operador/catalogo/marcas',
        label: 'Marcas'
      }
    ]
  },
  work: {
    key: 'work',
    to: '/operador/projetos',
    label: 'Projetos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faConstruction} />
  },
  users: {
    key: 'users',
    to: '/operador/usuarios',
    label: 'Usuários',
    canRead: true,
    icon: <FontAwesomeIcon icon={faUserGroup} />,
    children: [
      {
        key: 'clientes',
        to: '/operador/usuarios/clientes',
        label: 'Clientes e Fornecedores'
      },
      {
        key: 'operadores',
        to: '/operador/usuarios/operadores',
        label: 'Operadores'
      },
      {
        key: 'profissionais',
        to: '/operador/usuarios/profissionais',
        label: 'Profissionais'
      }
    ]
  },
  configuration: {
    key: 'configuration',
    to: '/operador/configuracao',
    label: 'Configuração',
    canRead: true,
    icon: <FontAwesomeIcon icon={faCog} />,
    children: [
      {
        key: 'pipedrive',
        to: '/operador/configuracao/pipedrive',
        label: 'Exportar Pipedrive'
      },
      {
        key: 'search',
        to: '/operador/configuracao/search',
        label: 'Configurações do Search'
      },
      {
        key: 'notificacoes',
        to: '/operador/configuracao/notificacoes',
        label: 'Notificações'
      },
      {
        key: 'status',
        to: '/operador/configuracao/status',
        label: 'Status'
      },
      {
        key: 'templates',
        to: '/operador/configuracao/templates/todos',
        label: 'Templates'
      },
      {
        key: 'broadcasting',
        to: '/operador/configuracao/broadcasting',
        label: 'Enviar Notificação'
      }
    ]
  }
};

const provider = {
  dashboard: {
    key: 'dashboard',
    to: '/profissional/dashboard',
    noShowCollapse: true,
    label: 'Home',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  projects: {
    key: 'projects',
    to: '/profissional/projetos',
    label: 'Projetos',
    canRead: true,
    roles: ['project'],
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faFolderOpen} />
  },
  opportunities: {
    key: 'opportunities',
    to: '/profissional/oportunidades',
    label: 'Oportunidades',
    canRead: true,
    roles: ['opportunity'],
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faHandshake} />
  },
  productivity: {
    key: 'productivity',
    label: 'Produtividade',
    canRead: true,
    roles: ['productivity'],
    icon: <FontAwesomeIcon icon={faGaugeHigh} />,
    children: [
      {
        key: 'gestao',
        to: '/profissional/produtividade/gestao-tarefas',
        label: 'Gestão de Tarefas',
        roles: ['tasks']
      },
      {
        key: 'cronograma',
        to: '/profissional/produtividade/cronograma-geral',
        label: 'Cronograma Geral',
        roles: ['generalSchedule']
      },
      {
        key: 'gestao-horas',
        to: '/profissional/produtividade/gestao-horas',
        label: 'Gestão de Horas',
        roles: ['performedHours']
      }
    ]
  },
  build: {
    key: 'build',
    label: 'Gestão de Obras',
    canRead: true,
    roles: ['purchase', 'workDiary', 'generalPlanning'],
    icon: <FontAwesomeIcon icon={faPersonDigging} />,
    children: [
      {
        key: 'purchase',
        to: '/profissional/gestao-de-compras/panorama',
        label: 'Gestão de Compras',
        roles: ['purchase']
      },
      {
        key: 'planning',
        to: '/profissional/planejamentos',
        label: 'Planejamentos',
        roles: ['generalPlanning']
      },
      {
        key: 'acompanhamento-obras',
        to: '/profissional/acompanhamento-obras',
        label: 'Acompanhamento de obra',
        roles: ['workMonitoring']
      },
      {
        key: 'diario-de-obra',
        to: '/profissional/diario-de-obra',
        label: 'Diário de Obra',
        roles: ['workDiary']
      }
    ]
  },
  financial: {
    key: 'financial',
    label: 'Financeiro',
    canRead: true,
    roles: ['financial', 'financialSupplier'],
    icon: <FontAwesomeIcon icon={faCoins} />,
    children: [
      {
        key: 'resumo',
        to: '/profissional/financeiro/resumo',
        label: 'Resumo',
        roles: ['financialResume']
      },
      {
        key: 'pagamentos',
        to: '/profissional/financeiro/cobrancas',
        label: 'Pagamentos do cliente',
        roles: ['financialSupplier', 'financialOwnBusiness']
      },
      {
        key: 'receitas',
        to: '/profissional/financeiro/receitas',
        label: 'Receitas',
        roles: ['financialIncome']
      },
      {
        key: 'despesas',
        to: '/profissional/financeiro/despesas',
        label: 'Despesas',
        roles: ['financialExpense']
      },
      {
        key: 'transferencias',
        to: '/profissional/financeiro/transferencias',
        label: 'Transferências',
        roles: ['financialTransfer']
      },
      {
        key: 'resultados',
        to: '/profissional/financeiro/resultados',
        label: 'Resultados',
        roles: ['financialResults']
      },
      {
        key: 'extrato',
        to: '/profissional/financeiro/extrato',
        label: 'Extrato',
        roles: ['financialBankStatement']
      },
      {
        key: 'relatorios',
        label: 'Relatórios',
        roles: ['financialReport'],
        children: [
          {
            key: 'cashFlow',
            label: 'Fluxo de caixa',
            children: [
              {
                key: 'monthly',
                to: '/profissional/financeiro/relatorios?option=cashFlow-monthly',
                label: 'Mensal'
              },
              {
                key: 'daily',
                to: '/profissional/financeiro/relatorios?option=cashFlow-daily',
                label: 'Diário'
              }
            ]
          },
          {
            key: 'dre',
            to: '/profissional/financeiro/relatorios?option=dre',
            label: 'DRE Gerencial'
          },
          {
            key: 'consolidatedByProject',
            to: '/profissional/financeiro/relatorios?option=consolidatedByProject',
            label: 'Resultados consolidados por projeto'
          },
          {
            key: 'foreseenByProject',
            to: '/profissional/financeiro/relatorios?option=foreseenByProject',
            label: 'Resultados previstos por projeto'
          },
          {
            key: 'generalReport',
            to: '/profissional/financeiro/relatorios/gerais',
            label: 'Relatórios gerais'
          }
        ]
      }
    ]
  },
  vobipay: {
    key: 'vobipay',
    label: 'Vobi Pay',
    canRead: true,
    roles: ['vobipay'],
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    children: [
      {
        key: 'resumoVobiPay',
        to: '/profissional/vobipay/resumo',
        label: 'Resumo',
        roles: ['vobipaySummary', 'withdraw']
      },
      {
        key: 'cobrancaVobiPay',
        to: '/profissional/vobipay/cobrancas',
        label: 'Cobranças',
        roles: ['vobipayCharge']
      },
      {
        key: 'extratoVobiPay',
        to: '/profissional/vobipay/extrato',
        label: 'Extrato',
        roles: ['bankStatement']
      },
      {
        key: 'simuladroVobiPay',
        to: '/profissional/vobipay/simulador',
        label: 'Simulador de cobranças'
      }
    ]
  },
  tools: {
    key: 'tools',
    label: 'Ferramentas',
    canRead: true,
    roles: ['tools'],
    icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
    children: [
      {
        key: 'templates',
        to: '/profissional/ferramentas/meus-templates/todos',
        label: 'Templates',
        roles: ['template']
      },
      {
        key: 'moodboard',
        to: '/profissional/moodboard',
        label: 'Moodboards',
        roles: ['moodboard']
      },
      {
        key: 'portfolio',
        to: '/profissional/meu-site',
        label: 'Portfólio',
        roles: ['portfolio']
      },
      {
        key: 'extensao',
        to: 'https://go.vobi.com.br/9wMpqd/',
        label: 'Extensão de Captura',
        external: true
      },
      {
        key: 'cron',
        to: 'https://go.vobi.com.br/cronometro-menu',
        label: 'Extensão Cronômetro',
        external: true
      },
      {
        key: 'catalogo',
        to: '/profissional/catalogo',
        label: 'Catálogo Vobi',
        roles: ['catalog']
      }
    ]
  },
  registrations: {
    key: 'registrations',
    label: 'Cadastros',
    roles: ['registrations'],
    canRead: true,
    icon: <FontAwesomeIcon style={{ width: '14px' }} icon={faList} />,
    children: [
      {
        key: 'produtos',
        to: '/profissional/biblioteca',
        label: 'Minha Biblioteca',
        roles: ['products', 'composition']
      },
      {
        key: 'grupos',
        to: '/profissional/grupos-e-categorias',
        label: 'Grupos, Categorias e Unidades',
        roles: ['group', 'categories', 'units']
      },
      {
        key: 'contatos',
        to: '/profissional/contatos',
        label: 'Contatos',
        roles: ['customer', 'supplier']
      },
      {
        key: 'financeiro',
        to: '/profissional/cadastro-financeiro',
        label: 'Financeiro',
        roles: ['financial']
      },
      {
        key: 'birthday',
        to: '/profissional/contatos/clientes/aniversario',
        label: 'Aniversário de clientes',
        roles: ['customer']
      }
    ]
  },
  vobiSolutions: {
    key: 'id-website-creator',
    label: 'Soluções Vobi',
    roles: ['websiteCreator', 'vobiSolutions'],
    canRead: true,
    to: '/profissional/assinatura-solucoes',
    icon: <FontAwesomeIcon style={{ width: '14px' }} icon={faGrid2Plus} />
  },
  vobiCommunity: {
    key: 'vobiCommunity',
    label: 'Comunidade',
    roles: [],
    canRead: true,
    icon: <FontAwesomeIcon style={{ width: '14px' }} icon={faUsers} />,
    children: [
      {
        key: 'vobiVipGroup',
        to: 'https://go.vobi.com.br/menu-lateral',
        label: 'Grupo Vobi VIP',
        roles: [],
        external: true
      },
      {
        key: 'platformUpdates',
        to: 'https://ajuda.vobi.com.br/pt-BR/collections/2612734-novidades-vobi?utm_source=app&utm_id=menu-comunidade',
        label: 'Atualizações Vobi',
        roles: [],
        external: true
      },
      {
        key: 'vobiHelp',
        to: 'https://ajuda.vobi.com.br/pt-BR/?utm_source=app&utm_id=menu-comunidade',
        label: 'Central de Ajuda',
        roles: [],
        external: true
      },
      {
        key: 'implementationGuide',
        to: 'https://plataforma.vobi.com.br/profissional/guia-implantacao?utm_source=app&utm_id=menu-comunidade',
        label: 'Guia de Implantação',
        roles: [],
        external: true
      }
    ]
  }
};

const customerV2 = {
  dashboard: {
    key: 'dashboard',
    to: '/cliente/dashboard',
    noShowCollapse: true,
    label: 'Dashboard',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  budget: {
    key: 'budget',
    permissionId: 'item',
    to: '/cliente/orcamento-v2',
    label: 'Orçamento',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFileInvoiceDollar} />
  },
  purchaseOverview: {
    key: 'purchaseOverview',
    permissionId: 'purchaseOverview',
    to: '/cliente/gestao-de-compras/panorama',
    label: 'Panorama de Compras',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faCartFlatbedBoxes} />
  },
  quotation: {
    key: 'quotation',
    permissionId: 'quotation',
    to: '/cliente/gestao-de-compras/cotacoes',
    label: 'Cotações',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faCommentsDollar} />
  },
  timeline: {
    key: 'timeline',
    permissionId: 'timeline',
    to: '/cliente/cronograma',
    label: 'Cronograma',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faClipboardListCheck} />
  },
  payments: {
    key: 'paymentsV2',
    permissionId: 'invoice',
    to: '/cliente/pagamentos-v2',
    label: 'Pagamentos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faCoins} />
  },
  shopping: {
    key: 'compras',
    permissionId: 'shopping',
    to: '/cliente/compras',
    label: 'Compras',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faBagShopping} />
  },
  files: {
    key: 'Arquivos',
    permissionId: 'file',
    to: '/cliente/arquivos',
    label: 'Arquivos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFile} />
  },
  propostas: {
    key: 'Propostas',
    permissionId: 'proposal',
    to: '/cliente/propostas',
    label: 'Propostas',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '14px', height: '12px' }} icon={faHandshake} />
  },
  anotacoes: {
    key: 'Anotacoes',
    permissionId: 'note',
    to: '/cliente/anotacoes',
    label: 'Anotações',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faNotebook} />
  },
  planejamento: {
    key: 'planejamento',
    permissionId: 'planning',
    to: '/cliente/obra/planejamento',
    label: 'Planejamento',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faListTree} />
  },
  medicoes: {
    key: 'medicoes',
    permissionId: 'measurement',
    to: '/cliente/obra/medicoes',
    label: 'Medições',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faRulerTriangle} />
  },
  'diario-de-obra': {
    key: 'Diario-de-obra',
    permissionId: 'workDiary',
    to: '/cliente/obra/diario-de-obra',
    label: 'Diário de Obra',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faBook} />
  },
  vobiCred: {
    key: 'vobiCred',
    permissionId: 'vobiCred',
    to: '/cliente/vobi-cred?tab=1',
    label: 'Vobi Cred',
    tooltip: 'Vobi Cred: Simule crédito e financiamento imobiliário em apenas alguns cliques',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />
  }
};

const customer = {
  dashboard: {
    key: 'dashboard',
    to: '/cliente/dashboard',
    noShowCollapse: true,
    label: 'Dashboard',
    canRead: true,
    icon: <FontAwesomeIcon icon={faHome} />
  },
  budget: {
    key: 'budget',
    permissionId: 'item',
    to: '/cliente/orcamento',
    label: 'Orçamento',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFileInvoiceDollar} />
  },
  timeline: {
    key: 'timeline',
    permissionId: 'timeline',
    to: '/cliente/cronograma',
    label: 'Cronograma',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faClipboardListCheck} />
  },
  payments: {
    key: 'payments',
    permissionId: 'payment',
    to: '/cliente/parcelas',
    label: 'Pagamentos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faCoins} />
  },
  financial: {
    key: 'Cobrancas',
    permissionId: 'invoice',
    to: '/cliente/cobrancas',
    label: 'Cobranças',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} />
  },
  shopping: {
    key: 'compras',
    permissionId: 'shopping',
    to: '/cliente/compras',
    label: 'Compras',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faBagShopping} />
  },
  files: {
    key: 'Arquivos',
    permissionId: 'file',
    to: '/cliente/arquivos',
    label: 'Arquivos',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '15px' }} icon={faFile} />
  },
  propostas: {
    key: 'Propostas',
    permissionId: 'proposal',
    to: '/cliente/propostas',
    label: 'Propostas',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon style={{ width: '14px', height: '12px' }} icon={faHandshake} />
  },
  anotacoes: {
    key: 'Anotacoes',
    permissionId: 'note',
    to: '/cliente/anotacoes',
    label: 'Anotações',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faNotebook} />
  },
  'diario-de-obra': {
    key: 'Diario-de-obra',
    permissionId: 'workDiary',
    to: '/cliente/diario-de-obra',
    label: 'Diário de Obra',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faBook} />
  },
  vobiCred: {
    key: 'vobiCred',
    permissionId: 'vobiCred',
    to: '/cliente/vobi-cred?tab=1',
    label: 'Vobi Cred',
    canRead: true,
    noShowCollapse: true,
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />
  }
};

export const navItems = { operator, provider, customer, customerV2 };
