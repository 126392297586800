import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import CenteredLoader from '../Loader/CenteredLoader';

import { StyledDiv, StyledDrawer, Header } from './Drawer.styles';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import { Subtitle } from '../Text/Text';

const Drawer = ({
  title = 'Titulo modal',
  subtitle,
  open,
  onSubmit = () => {},
  onClose = () => {},
  width = 600,
  maxWidth,
  placement,
  submitText = 'Confirmar',
  cancelText = 'Cancelar',
  children,
  loading,
  footer,
  hideFooter,
  formId,
  headerExtraContent: HeaderExtraContent,
  extraContentOnRight,
  styleDiv = {},
  HowDoesItWorks,
  aditionalInformation,
  headerStyle,
  onScroll,
  noTitlePadding,
  showSubmit = true,
  showCancel = true,
  subtitleStyle,
  ...props
}) => {
  const _footer = footer || (
    <>
      {showCancel && (
        <Button onClick={onClose} loading={loading} text>
          {cancelText}
        </Button>
      )}
      {showSubmit && (
        <Button id="submit-drawer" htmlType="submit" type="primary" loading={loading} form={formId} onClick={onSubmit}>
          {submitText}
        </Button>
      )}
    </>
  );

  const Title = (
    <div style={{ ...styleDiv }}>
      <StyledDiv noTitlePadding={noTitlePadding}>
        <Header extraContentOnRight={extraContentOnRight} style={headerStyle}>
          <span>{title}</span>

          {HeaderExtraContent ? <HeaderExtraContent /> : null}
        </Header>
        {HowDoesItWorks && <HowDoesItWorksButton id={HowDoesItWorks} />}
      </StyledDiv>
      {subtitle && (
        <Subtitle weight={400} style={subtitleStyle}>
          {subtitle}
        </Subtitle>
      )}

      {aditionalInformation && aditionalInformation}
    </div>
  );

  return (
    <StyledDrawer
      title={Title}
      open={open}
      onClose={onClose}
      placement={placement}
      width={width}
      maxWidth={maxWidth}
      footer={hideFooter ? null : _footer}
      {...props}
    >
      {loading ? <CenteredLoader /> : <section onScroll={onScroll}>{children}</section>}
    </StyledDrawer>
  );
};

Drawer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placement: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  footer: PropTypes.instanceOf(Object),
  hideFooter: PropTypes.bool,
  formId: PropTypes.string,
  headerExtraContent: PropTypes.instanceOf(Object),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  styleDiv: PropTypes.instanceOf(Object),
  HowDoesItWorks: PropTypes.string,
  aditionalInformation: PropTypes.instanceOf(Object),
  extraContentOnRight: PropTypes.bool,
  onScroll: PropTypes.func,
  headerStyle: PropTypes.instanceOf(Object),
  subtitleStyle: PropTypes.instanceOf(Object),
  noTitlePadding: PropTypes.bool,
  showSubmit: PropTypes.bool,
  showCancel: PropTypes.bool
};

export default Drawer;
