import * as Yup from 'yup';
import React from 'react';

import SelectDropdown from '../../../components/Dropdown/SelectDropdown';
import Avatar from '../../../components/AvatarEditor/Avatar';
import { date, requiredNumber } from '../schema';
import { getInitials } from '../../helpers/helper';
import MaskedInput from '../../../components/Input/MaskedInput';

const performedHoursSchema = Yup.object().shape({
  timePerformed: requiredNumber.moreThan(0, 'Campo obrigatório'),
  date,
  idResponsible: requiredNumber,
  idTask: requiredNumber
});

const performedHoursMapping = ({
  idRefurbish,
  idCompany,
  execution,
  responsible,
  setResponsible,
  timePerformed,
  setTimePerformed,
  generalTask,
  isMobile
}) => {
  const disabled = !idRefurbish;
  return {
    idResponsible: {
      type: 'custom',
      md: 3,
      xs: 4,
      // eslint-disable-next-line react/prop-types
      Component: ({ setField, onBlur }) => (
        <SelectDropdown
          model="user"
          trigger={['click']}
          options={{ where: { isActive: true, idCompany }, order: [['name']] }}
          onSelect={value => {
            setResponsible(value);
            onBlur();
            setField(value.id);
          }}
          data={responsible}
          overlayStyle={{ zIndex: 12000 }}
          canCreate={false}
        >
          <Avatar src={responsible?.avatarFullpath}>{getInitials(responsible?.name)}</Avatar>
        </SelectDropdown>
      )
    },
    ...(generalTask && {
      idRefurbish: {
        type: 'select',
        md: 21,
        xs: 20,
        model: 'refurbish',
        dropdownStyle: { zIndex: 13000 },
        placeholder: 'Selecione o projeto',
        modelOptions: {
          where: { idCompany, idStatus: execution.id, or: [{ '$activeStep.isActive$': true }, { idStep: null }] },
          include: ['activeStep'],
          order: [['name'], ['id']]
        },
        hasChildren: true
      },
      blank: {
        type: 'custom',
        md: 3,
        xs: 4,
        Component: () => <div />
      }
    }),
    idTask: {
      type: 'select',
      md: 11,
      xs: 20,
      dropdownStyle: { zIndex: 13000 },
      placeholder: 'Selecione a tarefa',
      isChangeableOptions: generalTask,
      ...(generalTask && {
        parent: {
          name: 'idRefurbish',
          lazyLoad: disabled,
          makeRefetchOptions: ({ field, value }) => ({
            where: { [field]: value },
            order: [['order'], ['id']]
          })
        }
      }),
      model: 'task',
      modelOptions: {
        where: {
          idCompany,
          idRefurbish
        },
        order: [['order'], ['id']]
      }
    },
    ...(isMobile && {
      blank2: {
        type: 'custom',
        sm: 0,
        xs: 4,
        Component: () => <div />
      }
    }),
    date: {
      type: 'date',
      allowClear: false,
      md: 5,
      xs: 8,
      format: 'DD/MM/YY',
      suffixIcon: false,
      popupStyle: { zIndex: 15000 }
    },
    timePerformed: {
      type: 'custom',
      md: 5,
      xs: 6,
      // eslint-disable-next-line react/prop-types
      Component: ({ onBlur, setField }) => (
        <MaskedInput
          id="performed-hours"
          type="tel"
          $noBorder
          placeholder="0:00"
          value={timePerformed}
          alwaysTriggerBlur
          onBlur={value => {
            setTimePerformed(value);
            onBlur();
            setField(value);
          }}
        />
      )
    }
  };
};

export { performedHoursSchema, performedHoursMapping };
