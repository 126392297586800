import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../Button/Button';
import Dropdown from './DropdownV2';
import { DropdownContainer, StyledMenu } from './DropdownV2.styled';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { colors } from '../../styles/style';
import SendQuoteToSupplierModal from '../Modal/SendQuoteToSupplierModal';
import useIsCustomer from '../../_Hooks/useIsCustomer';
import SpreadsheetImport from '../DataImport/SpreadsheetImport';
import { modelImportLinks } from '../../lib/helpers/helper';
import { useContextHook as useRefurbish } from '../../contexts/GeneralContext';

const QuoteSupplierActionDropdown = ({
  quoteSupplier,
  handleLoad,
  handleOpenEditQuoteSupplierDrawer,
  children,
  isDrawer,
  handlePrint,
  handleApproveOrReject,
  isPublicRoute
}) => {
  const { idQuote, idSupplier, id, status: idStatus, supplier, approved: isApproved } = quoteSupplier || {};

  const { idRefurbish } = useRefurbish();
  const { quoteSupplierMoveStatus, quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(false);
  const [openImportData, setOpenImportData] = useState(false);
  const [openImportItems, setOpenImportItems] = useState(false);

  const history = useHistory();

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const isCustomer = useIsCustomer();

  const { handleDelete, handleUpdate } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const handleCreateOrder = () => {
    history.push(
      `/profissional/gestao-de-compras/ordem-de-compras/novo?cotacao=${idQuote}&fornecedor=${idSupplier}${
        idRefurbish ? `&projeto=${idRefurbish}` : ''
      }`
    );
  };

  const deleteQuoteSupplier = () => {
    handleDelete({ id, refresh: false, displayToast: true }).then(() => handleLoad({ close: true }));
  };

  const updateQuoteSupplierStatus = status => {
    handleUpdate({ id, values: { status }, refresh: false, displayToast: true }).then(resp => {
      if (!resp?.error) handleLoad({ status: resp?.status });
    });
  };

  const getModel = e => {
    e.stopPropagation();

    window.location.href = modelImportLinks.quoteItemSuppliers;
  };

  const statusOptions = (
    <>
      {quoteSupplierMoveStatus[idStatus]?.forward?.map(status => (
        <Menu.Item key={`quote-suppliers-move-to-status-${status.id}`}>
          <Button onClick={() => updateQuoteSupplierStatus(status.id)} text>
            Mover para {status.name}
          </Button>
        </Menu.Item>
      ))}
      {quoteSupplierMoveStatus[idStatus]?.back?.map(status => (
        <Menu.Item key={`quote-suppliers-move-to-status-${status.id}`}>
          <Button onClick={() => updateQuoteSupplierStatus(status.id)} text>
            Voltar para {status.name}
          </Button>
        </Menu.Item>
      ))}
    </>
  );

  const seeDetails = (
    <Menu.Item key="quote-suppliers-see-details">
      <Button onClick={() => handleOpenEditQuoteSupplierDrawer()} text>
        Ver detalhes
      </Button>
    </Menu.Item>
  );

  let menu;

  if (isCustomer) {
    menu = (
      <StyledMenu $alignLeftOptions>
        {seeDetails}
        {quoteSupplierStatus.canApprove.includes(idStatus) && (
          <>
            {!isApproved && !isPublicRoute && (
              <Menu.Item key="quote-suppliers-approve">
                <Button onClick={() => handleApproveOrReject(true)} text>
                  Aprovar
                </Button>
              </Menu.Item>
            )}
            {isApproved && !isPublicRoute && (
              <Menu.Item key="quote-suppliers-reject">
                <Button onClick={() => handleApproveOrReject(false)} text>
                  Reprovar
                </Button>
              </Menu.Item>
            )}
          </>
        )}
      </StyledMenu>
    );
  } else {
    menu = (
      <StyledMenu $alignLeftOptions>
        {isDrawer ? (
          <>
            {statusOptions}
            {!_isMobile && (
              <Menu.Item key="quote-suppliers-see-details">
                <Button onClick={() => handlePrint()} text>
                  Exportar resposta em PDF
                </Button>
              </Menu.Item>
            )}
          </>
        ) : (
          <>
            {seeDetails}
            {statusOptions}
            {idStatus !== quoteSupplierStatus.buy.id && (
              <Menu.Item key="quote-suppliers-send-quote">
                <Button onClick={() => setOpenSendToSupplierModal(true)} text>
                  Enviar pedido de cotação
                </Button>
              </Menu.Item>
            )}
          </>
        )}
        {(idStatus === quoteSupplierStatus.answered.id ||
          idStatus === quoteSupplierStatus.analyzed.id ||
          idStatus === quoteSupplierStatus.buy.id) && (
          <Menu.Item key="quote-suppliers-create-order">
            <Button onClick={() => handleCreateOrder()} text>
              Criar ordem de compra e contratação
            </Button>
          </Menu.Item>
        )}
        {idStatus === quoteSupplierStatus.waitingResponse.id && (
          <>
            <Menu.Item key="quote-suppliers-import-quote">
              <Button onClick={() => setOpenImportData(true)} text>
                Importar dados da cotação
              </Button>
            </Menu.Item>
            <Menu.Item key="quote-suppliers-import-quote-items">
              <Button onClick={() => setOpenImportItems(true)} text>
                Importar itens da cotação
              </Button>
            </Menu.Item>
            <Menu.Item key="quote-suppliers-import-quote-model">
              <Button onClick={getModel} text>
                Modelo de importação
              </Button>
            </Menu.Item>
          </>
        )}
        <Menu.Item key="quote-suppliers-delete">
          <Button onClick={() => deleteQuoteSupplier()} text style={{ color: colors.red500 }}>
            Excluir
          </Button>
        </Menu.Item>
      </StyledMenu>
    );
  }
  return (
    <div role="presentation" onClick={e => e.stopPropagation()}>
      <DropdownContainer mobileDirection="column">
        <Dropdown slim trigger={['click']} menu={menu} padding={0}>
          {children}
        </Dropdown>
      </DropdownContainer>

      {openSendToSupplierModal && (
        <SendQuoteToSupplierModal
          idQuoteSupplier={id}
          onClose={() => setOpenSendToSupplierModal(false)}
          name={supplier.name}
          email={supplier.email}
          idQuote={idQuote}
        />
      )}
      <SpreadsheetImport
        model="quoteSuppliers"
        isOpen={openImportData}
        onClose={() => {
          setOpenImportData(false);
        }}
        body={{ idQuoteSupplier: id, idQuote }}
        onLoad={handleLoad}
        showToastError={false}
        toKebabCase={false}
        canNotify={false}
      />
      <SpreadsheetImport
        model="quoteItemSuppliers"
        isOpen={openImportItems}
        onClose={() => {
          setOpenImportItems(false);
        }}
        body={{ idQuoteSupplier: id, idQuote }}
        onLoad={handleLoad}
        showToastError={false}
        toKebabCase={false}
        canNotify={false}
      />
    </div>
  );
};

QuoteSupplierActionDropdown.propTypes = {
  quoteSupplier: PropTypes.instanceOf(Object),
  handleLoad: PropTypes.func,
  handleOpenEditQuoteSupplierDrawer: PropTypes.func,
  children: PropTypes.instanceOf(Object),
  isDrawer: PropTypes.bool,
  handlePrint: PropTypes.func,
  handleApproveOrReject: PropTypes.func,
  isPublicRoute: PropTypes.bool
};

export default QuoteSupplierActionDropdown;
