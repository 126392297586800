import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Context } from '../../contexts/GeneralContext';
import Button from '../../components/Button/Button';
import useFormState from '../../_Hooks/useFormState';

import useCRUD from '../../_Hooks/useCRUD';
import PaymentAttachmentContent from '../../components/Content/PaymentAttachmentContent';
import CenteredLoader from '../../components/Loader/CenteredLoader';
import PaymentNotesForm from '../../components/Form/PaymentNotesForm';

import {
  capitalize as capitalizeHelper,
  getColorByStatusPayment,
  validatePercentage,
  validatePurchaseTableItems
} from '../../lib/helpers/helper';
import { billSchema } from '../../lib/mapping/Form/billSchema';

import { RightHeaderContent, StatusContainer, Container } from './Payment.style';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import {
  getPaymentLabel,
  getValidationErrorMessage,
  extractInitialOrderData,
  serializedPaymentItems,
  conciliateProducts,
  prepareDataToDuplicate
} from '../../lib/helpers/payment';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import Drawer from '../../components/Drawer/Drawer';
import { colors, fonts, spaces } from '../../styles/style';
import useReplaceUrl from '../../_Hooks/useReplaceUrl';
import useViewport from '../../_Hooks/useViewport';
import RegisterInstallmentModal from '../../components/Modal/RegisterInstallmentModal';
import eventBus from '../../lib/helpers/eventBus';
import { BadgeText, Subtitle } from '../../components/Text/Text';
import PaymentConfiguration from '../../components/Payments/PaymentConfiguration';
import PaymentBasicForm from '../../components/Form/PaymentBasicForm';
import UndoReconciliationModal from '../../components/Modal/UndoReconciliationModal';
import PaymentFormItem from '../../components/Form/PaymentFormItem';
import useItemWithAppropriation from '../../_Hooks/useItemWithAppropriation';
import formatNumber from '../../lib/helpers/formatNumber';
import PaymentInvoiceList from '../../components/List/PaymentInvoiceList';
import ProductsConciliation from './ProductsConciliation';
import { Line } from '../../components/Line/Line';
import BillTotals from '../../components/Payments/BillTotals';
import CreatedByContent from '../../components/Content/CreatedByContent';

const confirmOptions = type => [
  { label: `${type} somente este`, value: 'only', id: 'save-only' },
  { label: `${type} este e os próximos lançamentos em aberto`, value: 'all', id: 'save-all' }
];

const Bill = ({
  isView,
  onClose,
  action,
  id,
  billType,
  idRefurbish,
  initialData,
  isConciliation,
  idOrder,
  orderDate,
  orderType,
  setPaymentToCreate,
  paymentToCreate,
  showProductsConciliation,
  setShowProductsConciliation,
  idToDuplicate,
  paymentComplete,
  selectedItems
}) => {
  const {
    installmentStatuses,
    paymentStatuses,
    billType: billTypeEnum,
    paymentOrderType,
    refurbishItemType
  } = useSelector(state => state.setup.enums);
  const [shouldReload, setShouldReload] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [statusColor, setStatusColor] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState('0');
  const [registerPayment, setRegisterPayment] = useState(false);
  const [undoReconciliation, setUndoReconciliation] = useState(false);
  const [showConfirmPendingModal, setShowConfirmPendingModal] = useState(false);
  const [isLoadingInstallments, setIsLoadingInstallments] = useState(false);
  const [_id, setId] = useState(id);
  const [extraValueErrors, setExtraValueErrors] = useState({});
  const { itemObjectsMap, itemSettersMap, clearStates } = useItemWithAppropriation();
  const [creatingFromOrder, setCreatingFromOrder] = useState(false);
  const [showConfirmCreateItemsModal, setShowConfirmCreateItemsModal] = useState(false);
  const [_showProductsConciliation, _setShowProductsConciliation] = useState(showProductsConciliation);
  const [_paymentToCreate, _setPaymentToCreate] = useState(paymentToCreate);
  const [data, setData] = useState(null);
  const [_idToDuplicate, setIdToDuplicate] = useState(idToDuplicate);
  const [invalidPaymentItems, setInvalidPaymentItems] = useState('');

  const { loading, handleGet, handleUpdate, handleCreate, handleDelete, setLoading } = useCRUD({
    model: 'payment',
    pathOptions: _id ? `/${_id}` : '',
    immediatelyLoadData: false
  });

  const { handleUpdate: installmentUpdate } = useCRUD({
    model: 'installment',
    pathOptions: `/${id}`,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetStatus } = useCRUD({
    model: 'payment',
    pathOptions: _id ? `/${_id}` : '',
    options: {
      attributes: [
        'idPaymentStatus',
        'paidValue',
        'openAmount',
        'cancelledValue',
        'allInterest',
        'allDueDateDiscount',
        'total',
        'taxes',
        'shipping',
        'otherTaxes',
        'discount',
        'paymentComplete'
      ],
      include: ['paymentStatus', 'installments', 'paymentItems']
    },
    immediatelyLoadData: false
  });

  const { handleGet: handleGetOrder, loading: loadingOrder } = useCRUD({
    model: 'order',
    pathOptions: `/${idOrder}`,
    immediatelyLoadData: false,
    options: {
      include: [
        'paymentTypes',
        { model: 'orderItems', include: [{ model: 'orderItemLevel', include: ['refurbishItem'] }] }
      ]
    }
  });

  const { handleGet: handleGetItems, loading: loadingItems } = useCRUD({
    model: 'refurbish-items/bulk/payment',
    immediatelyLoadData: false
  });

  const { handleCreate: handleBulkCreateItems } = useCRUD({
    model: 'item',
    pathOptions: `/bulk`,
    immediatelyLoadData: false
  });

  const model = {
    income: 'receitas',
    expense: 'despesas'
  }[billType];

  const shortUrl = {
    income: 'r',
    expense: 'd'
  }[billType];

  const {
    formState,
    handleBlur,
    handleChange,
    setField,
    isValid,
    handleSubmit,
    setTouched,
    valuesChanged
  } = useFormState(
    data
      ? {
          ...data,
          extraValues: null
        }
      : {
          name: initialData?.name || '',
          billingDate: initialData?.billingDate || orderDate || dayjs().format('YYYY-MM-DD'),
          idPaymentStatus: paymentStatuses.open,
          paymentTypes: [],
          installments: [],
          idRefurbish: Number(idRefurbish) || undefined,
          total: initialData?.total || 0,
          isCharge: false,
          billType,
          value: initialData?.value || 0,
          paymentItems: initialData?.paymentItems || [],
          paymentRecurrence: false,
          recurrence: {
            frequency: 'month',
            recurrenceCount: 1,
            interval: 1
          },
          ownBusiness: true,
          idPaymentBankAccount: initialData?.idPaymentBankAccount,
          paymentComplete: !!idOrder || paymentComplete,
          ...(idOrder ? { paymentOrderType: paymentOrderType[orderType] } : {})
        },
    billSchema,
    true
  );

  const changePaymentToCreate = setPaymentToCreate || _setPaymentToCreate;
  const changeShowProductsConciliation = setShowProductsConciliation || _setShowProductsConciliation;

  const { values, touched, errors } = formState;

  const installmentData = data?.installments?.[0];

  const pendingPayment = installmentData?.idInstallmentStatus === installmentStatuses?.pendingPayment;

  const textToDelete = values.recurrenceId
    ? 'Deseja excluir:'
    : `Ao excluir, a ${getPaymentLabel({
        billTypeEnum,
        billType
      })} será removida permanente da sua conta e ninguém mais poderá acessá-la.`;

  const textDeletePaymentIsOrder = `Ao excluir lançamentos financeiros associados à ordem de compra,
  a ordem de compra voltará para o status "Aberta".`;

  const handleLoad = () => {
    if (!_id && !_idToDuplicate) {
      toast.error('Desculpe. Página não encontrada.');
      history.push('/profissional/dashboard');
    }
    handleGet({
      refetchPathOptions: _id || _idToDuplicate ? `/${_id || _idToDuplicate}` : '',
      refetchOptions: {
        include: [
          { model: 'installment', include: ['installmentStatus', 'paymentType'] },
          'refurbish',
          {
            model: 'PaymentItem',
            separate: true,
            include: [
              {
                model: 'paymentItemLevel',
                include: ['refurbishItem']
              }
            ]
          },
          'paymentTypes',
          'paymentStatus',
          'companyCustomer',
          'files',
          {
            model: 'paymentInvoice',
            as: 'invoices',
            attributes: ['id', 'number', 'date', 'idPayment', 'accessKey', 'isImported'],
            include: ['file', 'fileXml']
          }
        ],
        order: [
          ['installments', 'dueDate', 'asc'],
          ['invoices', 'id', 'asc']
        ]
      }
    }).then(resp => {
      if (resp?.isCharge) {
        toast.error('Desculpe. Página não encontrada.');
        history.push('/profissional/dashboard');
      }
      const _data = _idToDuplicate ? prepareDataToDuplicate(resp) : resp;
      setData({ ..._data, idToDuplicate });
    });
  };

  useEffect(() => {
    if (_id || _idToDuplicate) {
      handleLoad();
    } else {
      setLoading(true);
      setData(null);
      setTimeout(() => setLoading(false), 100);
    }
  }, [_id, _idToDuplicate]);

  const { isMobile } = useViewport(window.innerWidth);
  const { returnToOriginalUrl, replaceToUrl } = useReplaceUrl({
    preventTrigger: action === 'novo',
    urlToReplace: `/${shortUrl}/${action}${_id ? `/${_id}` : ''}`
  });

  const handleClose = (_refresh, payment) => {
    returnToOriginalUrl();
    onClose(_refresh || !!refresh, payment);
    return null;
  };

  const reloadAll = ({ closeDrawer = true, time = 1500, withLoad = true }) => {
    setIsLoadingInstallments(true);
    setTimeout(() => {
      if (withLoad) handleLoad();
      setIsLoadingInstallments(false);
      if (closeDrawer) handleClose(true, data);
    }, time);
  };

  const handleConfirmPending = () => {
    installmentUpdate({
      updatePathOptions: `/${installmentData.id}`,
      values: { idInstallmentStatus: installmentStatuses.pendingPayment, paidDate: null, idPaymentType: null }
    }).then(() => {
      setShouldReload(true);
      reloadAll({ closeDrawer: false });
    });
  };

  const handleSave = ({ e, close = false, options = {} } = {}) => {
    const paymentItemsIsValid =
      (values?.paymentComplete && (values?.paymentItems?.length ?? 0) > 0) || !values?.paymentComplete;
    const hasInvoiceInvalid =
      values?.invoices?.length > 0 && values?.invoices?.some(invoice => !invoice.number?.trim() && !invoice.deleted);

    if (!validatePurchaseTableItems({ values, setInvalidItems: setInvalidPaymentItems })) {
      return;
    }
    if (!isValid() || !paymentItemsIsValid || hasInvoiceInvalid) {
      if (!hasInvoiceInvalid) handleSubmit(f => f)(e);
      toast.error(
        getValidationErrorMessage({
          isView,
          isRevision: false,
          totalInstallmentIsValid: true,
          paymentItemsIsValid,
          hasInvoiceInvalid
        })
      );
      return;
    }

    if (!validatePercentage(values?.paymentItems) && values?.paymentComplete) {
      toast.error('A soma das apropriações deve ser igual a 100%.');
      return;
    }
    const _formatNumber = number => (number ? formatNumber(number) : null);

    if (loading) return;

    handleSubmit(() => {
      const func = _id ? handleUpdate : handleCreate;

      func({
        values: {
          ...values,
          discount: _formatNumber(values.discount),
          taxes: _formatNumber(values.taxes),
          shipping: _formatNumber(values.shipping),
          idRefurbish: values?.idRefurbish || null,
          ...options,
          idPaymentBankAccount: values?.idPaymentBankAccount || null,
          ...(_id ? itemObjectsMap : {}),
          orderDate
        },
        refresh: false
      }).then(resp => {
        if (!resp?.error) {
          clearStates();
          eventBus.dispatch('updateGuide');
          toast.success(
            `${getPaymentLabel({ billTypeEnum, billType, capitalize: true })} ${
              _id ? 'editada' : 'criada'
            } com sucesso.`
          );
          if (isConciliation) {
            handleClose(false, resp);
          }

          reloadAll({
            time: resp?.recurrenceId && !_id ? 4000 : 1500,
            closeDrawer: shouldClose || close,
            withLoad: !!_id
          });
          setShouldReload(true);
          setIdToDuplicate(null);
          if (!close && !_id) {
            replaceToUrl(`/${shortUrl}/editar/${resp.id}`);
            setId(resp.id);
          }
        }
      });
    }, e);
  };

  const handleConfirmPayment = (e, close) => {
    if (data?.recurrenceId) {
      setShowConfirmEditModal(true);
      setShouldClose(close);
      return;
    }

    handleSave({ e, close });
  };

  const handleConfirmDelete = val => {
    const isRecurrence = data?.recurrenceId && val?.optionValue === 'all';
    const isReconciled = values?.installments?.some(i => i?.idReconciliation);

    if (isReconciled) {
      toast.error(
        `Não é possível excluir lançamento com parcelas conciliadas.
          Para excluir você deve primeiro desfazer a conciliação das parcelas na tela de extrato.`
      );
      return;
    }
    if (_id) {
      handleDelete({
        refresh: false,
        ...(isRecurrence && { deletePathOptions: `/recurrence/${data?.recurrenceId}` }),
        values: { payment: data, event: `${billType}-deleted` }
      }).then(() => {
        if (isConciliation) {
          return onClose(false, data, true);
        }
        return reloadAll({ time: isRecurrence ? 4000 : 1500, closeDrawer: true, withLoad: false });
      });
    }
  };

  const refreshStatus = () => {
    handleGetStatus({
      refetchPathOptions: _id ? `/${_id}` : ''
    }).then(resp => {
      if (resp.error) return;
      const { idPaymentStatus, paymentStatus, allInterest, allDueDateDiscount, total } = resp;
      setField('idPaymentStatus')(idPaymentStatus, true);
      setField('paymentStatus')(paymentStatus, true);
      setField('allInterest')(allInterest, true);
      setField('allDueDateDiscount')(allDueDateDiscount, true);
      setField('total')(total, true);
      setStatusColor(getColorByStatusPayment(idPaymentStatus, paymentStatuses));
      setRefresh(true);
    });
  };

  const _onClose = () => {
    if (valuesChanged) {
      setShowConfirmModal(true);
      return;
    }
    handleClose(shouldReload, data);
  };

  const handleConciliateProducts = () => {
    const hasItemsToCreate = _paymentToCreate?.products?.some(item => !item.suggestion && !item.item);

    if (hasItemsToCreate) {
      setShowConfirmCreateItemsModal(true);
      return;
    }
    conciliateProducts({
      handleBulkCreateItems,
      paymentToCreate: _paymentToCreate,
      refurbishItemType,
      setField,
      setLoading,
      setShowProductsConciliation: changeShowProductsConciliation
    });
  };
  const handleConfirmCreateItem = () => {
    conciliateProducts({
      handleBulkCreateItems,
      paymentToCreate: _paymentToCreate,
      refurbishItemType,
      setField,
      setLoading,
      setShowProductsConciliation: changeShowProductsConciliation
    });
    setShowConfirmCreateItemsModal(false);
  };

  const loadingDrawer =
    loading ||
    isLoadingInstallments ||
    (id && !values?.id) ||
    (_idToDuplicate && !values?.idToDuplicate) ||
    loadingOrder;

  const getTitle = () => {
    if (_showProductsConciliation) return 'Vincular itens';
    return (isView || data) && !_idToDuplicate ? capitalizeHelper(model?.slice(0, -1)) : `Criar ${model?.slice(0, -1)}`;
  };

  useEffect(() => {
    setStatusColor(getColorByStatusPayment(data?.paymentStatus?.id, paymentStatuses));
  }, [data]);

  useEffect(() => {
    _setShowProductsConciliation(showProductsConciliation);
  }, [showProductsConciliation]);
  useEffect(() => {
    _setPaymentToCreate(paymentToCreate);
  }, [paymentToCreate]);

  useEffect(() => {
    if (idOrder) {
      handleGetOrder().then(orderList => {
        const orderItems = serializedPaymentItems(orderList?.orderItems);

        const { idRefurbish: _idRefurbish, paymentInfo } = orderList || {};
        const {
          orderDate: _orderDate,
          idOrder: _idOrder,
          idSupplier,
          shipping,
          taxes,
          discount,
          paymentTypes
        } = extractInitialOrderData({ ...orderList, id: idOrder }, paymentOrderType?.order === orderType);
        setField('paymentItems')(orderItems);
        setCreatingFromOrder(true);

        setField('orderDate')(_orderDate);
        setField('idOrder')(_idOrder);
        setField('idSupplier')(idSupplier);
        shipping && setField('shipping')(shipping);
        taxes && setField('taxes')(taxes);
        discount && setField('discount')(discount);
        paymentTypes && setField('paymentTypes')(paymentTypes || []);
        setField('idRefurbish')(_idRefurbish);
        if (paymentOrderType?.order === orderType) setField('installments')(paymentInfo?.list || []);
      });
    }

    if (selectedItems?.length > 0) {
      handleGetItems({
        refetchOptions: {
          where: {
            id: selectedItems,
            idRefurbish
          }
        }
      }).then(({ paymentItems, supplier }) => {
        setField('paymentItems')(paymentItems);
        if (supplier) setField('idSupplier')(supplier);
      });
    }
  }, []);

  return (
    <>
      <Drawer
        open
        formId="PaymentForm"
        title={
          <>
            <Subtitle id="title-drawer">{getTitle()}</Subtitle>
            <span style={{ color: colors.neutral400, fontSize: fonts.sizeSm }} id="payment-id">
              {_id ? ` #${_id}` : ''}
            </span>
          </>
        }
        onClose={_onClose}
        padding="0px"
        width={isMobile() ? '100%' : '1080px'}
        extraContentOnRight
        footer={
          <>
            <Button type="text" className="withMarginRight" onClick={_onClose}>
              {_showProductsConciliation ? 'Fechar' : 'Voltar'}
            </Button>

            <div style={{ display: 'flex', gap: spaces.space2 }}>
              {data?.id && (
                <>
                  <Button
                    loading={loadingDrawer}
                    id="delete-payment"
                    text
                    style={{ color: colors.red500 }}
                    className="withMarginRight"
                    onClick={() => setShowConfirmDeleteModal(true)}
                  >
                    Excluir
                  </Button>
                  {data?.recurrenceId &&
                    !installmentData?.idReconciliation &&
                    data?.idPaymentStatus !== paymentStatuses.draft && (
                      <Button
                        loading={loadingDrawer}
                        id="open-register-installment"
                        htmlType="submit"
                        type="primary"
                        onClick={() => (pendingPayment ? setRegisterPayment(true) : setShowConfirmPendingModal(true))}
                      >
                        {pendingPayment ? 'Marcar como pago' : 'Voltar para aguardando'}
                      </Button>
                    )}
                  {data?.recurrenceId && installmentData?.idReconciliation && (
                    <Button
                      loading={loadingDrawer}
                      id="open-undo-reconciliation"
                      text
                      style={{ color: colors.primary500 }}
                      onClick={() => setUndoReconciliation(true)}
                    >
                      Desfazer conciliação
                    </Button>
                  )}
                </>
              )}
              {!isConciliation && !_showProductsConciliation && (
                <Button loading={loadingDrawer} id="save-continue-bill" type="light" onClick={handleConfirmPayment}>
                  Salvar
                </Button>
              )}

              {!_showProductsConciliation && (
                <Button
                  loading={loadingDrawer}
                  id="save-bill"
                  type="primary"
                  onClick={e => handleConfirmPayment(e, true)}
                >
                  Salvar e fechar
                </Button>
              )}

              {_showProductsConciliation && (
                <Button loading={loadingDrawer} id="advance-bill" type="primary" onClick={handleConciliateProducts}>
                  Avançar
                </Button>
              )}
            </div>
          </>
        }
        headerExtraContent={() => {
          return _id && values?.paymentStatus?.name ? (
            <RightHeaderContent>
              <StatusContainer>
                Status:{' '}
                <span style={{ color: statusColor }} id="status">
                  {values?.paymentStatus?.name}
                </span>
              </StatusContainer>
            </RightHeaderContent>
          ) : null;
        }}
      >
        {loadingDrawer ? (
          <CenteredLoader />
        ) : (
          <Context
            data={{
              values,
              setField,
              touched,
              errors,
              handleChange,
              handleBlur,
              statusColor,
              isCharge: false,
              setTouched,
              handleLoad,
              valuesChanged,
              handleSave,
              isConciliation,
              disableRefurbish: creatingFromOrder,
              setExtraValueErrors,
              extraValueErrors,
              itemObjectsMap,
              itemSettersMap,
              isCreate: !_id,
              loadingItems: loadingItems || loadingOrder,
              invalidPaymentItems,
              setInvalidPaymentItems
            }}
          >
            <Container $noMargin>
              {_showProductsConciliation ? (
                <ProductsConciliation
                  idRefurbish={idRefurbish}
                  products={_paymentToCreate?.products}
                  onProductsChange={products => {
                    changePaymentToCreate(prev => {
                      const _payment = { ...prev };
                      _payment.products = products;
                      return _payment;
                    });
                  }}
                />
              ) : (
                <>
                  {isView ? (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                      <PaymentBasicForm />
                      <Line />
                      <PaymentConfiguration onSubmitDropdown={refreshStatus} idRefurbish={idRefurbish} />
                      <Line />
                      <PaymentNotesForm />
                      <Line />
                      <PaymentAttachmentContent />
                      <Line />
                      <PaymentInvoiceList />
                      <CreatedByContent data={values} showLine />
                    </Space>
                  ) : (
                    <Tabs
                      activeKey={selectedTab}
                      onChange={setSelectedTab}
                      tabBarExtraContent={
                        data && installmentData?.idReconciliation && values?.recurrenceId ? (
                          <BadgeText $justify="flex-end" color={colors.green100} count="LANÇAMENTO CONCILIADO" />
                        ) : null
                      }
                    >
                      <TabPane key="0" tab="Detalhes">
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                          <PaymentBasicForm />
                          <Line />
                          {values?.paymentComplete || (values?.paymentItems.length && values?.id) ? (
                            <>
                              <PaymentFormItem />
                              <Line />
                            </>
                          ) : null}
                          <PaymentConfiguration onSubmitDropdown={refreshStatus} idRefurbish={idRefurbish} />
                          {values?.id && (values?.allInterest || values?.allDueDateDiscount) ? (
                            <>
                              <Line />
                              <BillTotals data={values} />
                            </>
                          ) : null}
                          <CreatedByContent data={values} showLine />
                        </Space>
                      </TabPane>
                      <TabPane key="1" tab="Anexos, notas fiscais e anotações">
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                          <PaymentAttachmentContent />
                          <Line />
                          <PaymentInvoiceList />
                          <Line />
                          <PaymentNotesForm />
                        </Space>
                      </TabPane>
                    </Tabs>
                  )}
                </>
              )}
            </Container>
            {showConfirmEditModal && (
              <ConfirmModal
                title={`Editar ${getPaymentLabel({
                  billTypeEnum,
                  billType
                })}`}
                alertInfo="Lançamentos pagos não serão alterados"
                options={values?.recurrenceId && confirmOptions('Editar')}
                text="Quais lançamentos devem ser editados?"
                onSubmit={options => handleSave({ e: null, options })}
                onClose={() => setShowConfirmEditModal(false)}
                modalWidth={values.recurrenceId ? 416 : 340}
              />
            )}
            {showConfirmDeleteModal && (
              <ConfirmModal
                title={`Excluir ${getPaymentLabel({
                  billTypeEnum,
                  billType
                })}`}
                alertInfo="Essa operação não poderá ser desfeita"
                options={values?.recurrenceId && confirmOptions('Deletar')}
                text={values?.isOrderPayment ? textDeletePaymentIsOrder : textToDelete}
                onSubmit={options => handleConfirmDelete(options)}
                onClose={() => setShowConfirmDeleteModal(false)}
                modalWidth={values.recurrenceId ? 416 : 340}
              />
            )}
          </Context>
        )}
        {registerPayment && (
          <RegisterInstallmentModal
            onClose={() => setRegisterPayment(false)}
            installment={installmentData}
            onSubmit={() => {
              setRegisterPayment(false);
              setShouldReload(true);
              reloadAll({ closeDrawer: false });
            }}
            valuesChanged={valuesChanged}
            billType={billType}
          />
        )}
        {undoReconciliation && (
          <UndoReconciliationModal
            installment={installmentData}
            onClose={reload => {
              setUndoReconciliation(false);
              if (reload) {
                setShouldReload(true);
                reloadAll({ closeDrawer: false });
              }
            }}
          />
        )}
        {showConfirmPendingModal && (
          <ConfirmModal
            text="Deseja voltar a parcela para status 'Aguardando'?"
            onSubmit={() => {
              handleConfirmPending();
              setShowConfirmPendingModal(false);
            }}
            onClose={() => setShowConfirmPendingModal(false)}
          />
        )}
      </Drawer>
      {showConfirmCreateItemsModal && (
        <ConfirmModal
          title="Confirmar vínculos"
          alertInfo="Essa operação não poderá ser desfeita"
          text="Itens do XML não vinculados com os itens da plataforma serão criados e salvos em “Minha Biblioteca”."
          onSubmit={handleConfirmCreateItem}
          onClose={() => setShowConfirmCreateItemsModal(false)}
          modalWidth={416}
          submitText="Salvar e criar itens"
          cancelText="Voltar"
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar."
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onSubmit={() => handleSave({ e: null, close: true })}
          onClose={(_, opts) => {
            setShowConfirmModal(false);
            if (opts?.discard) handleClose(false);
          }}
        />
      )}
    </>
  );
};

Bill.propTypes = {
  isView: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.string,
  id: PropTypes.string,
  idToDuplicate: PropTypes.string,
  billType: PropTypes.string,
  idRefurbish: PropTypes.number,
  initialData: PropTypes.instanceOf(Object),
  isConciliation: PropTypes.bool,
  idOrder: PropTypes.number,
  orderDate: PropTypes.string,
  orderType: PropTypes.string,
  setPaymentToCreate: PropTypes.func,
  paymentToCreate: PropTypes.instanceOf(Object),
  showProductsConciliation: PropTypes.bool,
  setShowProductsConciliation: PropTypes.func,
  paymentComplete: PropTypes.bool,
  selectedItems: PropTypes.instanceOf(Array)
};

export default Bill;
