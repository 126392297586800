import styled from 'styled-components';
import { Checkbox } from 'antd';

import { spaces, colors, fonts, radius } from '../../styles/style';
import Button from '../Button/Button';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const WatcherCheckbox = styled(Checkbox)`
  margin: ${spaces.space1};
`;

export const EyeButton = styled(Button)`
  height: ${spaces.space4};
  width: ${spaces.space4};
`;

export const EyeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  div {
    position: absolute;
    top: ${spaces.space2};
    right: 0;
    background-color: ${colors.primary600};
    color: ${colors.white};
    border-radius: ${radius.radius1};
    height: 12px;
    width: 12px;
    font-size: ${fonts.sizeXs};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const UserList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  height: auto;
  max-height: 300px;
  overflow: auto;
  background-color: ${colors.white};
  padding: ${spaces.space2};
  border-radius: ${radius.radius1};
  box-shadow: 0 ${spaces.space1} ${spaces.space2} 0 rgb(0 0 0 / 8%);
  z-index: 10;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      margin-right: ${spaces.space1};
    }

    label {
      margin-top: ${spaces.space0};
    }
  }

  li + li {
    margin-top: ${spaces.space1};
  }
`;
