import { copyLinkButton, attachmentButton, deleteActionV2 } from './_dropdown';

const editItem = {
  label: 'Editar',
  id: 'edit',
  modal: 'editItem',
  params: { tab: '0' }
};

const comment = {
  label: 'Comentar',
  modal: 'editItem',
  id: 'comment-items',
  params: { tab: '3' }
};

const addToMyProducts = {
  label: 'Adicionar à meus produtos',
  button: 'myProducts',
  id: 'add-products',
  params: {
    postPathOptions: '/copy',
    displayToast: 'Produto adicionado em Meus Produtos com sucesso!'
  }
};

const duplicateItem = {
  label: 'Duplicar este item',
  verb: 'post',
  id: 'duplicate-item',
  params: {
    postPathOptions: '/duplicate'
  }
};

const addToLibrary = {
  label: 'Adicionar na Biblioteca',
  verb: 'post',
  id: 'copy',
  params: {
    postPathOptions: '/copy',
    displayToast: 'Produto adicionado na biblioteca com sucesso!'
  }
};

const removeItemFromGroup = {
  label: 'Retirar item do grupo',
  verb: 'put',
  id: 'remove-item-group',
  params: {
    updatePathOptions: '/remove-group',
    displayToast: 'Produto adicionado em Meus Produtos com sucesso!',
    refresh: false
  }
};

const linkItem = {
  id: 'link-item',
  label: 'Vincular',
  modal: 'linkItem',
  params: { subtitle: 'Busque o item da biblioteca para vincular com o item selecionado' }
};

const unlinkItem = {
  id: 'unlink-item',
  label: 'Desvincular',
  modal: 'unlinkItem'
};

const updateLinkedItem = {
  id: 'update-linked-item',
  label: 'Atualizar',
  modal: 'updateItem'
};

const addObservations = {
  label: 'Adicionar observações',
  modal: 'editItem',
  id: 'add-notes',
  params: { tab: '1' }
};

const specificationDropDown = {
  specificationItem: [
    {
      label: 'Agrupar item',
      verb: 'post',
      params: {
        postPathOptions: '/bulk'
      }
    },
    {
      label: 'Adicionar observações',
      modal: 'editItem',
      id: 'add-notes',
      params: { tab: '1' }
    },
    editItem,
    comment,
    attachmentButton,
    copyLinkButton,
    addToMyProducts,
    deleteActionV2
  ],
  specificationParent: [
    attachmentButton,
    editItem,
    comment,
    {
      ...deleteActionV2,
      label: 'Desfazer agrupamento',
      params: {
        text: `Deseja realmente apagar esse item? Todos os sub-itens serão colocados como itens avulsos no template.`
      }
    }
  ],
  specificationChildren: [
    removeItemFromGroup,
    editItem,
    attachmentButton,
    copyLinkButton,
    addToMyProducts,
    comment,
    deleteActionV2
  ],
  templateItem: [
    editItem,
    {
      label: 'Agrupar item',
      verb: 'post',
      params: {
        postPathOptions: '/bulk'
      }
    },
    deleteActionV2
  ],
  templateItemParent: [
    {
      ...deleteActionV2,
      label: 'Desfazer agrupamento',
      params: {
        text: `Deseja realmente apagar esse item? Todos os sub-itens serão colocados como itens avulsos no template.`
      }
    }
  ],

  templateItemChildren: [editItem, removeItemFromGroup, deleteActionV2],

  // TODO: Remove after deprecate v1
  specificationItemV2: ({ isComposition, isLinked, isExternalBase }) => {
    const items = [];

    if (!isComposition) items.push(duplicateItem);

    if (!isComposition && !isLinked && !isExternalBase) items.push(addToLibrary);

    items.push(addObservations, editItem, comment, attachmentButton, copyLinkButton);

    if (isExternalBase) {
      items.push(unlinkItem);
      return items;
    }

    if (isLinked) items.push(unlinkItem, updateLinkedItem);
    else items.push(linkItem);

    items.push(deleteActionV2);

    return items;
  },
  parentV2: [
    {
      ...deleteActionV2,
      params: {
        text: `Ao apagar um nível os itens que estão dentro dele também serão apagados.
                Deseja realmente apagar?`
      }
    }
  ],
  templateItemV2: ({ isComposition, isLinked, isExternalBase }) => {
    const items = [];

    if (!isComposition) items.push(duplicateItem);

    if (!isComposition || !isLinked || !isExternalBase) items.push(addToLibrary);

    items.push({ ...editItem, params: { tab: '0', isTemplate: true } }, deleteActionV2);

    if (isExternalBase) {
      items.push(unlinkItem);
      return items;
    }

    if (isLinked) items.push(unlinkItem, updateLinkedItem);
    else items.push(linkItem);

    return items;
  }
};

const viewAttachments = {
  ...editItem,
  params: { ...editItem.params, tab: '1' }
};

export { specificationDropDown, editItem, viewAttachments };
