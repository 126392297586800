import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useContextHook } from '../../contexts/GeneralContext';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import PurchaseTable from '../Table/PurchaseTable';
import PaymentExtraValuesForm from './PaymentExtraValuesForm';
import { Div, colors, spaces } from '../../styles/style';
import { Paragraph, Subtitle } from '../Text/Text';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { Content } from '../../_Pages/Payments/Payment.style';

const PaymentFormItemContent = ({
  list,
  idRefurbish,
  onchangeInput,
  itemObjectsMap,
  itemSettersMap,
  isView,
  fullTable,
  isCreatePayment,
  priceWithBdi,
  shouldForceRender = true,
  importedFromXML,
  invalidPaymentItems,
  setInvalidPaymentItems
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [forceRender, setForceRender] = useState(shouldForceRender);

  const handleSetList = setFunc => {
    const result = setFunc(list);
    onchangeInput(result);
  };

  const handleForceRender = () => {
    if (!list.length) {
      setForceRender(false);
      return;
    }

    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setForceRender(false);
    }, 100);
  };

  useEffect(() => {
    if (importedFromXML) handleForceRender();
  }, [fullTable]);

  useEffect(() => {
    if (forceRender && list) handleForceRender();
  }, [list]);

  return (
    <Div direction="column" $fullWidth align="start">
      {isLoading ? (
        <Div height={isView ? '100%' : '300px'} />
      ) : (
        <Div $fullWidth display="block" className="customScale">
          <PurchaseTable
            list={list || []}
            setList={handleSetList}
            idRefurbish={idRefurbish}
            itemObjectsMap={itemObjectsMap}
            itemSettersMap={itemSettersMap}
            paymentView
            isCreatePayment={isCreatePayment}
            childrenColumnName={fullTable ? 'paymentItemLevels' : ''}
            parentColumnName="paymentItems"
            readOnly={isView}
            height={isView ? '100%' : '300px'}
            limitedTable={!fullTable}
            priceWithBdi={priceWithBdi}
            invalidPaymentItems={invalidPaymentItems}
            setInvalidPaymentItems={setInvalidPaymentItems}
            updateFilters={false}
          />
        </Div>
      )}
      <Div $fullWidth justify="end">
        <PaymentExtraValuesForm creditDisabled={isView} showOtherTaxes />
      </Div>
    </Div>
  );
};

PaymentFormItemContent.propTypes = {
  list: PropTypes.instanceOf(Array),
  isView: PropTypes.bool,
  idRefurbish: PropTypes.number,
  onchangeInput: PropTypes.func,
  itemObjectsMap: PropTypes.instanceOf(Object),
  itemSettersMap: PropTypes.instanceOf(Object),
  fullTable: PropTypes.bool,
  isCreatePayment: PropTypes.bool,
  priceWithBdi: PropTypes.bool,
  importedFromXML: PropTypes.bool,
  shouldForceRender: PropTypes.bool,
  invalidPaymentItems: PropTypes.bool,
  setInvalidPaymentItems: PropTypes.func
};

const PaymentFormItem = () => {
  const {
    values,
    setField,
    isView,
    itemObjectsMap,
    itemSettersMap,
    isCreate,
    loadingItems,
    invalidPaymentItems,
    setInvalidPaymentItems
  } = useContextHook();
  const { vobiPayPaymentType, paymentStatuses } = useSelector(state => state.setup.enums);
  const {
    paymentItems,
    idRefurbish,
    isVobiPay,
    paymentTypes,
    total,
    paymentStatus,
    isCharge,
    id,
    ownBusiness,
    priceWithBdi,
    idOrder,
    importedFromXML
  } = values || {};
  const creditDisabled = isVobiPay && paymentTypes?.some(type => type?.id === vobiPayPaymentType?.creditCard);

  const onchangeInput = newList => {
    setField('paymentItems')(newList);
  };

  useEffect(() => {
    if (!isView && !isCharge && !id) setField('value')(total, true);
  }, [total]);

  const RenderTable = (
    <PaymentFormItemContent
      isView={isView || creditDisabled || paymentStatus?.id === paymentStatuses.paid}
      invalidPaymentItems={invalidPaymentItems}
      setInvalidPaymentItems={setInvalidPaymentItems}
      list={!loadingItems ? paymentItems || [] : null}
      onchangeInput={onchangeInput}
      idRefurbish={idRefurbish}
      itemSettersMap={itemSettersMap}
      itemObjectsMap={itemObjectsMap}
      fullTable={!ownBusiness || values?.billType === 'expense'}
      isCreatePayment={isCreate}
      priceWithBdi={priceWithBdi}
      shouldForceRender
      importedFromXML={importedFromXML}
    />
  );

  const handleSwitchPriceBdi = val => {
    setField('priceWithBdi')(val);

    const newPaymentItems = paymentItems?.map(data => {
      if (val) {
        return { ...data, price: data.priceBdi || data?.price };
      }
      return { ...data, price: data.priceUnit || data?.price };
    });
    setField('paymentItems')(newPaymentItems);
  };

  const SwitchPriceBdi =
    isCharge && !id && !idOrder ? (
      <Div gap={spaces.space1}>
        <Switch
          id="switchPriceBdi"
          checked={priceWithBdi}
          onChange={(checked, e) => {
            e.stopPropagation();
            handleSwitchPriceBdi(checked);
          }}
        />
        <Paragraph type="small">{priceWithBdi ? 'Preço (com BDI)' : 'Custo (sem BDI)'}</Paragraph>
        <TooltipIcon
          text="Alterne entre as opções de preço e custo para lançamentos com ou sem o BDI no valor de cada item."
          icon={faCircleInfo}
          iconColor={colors.primary500}
        />
      </Div>
    ) : (
      <div />
    );

  return (
    <>
      {isView ? (
        <Content>
          <Subtitle>Produtos/Serviços</Subtitle>
          {RenderTable}
        </Content>
      ) : (
        <SimpleAccordion title="Produtos/Serviços" initOpen withPaddingContainer extraHeaderComponent={SwitchPriceBdi}>
          {RenderTable}
        </SimpleAccordion>
      )}
    </>
  );
};

export default PaymentFormItem;
