import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faTrashAlt, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import Drawer from './Drawer';
import useCRUD from '../../_Hooks/useCRUD';
import Tabs, { TabPane } from '../Tabs/Tabs';

import { colors } from '../../styles/style';
import Button from '../Button/Button';
import Input from '../Input/Input';
import ConfirmModal from '../Modal/ConfirmModal';
import { capitalize } from '../../lib/helpers/helper';
import { Card, Content, EmptyText, IconCheck, ListItems, StyledStrashIcon } from './ListDrawer.styled';
import EditableInput from '../Input/EditableInput';
import useViewport from '../../_Hooks/useViewport';
import { Subtitle, Title } from '../Text/Text';

const RenderContent = ({
  list,
  setItemToDelete,
  addFinancial,
  onCreate,
  setAddFinancial,
  billType,
  onChange,
  tabRender,
  subtitle,
  typeText
}) => {
  const [name, setName] = useState('');
  const title = subtitle || (billType === 'income' ? 'Pagamentos e receitas' : 'Despesas');
  return (
    <Content>
      <Title>{title}</Title>
      <Subtitle>{tabRender.description}</Subtitle>
      <ListItems>
        {list && list.length ? (
          list.map(item => {
            return (
              <Card id={`category-${item?.id}`}>
                <EditableInput
                  key={`name-${item.id}`}
                  value={item.name}
                  onChange={val => onChange(item, { name: val })}
                />
                {!item.isVobiPay && (
                  <Tooltip title={`Excluir ${typeText}`} placement="topLeft">
                    <StyledStrashIcon
                      id={`remove-category-${item?.id}`}
                      icon={faTrashAlt}
                      onClick={() => setItemToDelete(item)}
                    />
                  </Tooltip>
                )}
              </Card>
            );
          })
        ) : (
          <EmptyText>{tabRender.emptyText}</EmptyText>
        )}
      </ListItems>
      {addFinancial && (
        <Card>
          <Input
            id="add-financial-input"
            onChange={e => setName(e.target.value)}
            onPressEnter={e => onCreate({ values: { name: e?.target?.value, isActive: true, billType } })}
          />
          <IconCheck icon={faCheck} onClick={() => onCreate({ values: { name, isActive: true, billType } })} />
          <StyledStrashIcon icon={faXmark} onClick={() => setAddFinancial(false)} />
        </Card>
      )}
      {!addFinancial && (
        <Button
          id="add-financial"
          type="primary"
          text
          style={{ marginRight: 'auto' }}
          onClick={() => {
            setAddFinancial(true);
            setTimeout(() => document.getElementById('add-financial-input').focus(), 100);
          }}
        >
          {`+ Adicionar ${typeText}`}
        </Button>
      )}
    </Content>
  );
};

RenderContent.propTypes = {
  list: PropTypes.instanceOf(Array),
  setItemToDelete: PropTypes.func,
  addFinancial: PropTypes.bool,
  onCreate: PropTypes.func,
  setAddFinancial: PropTypes.func,
  billType: PropTypes.string,
  onChange: PropTypes.func,
  tabRender: PropTypes.instanceOf(Object),
  subtitle: PropTypes.string,
  typeText: PropTypes.string
};

const ListDrawer = ({
  onClose,
  tab,
  model,
  title,
  subtitle,
  typeText = 'item',
  where,
  include,
  renderTabs,
  textToDelete,
  isV2
}) => {
  const [selectedTab, setSelectedTab] = useState(tab);
  const { isMobile } = useViewport(window.innerWidth);
  const [list, setList] = useState([]);
  const [addFinancial, setAddFinancial] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(false);
  const _textToDelete = textToDelete || 'ao excluir esse item, ele será removido permanentemente da sua lista.';
  const { handleGet, handleCreate, handleDelete, handleUpdate } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const handleLoad = () =>
    handleGet({
      refetchOptions: {
        include,
        where: { ...where, billType: { or: [selectedTab, null] } },
        onlyMine: true,
        order: [['name', 'asc']]
      }
    }).then(resp => setList(resp));

  useEffect(() => {
    if (selectedTab) {
      handleLoad();
    }
  }, [selectedTab]);

  const onCreate = data => {
    handleCreate(data).then(resp => {
      if (!resp?.error) {
        setList(prev => prev?.concat(resp));
        setAddFinancial(false);
      }
    });
  };

  const onUpdate = (item, newValue) => {
    return handleUpdate({ values: newValue, updatePathOptions: `/${item?.id}` });
  };

  const onDelete = data => {
    handleDelete({ id: data.id, displayToast: true }).then(resp => {
      if (!resp?.error) {
        setList(prev => prev.filter(item => item.id !== data.id));
      }
    });
  };

  return (
    <Drawer
      title={title}
      open
      padding="0px"
      formId="customerForm"
      onClose={() => onClose()}
      footer={
        <Button style={{ marginLeft: 'auto' }} id="close-financial-drawer" type="primary" onClick={() => onClose()}>
          Fechar
        </Button>
      }
      zIndex={1100}
    >
      <Tabs
        activeKey={selectedTab}
        onChange={key => setSelectedTab(key)}
        tabBarStyle={{
          paddingTop: 0,
          borderBottom: `1px solid ${colors.neutral100}`,
          position: 'sticky',
          top: 0
        }}
      >
        {renderTabs &&
          renderTabs.map(tabRender => {
            return (
              <TabPane id="new-notification-tab" key={tabRender?.key} tab={tabRender?.name}>
                <RenderContent
                  subtitle={subtitle}
                  description={tabRender.description}
                  list={list}
                  setItemToDelete={setItemToDelete}
                  addFinancial={addFinancial}
                  onCreate={onCreate}
                  setAddFinancial={setAddFinancial}
                  billType={selectedTab}
                  onChange={onUpdate}
                  tabRender={tabRender}
                  typeText={typeText}
                  isV2={isV2}
                />
              </TabPane>
            );
          })}
      </Tabs>
      {itemToDelete && (
        <ConfirmModal
          modalWidth={isMobile() ? 500 : 344}
          onClose={() => setItemToDelete(false)}
          alertInfo="Essa operação não poderá ser desfeita."
          description={
            itemToDelete?.payments?.length
              ? `Esse item está sendo utilizado em ${itemToDelete?.payments?.length} lançamento${
                  itemToDelete?.payments?.length > 1 ? 's' : ''
                }${textToDelete ? `, ${textToDelete}` : '.'}`
              : capitalize(_textToDelete)
          }
          onSubmit={() => onDelete(itemToDelete)}
        />
      )}
    </Drawer>
  );
};

ListDrawer.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  tab: PropTypes.string,
  model: PropTypes.string.isRequired,
  where: PropTypes.instanceOf(Object),
  renderTabs: PropTypes.instanceOf(Array),
  include: PropTypes.instanceOf(Array),
  subtitle: PropTypes.string,
  typeText: PropTypes.string,
  textToDelete: PropTypes.string,
  isV2: PropTypes.bool
};

export default ListDrawer;
