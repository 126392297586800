import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';

import dayjs from 'dayjs';
import styled from 'styled-components';
import Modal from './Modal';

import useCRUD from '../../_Hooks/useCRUD';
import { Paragraph, Subtitle } from '../Text/Text';
import { colors, fonts } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';
import ConfirmModal from './ConfirmModal';
import useViewport from '../../_Hooks/useViewport';
import RenderCard from '../Card/RenderCard';

const OverflowSpace = styled(Space)`
  height: 300px;
  overflow: auto;
`;

const UndoReconciliationModal = ({ installment, onClose = f => f }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const [showConfirm, setShowConfirm] = useState();
  const { data, loading, handleUpdate } = useCRUD({
    model: 'conciliation',
    pathOptions: `/${installment?.idReconciliation || 0}`,
    options: {
      include: ['installments']
    }
  });

  const handleSubmit = () => {
    return handleUpdate({
      updatePathOptions: `/${installment?.idReconciliation}/undo`,
      refresh: false,
      noLoading: true,
      displayToast: 'Conciliação desfeita com sucesso!'
    }).then(resp => {
      if (resp?.error) return;
      setShowConfirm(false);
      onClose(
        true,
        resp?.map(i => i.id)
      );
    });
  };

  return (
    <>
      <Modal
        width="fit-content"
        open
        loading={loading}
        title="Detalhes da conciliação"
        cancelText="Voltar"
        submitText="Desfazer conciliação"
        onSubmit={() => setShowConfirm(true)}
        onClose={() => onClose(false)}
        auxText="Ao desfazer a conciliação, o status do lançamento da Vobi não será alterado."
        isMobile={_isMobile}
      >
        {loading ? (
          <CenteredLoader />
        ) : (
          <Space size={32} align="start" direction={_isMobile ? 'vertical' : 'horizontal'}>
            <Space size={8} direction="vertical">
              <Subtitle color={colors.neutral600} type="secondary" weight={fonts.weight600}>
                Transação importada
              </Subtitle>
              <RenderCard data={data} cardId="conciliation-card" />
              {data?.conciliationDate ? (
                <Paragraph type="small">
                  {`Conciliado em: ${dayjs(data?.conciliationDate).format('DD/MM/YYYY')}`}
                  {data?.createdBy && ` por ${data?.createdBy?.name}`}
                </Paragraph>
              ) : null}
            </Space>
            <Space size={8} direction="vertical">
              <Subtitle color={colors.neutral600} type="secondary" weight={fonts.weight600}>
                Conciliado na Vobi
              </Subtitle>
              <OverflowSpace size={8} direction="vertical">
                {data?.installments?.map((_installment, index) => (
                  <React.Fragment key={_installment?.id}>
                    <RenderCard data={_installment} cardId={`installment-card-${index + 1}`} />
                  </React.Fragment>
                ))}
              </OverflowSpace>
            </Space>
          </Space>
        )}
      </Modal>
      {showConfirm && (
        <ConfirmModal
          loading={loading}
          onSubmit={() => handleSubmit()}
          title="Desfazer conciliação"
          description={`Ao desfazer a conciliação, o vínculo entre o lançamento importado
          e o da Vobi será removido. O lançamento da Vobi continuará marcado como pago e o
          lançamento importado voltará a aparecer nas conciliações pendentes.`}
          alertInfo="Essa operação não poderá ser desfeita"
          onClose={() => setShowConfirm(false)}
          descriptionId="description-undo-reconciliation"
        />
      )}
    </>
  );
};

UndoReconciliationModal.propTypes = {
  installment: PropTypes.instanceOf(Object),
  onClose: PropTypes.func
};

export default UndoReconciliationModal;
