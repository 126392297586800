import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmark, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useDrag, useDrop } from 'react-dnd';
import styled, { css } from 'styled-components';
import Input from '../Input/Input';
import EditableInput from '../Input/EditableInput';
import Button from '../Button/Button';
import { colors, Div, spaces } from '../../styles/style';

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${colors.neutral100};
  padding: ${spaces.space1};

  ${props =>
    props.$isOver &&
    css`
      opacity: 0.5 !important;
    `}
`;

const Checklist = ({ list = [], onChange }) => {
  const [isCreating, setIsCreating] = useState();
  const [itemName, setItemName] = useState('');

  const handleChange = (item, key, value) => {
    const itemIndex = list.findIndex(_item => _item === item);
    const newList = list;
    newList[itemIndex][key] = value;
    onChange(newList);
  };

  const createItem = () => {
    const newItem = { title: itemName || '-', checked: false };
    list.push(newItem);
    onChange(list);
    setItemName('');
  };

  const removeItem = item => {
    const itemIndex = list.findIndex(_item => _item === item);
    list.splice(itemIndex, 1);
    onChange(list);
  };

  const moveRow = useCallback(
    (dragRow, dropRow, dragIndex, dropIndex) => {
      if (dragRow?.order === dropRow?.order) return;

      const newList = [...list];

      let beforeBoundary = 0;
      let afterBoundary = 0;

      if (dragIndex < dropIndex) {
        beforeBoundary = dropRow?.order;
        afterBoundary = newList?.[dropIndex + 1]?.order || Number(dropRow?.order) + 1;
      } else {
        beforeBoundary = newList?.[dropIndex - 1]?.order || 0;
        afterBoundary = dropRow?.order;
      }

      const order = (Number(beforeBoundary || 0) + Number(afterBoundary)) / 2;

      const itemIndex = newList.findIndex(_item => _item === dragRow);
      newList[itemIndex].order = order;

      onChange(newList);
    },
    [list]
  );

  const RenderList = ({ item, index }) => {
    const ref = useRef();

    const [, dragRef] = useDrag({
      item: { type: 'row', dragItem: item, dragIndex: index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });

    const [{ isOver }, dropRef] = useDrop({
      accept: 'row',
      collect: monitor => {
        return { isOver: monitor.isOver() };
      },
      drop(_item) {
        const { dragItem, dragIndex } = _item;
        moveRow(dragItem, item, dragIndex, index);
      }
    });

    dragRef(dropRef(ref));

    return (
      <ListItem key={index} ref={ref} $isOver={isOver} id={`item-${index}`}>
        <Div flex={1}>
          <Input
            style={{ width: '20px', minWidth: '20px', height: '20px', marginRight: spaces.space1 }}
            type="checkbox"
            checked={item.checked}
            onChange={e => handleChange(item, 'checked', e.target.checked)}
          />
          <EditableInput
            element={document.getElementById(`item-${index}`)}
            value={item.title}
            onChange={value => handleChange(item, 'title', value)}
            initEdit={false}
          />
        </Div>
        <FontAwesomeIcon icon={faTrash} color={colors.red500} onClick={() => removeItem(item)} />
      </ListItem>
    );
  };

  RenderList.propTypes = {
    item: PropTypes.instanceOf(Object),
    index: PropTypes.number
  };

  return (
    <List
      dataSource={list}
      locale={{ emptyText: 'Nenhum item' }}
      renderItem={(item, index) => (item ? <RenderList item={item} index={index} /> : null)}
      footer={
        isCreating ? (
          <Div gap={spaces.space2} $fullWidth>
            <Div flex={20}>
              <Input
                autoFocus
                placeholder=""
                value={itemName}
                type="text"
                onChange={e => setItemName(e.target.value)}
                onPressEnter={createItem}
              />
            </Div>
            <Div gap={spaces.space1} flex={2}>
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                icon={faCheck}
                color={colors.primary600}
                onClick={createItem}
              />
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }}
                icon={faXmark}
                color={colors.red500}
                onClick={() => setIsCreating(false)}
              />
            </Div>
          </Div>
        ) : (
          <Button id="add-item" text style={{ color: colors.primary600 }} onClick={() => setIsCreating(true)}>
            + Adicionar item
          </Button>
        )
      }
    />
  );
};

Checklist.propTypes = {
  list: PropTypes.instanceOf(Array),
  onChange: PropTypes.func
};

export default Checklist;
