import React from 'react';
import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import PropTypes from 'prop-types';

const _baseUrl = process.env.REACT_APP_API_URL;

export const print = ({ elementId, name = 'cronograma', orientation = 'landscape', format, isCustom }) => {
  window.scrollTo(0, 0);
  const Element = document.getElementById(elementId);

  const maxWidthJSPDF = 10800;
  const maxCanvasWidth = 32000;
  const elementWidth = Element.scrollWidth;
  const elementHeight = Element.scrollHeight;

  const scale = Math.min(1.5, maxCanvasWidth / elementWidth);

  return html2canvas(Element, {
    scale: isCustom ? scale : 1.5,
    letterRendering: 1,
    allowTaint: true,
    useCORS: false,
    proxy: `${_baseUrl}/html2canvas-proxy`,
    width: elementWidth,
    height: elementHeight,
    scrollX: 0,
    scrollY: 0,
    windowWidth: document?.documentElement?.offsetWidth,
    windowHeight: document?.documentElement?.offsetHeight,
    imageTimeout: 0
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    if (isCustom) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const aspectRatio = canvasWidth / canvasHeight;

      const imgWidth = Math.min(canvasWidth, maxWidthJSPDF);
      const imgHeight = imgWidth / aspectRatio;
      const _orientation = canvasWidth > canvasHeight ? 'landscape' : 'portrait';

      const doc = new JSPDF({ orientation: _orientation, unit: 'px', format: [imgHeight, imgWidth] });

      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');
      doc.save(`${name}.pdf`);
      return;
    }

    const doc = new JSPDF({ orientation, format });

    const imgWidth = doc?.internal?.pageSize?.getWidth();
    const pageHeight = doc?.internal?.pageSize?.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
      heightLeft -= pageHeight;
    }
    doc.save(`${name}.pdf`);
  });
};

export const ComponentToPdf = React.forwardRef(({ children, style = {} }, ref) => {
  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  );
});
ComponentToPdf.propTypes = {
  children: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object)
};
