import React from 'react';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';

import TooltipIcon from '../../../components/Tooltip/TooltipIcon';
import { colors, Div, fonts, spaces } from '../../../styles/style';
import EditableInput from '../../../components/Input/EditableInput';
import { Paragraph } from '../../../components/Text/Text';
import ButtonAttachmentDropDown from '../../../components/Dropdown/ButtonAttachmentDropDown';
import DatePicker from '../../../components/Datepicker/Datepicker';
import Button from '../../../components/Button/Button';

const columns = ({ isView, handleChange, handleRemove, handleOpenXML }) => {
  return [
    {
      title: 'Série/Número',
      key: 'number',
      dataIndex: 'number',
      width: 120,
      render(val, row) {
        if (isView || row.accessKey) {
          let title = val?.length > 11 ? val : '';
          if (row.accessKey) title = 'Ver nota fiscal';
          return (
            <Div $maxWidth={spaces.space13} padding={`${spaces.space0} 0 0 0`}>
              <Tooltip title={title}>
                {row.accessKey ? (
                  <Button
                    type="primary"
                    text
                    underline
                    onClick={() => handleOpenXML(row)}
                    linkFontWeight={fonts.weight500}
                    padding="0"
                    style={{ height: spaces.space2, maxWidth: spaces.space13 }}
                  >
                    {val}
                  </Button>
                ) : (
                  <Paragraph type="small" ellipsis>
                    {val}
                  </Paragraph>
                )}
              </Tooltip>
            </Div>
          );
        }
        return (
          <EditableInput
            key={`number${row.id}`}
            id={`number-${row.id}`}
            noWrap
            placeholder="Nº da nota"
            value={val}
            row={row}
            onChange={value => handleChange({ field: 'number', id: row.id, value })}
            style={{ height: spaces.space3, width: spaces.space13 }}
            forceShow={!isView}
            disabled={isView}
            size="small"
          />
        );
      }
    },
    {
      title: 'Data de emissão',
      key: 'date',
      dataIndex: 'date',
      render(val, row) {
        if (isView || row.accessKey)
          return (
            <Div $maxWidth={spaces.space13} padding={`${spaces.space0} 0 0 0`}>
              <Paragraph type="small">{val ? dayjs(val).format('DD/MM/YYYY') : ''}</Paragraph>
            </Div>
          );
        return (
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Data"
            value={val ? dayjs(val) : null}
            onChange={date => handleChange({ field: 'date', id: row.id, value: date?.format('YYYY-MM-DD') })}
            size="small"
          />
        );
      }
    },

    {
      title: isView ? 'Anexos' : 'Ações',
      className: 'action-col',
      width: 90,
      align: 'end',
      render: (val, row) => {
        return (
          <Div justify="flex-end" align="center" height={spaces.space3}>
            <Div margin={`0 ${spaces.space1} 0 0`}>
              <ButtonAttachmentDropDown
                type="xml"
                file={row?.fileXml}
                onFileChange={file =>
                  handleChange({ field: 'fileXml', id: row.id, value: { ...file, fileType: 'invoiceXml' } })
                }
                isView={isView}
                cannotUpdate={!!row.accessKey}
              />
            </Div>
            <Div margin={`0 ${spaces.space1} 0 0`}>
              <ButtonAttachmentDropDown
                type="paperclip"
                file={row?.file}
                onFileChange={file =>
                  handleChange({ field: 'file', id: row.id, value: { ...file, fileType: 'invoice' } })
                }
                isView={isView}
              />
            </Div>

            {!isView && (
              <TooltipIcon
                text={
                  row.isImported ? 'Não é possível excluir a nota fiscal utilizada na criação do lançamento' : 'Excluir'
                }
                iconColor={row.isImported ? colors.neutral300 : colors.red500}
                icon={faTrashCan}
                style={{ height: spaces.space2, width: spaces.space2 }}
                id="remove-invoice"
                onClick={!row.isImported ? () => handleRemove(row.id) : null}
              />
            )}
          </Div>
        );
      }
    }
  ];
};

export { columns };
