import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { indexList } from '../../lib/helpers/helper';
import { columns as scheduleColumns } from '../../lib/mapping/TableOrList/scheduleColumns';
import { parseArrayAsObject } from '../../lib/helpers/parseArrayAsObject';
import eventBus from '../../lib/helpers/eventBus';

const useScheduleData = ({
  referModel = 'refurbish',
  referKey = 'idRefurbish',
  idReference,
  filter,
  generalTask,
  dateRangeDisplay,
  setEditModalId,
  defaultOrder = [
    ['order', 'ASC NULLS FIRST'],
    ['tasks', 'order']
  ],
  isApply,
  idCompany,
  workDays,
  dayoffs
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { taskStatus, taskPriority } = useSelector(state => state.setup.systemData);
  const { taskStatus: taskStatusEnum, refurbishStatus: refurbishStatusEnum } = useSelector(state => state.setup.enums);
  const [list, setList] = useState([]);
  const [isDefaultOrder, setIsDefaultOrder] = useState(true);
  const [listOrder, setListOrder] = useState(defaultOrder);
  const { isMobile } = useViewport(window.innerWidth);
  const [expandItem, setExpandedItem] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState(null);
  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [serializedPrioritiesArray, setSerializedPrioritiesArray] = useState({});
  const [currentData, setCurrentData] = useState(false);
  const _filter = useRef({});
  const dataRangeSelected = useRef(false);
  const lastAdd = useRef(null);
  const _expandedRowKeys = useRef(null);

  const { handleCreate: handleCreateTask, handleUpdate: handleUpdateTask, handleChunks, progress } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  const {
    handleGet: handleGetReferStep,
    handleCreate: handleCreateReferStep,
    handleUpdate: handleUpdateReferStep,
    loading
  } = useCRUD({
    model: `${referModel}-step`,
    immediatelyLoadData: false
  });

  const loadData = ({ generateLoading = false }) => {
    return handleGetReferStep({
      refetchOptions: {
        where: { or: [{ [referKey]: idReference }, { id: 1 }] },
        include: [
          {
            model: 'task',
            include: ['user', 'performedHours'],
            required: false,
            where: { [referKey]: idReference, ..._filter.current }
          }
        ],
        order: listOrder
      },
      generateLoading
    }).then(resp => {
      if (!resp?.error) setList(indexList({ currentList: resp, childrenColumn: 'tasks' }));
      setExpandedItem(null);
    });
  };

  const handleAdd = (idReferStep, isTask, values) => {
    return isTask || generalTask
      ? handleCreateTask({
          values: {
            ...(dataRangeSelected.current && { startDate: new Date(), endDate: new Date() }),
            name: '',
            [`${referKey}Step`]: idReferStep || null,
            [referKey]: idReference,
            ...values,
            source: generalTask ? 'General' : 'Project'
          },
          noLoading: true,
          refresh: false
        })
          .then(task => {
            if (isMobile()) {
              setEditModalId(task.id);
            }
            if (idReferStep) {
              setExpandedItem(idReferStep);
            }
            lastAdd.current = task.id;
            return task;
          })
          .then(resp => {
            if (!resp?.error) {
              eventBus.dispatch('updateGuide');
              loadData({});
            }
          })
      : handleCreateReferStep({
          values: { [referKey]: idReference, ...values },
          noLoading: true,
          refresh: false
        }).then(resp => {
          lastAdd.current = resp.id;
          if (!resp?.error) loadData({});
        });
  };

  const handleChange = (values, isChildren = true, isFinished, isCancelled) => {
    const update = isChildren ? handleUpdateTask : handleUpdateReferStep;
    return update({
      id: values.id,
      values: { ...values, source: generalTask ? 'General' : 'Project' },
      refresh: false
    }).then(resp => {
      if (!resp?.error) {
        if (isFinished) toast && toast.success('Tarefa concluída com sucesso!');
        if (isCancelled) toast && toast.success('Tarefa cancelada com sucesso!');
        loadData({});
      }
    });
  };

  const handleSort = ({ key, order }) => {
    const _key = Array.isArray(key) ? key : [key];
    const _order =
      key && _key?.length
        ? [
            ['order', 'asc'],
            ['tasks', ..._key, order]
          ]
        : defaultOrder;

    setIsDefaultOrder(!_key);
    setListOrder(_order);
  };

  const handleBulkChange = (values, selectedRows) => {
    return handleChunks({ values: { ids: selectedRows, ...values, source: generalTask ? 'General' : 'Project' } }).then(
      resp => {
        const { failedCount, successCount } = resp || {};
        if (failedCount)
          toast.error(
            `${failedCount} tarefa${failedCount > 1 ? 's não puderam ser alteradas' : ' não pode ser alterada'}`
          );
        if (successCount) {
          toast.success(
            `${successCount} tarefa${
              successCount > 1 ? 's foram alteradas com sucesso.' : ' foi alterada com sucesso.'
            }`
          );

          loadData({});
        }
      }
    );
  };

  const handleBulkDelete = ids => {
    return handleChunks({
      values: { ids },
      verb: 'delete'
    }).then(resp => {
      const { failedCount, successCount } = resp || {};
      if (failedCount)
        toast.error(
          `${failedCount} tarefa${failedCount > 1 ? 's não puderam ser excluídas' : ' não pode ser excluída'}`
        );
      if (successCount) {
        toast.success(
          `${successCount} tarefa${successCount > 1 ? 's foram excluídas com sucesso.' : ' foi excluída com sucesso.'}`
        );
        loadData({});
      }
    });
  };

  const handleToggleAllRow = () => {
    _expandedRowKeys.current = _expandedRowKeys?.current?.length ? [] : list?.map(l => l.id);
    setExpandedRowKeys(_expandedRowKeys.current);
  };

  useEffect(() => {
    const isFilter = _filter.current !== filter;
    _filter.current = filter;
    loadData({ generateLoading: isFilter });
  }, [filter, listOrder]);

  useEffect(() => {
    if (dateRangeDisplay?.value && dateRangeDisplay.value !== 'all') {
      dataRangeSelected.current = true;
    }
  }, [dateRangeDisplay]);

  useEffect(() => {
    const object = parseArrayAsObject(taskStatus, true);
    setSerializedStatusArray(object);
  }, [taskStatus]);

  useEffect(() => {
    const object = parseArrayAsObject(taskPriority, true);
    setSerializedPrioritiesArray(object);
  }, [taskPriority]);

  const stepColumns = scheduleColumns({
    isMobile: isMobile(),
    handleChange,
    handleAdd,
    loadData,
    priorityList: taskPriority,
    statusList: taskStatus,
    serializedStatusArray,
    serializedPrioritiesArray,
    taskStatusEnum,
    refurbishStatusEnum,
    userType: user.type,
    idReference,
    referKey,
    isApply,
    idCompany,
    generalTask,
    isDefaultOrder,
    setCurrentData,
    lastAdd,
    handleToggleAllRow,
    workDays,
    dayoffs
  });

  return {
    list,
    setList,
    loadData,
    handleAdd,
    handleChange,
    handleSort,
    handleBulkChange,
    handleBulkDelete,
    isDefaultOrder,
    listOrder,
    expandItem,
    loading,
    progress,
    expandedRowKeys,
    currentData,
    setCurrentData,
    serializedPrioritiesArray,
    serializedStatusArray,
    stepColumns
  };
};

export default useScheduleData;
