import dayjs from 'dayjs';

import { sumByField } from './helper';
import formatCurrency from './formatCurrency';
import { store } from '../config/redux-store';

const calculatePercentage = (price, total) =>
  total !== 0 ? parseFloat(((parseFloat(price || 0) * 100) / parseFloat(total)).toFixed(10)) : 0;

const calculatePriceByPercentage = (percentage, total) =>
  parseFloat(((parseFloat(percentage || 0) / 100) * parseFloat(total)).toFixed(2));

const sortByDueDate = arr =>
  arr.sort((a, b) => {
    if (dayjs(a.dueDate).isAfter(b.dueDate)) return 1;
    if (dayjs(a.dueDate).isBefore(b.dueDate)) return -1;
    return 0;
  });

const sortByStatus = ({ arr, installmentStatuses }) =>
  arr.sort((a, b) => {
    if (installmentStatuses.allPaid.includes(a.idInstallmentStatus)) return -1;
    if (installmentStatuses.allPaid.includes(b.idInstallmentStatus)) return 1;
    return 0;
  });

const getLastDueDate = arr => {
  if (arr.length === 0) return null;
  return dayjs(arr[arr.length - 1].dueDate);
};

const generateInstallments = ({ quantity, total, installments, otherData, resetInstallments = false }) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;

  const _total = parseFloat(total || 0);
  const paidInstallments =
    installments?.filter(item => installmentStatuses.allPaid.includes(item.idInstallmentStatus)) || [];

  if (paidInstallments.length > quantity) {
    return { error: 'Número de parcelas deve ser maior que a quantidade de parcelas pagas' };
  }

  const installmentsValue = sumByField(installments, 'price', 'originalValue') || 0;

  if ((quantity === installments?.length && installmentsValue === _total) || _total < 0) {
    let totalOfPercentage = 0;
    const newInstallments = installments.map((item, index) => {
      const isLastItemOfArray = index === quantity - 1;
      let percentage = calculatePercentage(item?.price, _total);

      if (!isLastItemOfArray) {
        totalOfPercentage += percentage;
      } else {
        percentage = parseFloat((100 - totalOfPercentage).toFixed(10));
      }
      return { ...item, percentage };
    });
    return sortByDueDate(newInstallments);
  }

  if (quantity === installments.length && paidInstallments.length === 0 && !resetInstallments) {
    let totalPrice = 0;
    const newInstallments = installments.map((item, index) => {
      let price = calculatePriceByPercentage(item.percentage, _total);
      if (index === installments.length - 1) price = _total - totalPrice;
      else totalPrice += price;
      return { ...item, price };
    });
    return sortByDueDate(newInstallments);
  }

  const paidValue = sumByField(paidInstallments, 'price', 'originalValue') || 0;

  if (paidValue === _total) return sortByDueDate(paidInstallments);

  const quantityWithoutPaidQuantity = quantity - paidInstallments.length;
  const valueWithoutPaidValue = _total - paidValue;

  let newArray = Array(quantity).fill(null);

  const price = parseFloat((valueWithoutPaidValue / quantityWithoutPaidQuantity).toFixed(2));
  const lastPrice = parseFloat((valueWithoutPaidValue - price * (quantityWithoutPaidQuantity - 1)).toFixed(2));

  const isEveryInstallmentPaid = paidInstallments.length === quantity;

  let dueDate = getLastDueDate(installments) || dayjs(otherData?.billingDate || new Date());
  let totalOfPercentage = 0;
  const installmentSortedByStatus = sortByStatus({ arr: installments, installmentStatuses });
  newArray = newArray.map((_, index) => {
    const isLastItemOfArray = index === quantity - 1;
    const hasInstallment = installmentSortedByStatus[index];
    let newPrice = Math.max(isLastItemOfArray ? lastPrice : price, 0);
    let newDescription = !otherData
      ? hasInstallment?.description
      : `${otherData?.paymentName || 'Sua descrição aqui'} ${hasInstallment?.number || index + 1}/${quantity}`;

    if (installmentStatuses.allPaid.includes(hasInstallment?.idInstallmentStatus)) {
      newPrice = parseFloat(hasInstallment?.price);
      newDescription = hasInstallment?.description;
    }
    let percentage = calculatePercentage(parseFloat(hasInstallment?.originalValue) || newPrice, _total);

    if (!isLastItemOfArray || isEveryInstallmentPaid) {
      totalOfPercentage += percentage;
    } else {
      percentage = parseFloat((100 - totalOfPercentage).toFixed(10));
    }

    if (hasInstallment) {
      return {
        ...hasInstallment,
        percentage,
        price: newPrice,
        description: newDescription
      };
    }

    dueDate = dayjs(otherData?.billingDate || undefined).add(index, 'month');

    return {
      idPayment: otherData?.idPayment,
      installmentStatus: {
        name: 'Aguardando'
      },
      percentage,
      price: newPrice,
      idCompany: otherData?.idCompany,
      idInstallmentStatus: 1,
      dueDate,
      description: newDescription
    };
  });

  return sortByDueDate(newArray).map((item, index) => ({ ...item, number: index + 1 }));
};

const recalculateInstallments = ({ current, value, installments }) => {
  if (!current) return null;
  const _installments = [...installments];
  const { index, installment } = current;
  _installments[index] = installment;
  const previousInstallments = _installments?.slice(0, index + 1);

  const totalPriceOfPrevious = sumByField(previousInstallments, 'price') || 0;

  if (totalPriceOfPrevious <= 0) {
    return {
      error: `O valor deve ser maior que 0`
    };
  }

  const totalToDistribute = value - totalPriceOfPrevious;
  const restOfInstallments =
    totalToDistribute <= 0
      ? _installments?.slice(index + 1)?.map(item => ({ ...item, price: 0, percentage: 0 }))
      : _installments?.slice(index + 1);
  const isLastInstallment = index === _installments?.length - 1;

  const _restInstallments =
    totalToDistribute > 0
      ? generateInstallments({
          quantity: restOfInstallments.length,
          total: totalToDistribute,
          installments: restOfInstallments,
          resetInstallments: true
        })
      : restOfInstallments;
  let totalOfPercentage = 0;
  const _newInstallments = previousInstallments.concat(_restInstallments).map((item, i) => {
    let percentage = 0;
    if (i !== _installments.length - 1) {
      percentage = calculatePercentage(item.price, value);
      totalOfPercentage += percentage;
    } else {
      percentage = isLastInstallment ? item.percentage : Math.max(parseFloat((100 - totalOfPercentage).toFixed(10)), 0);
    }

    return { ...item, percentage, number: i + 1 };
  });

  const newInstallmentsTotal = sumByField(_newInstallments, 'price');

  if (Number((newInstallmentsTotal - value).toFixed(2)) > 0) {
    return {
      installments: _newInstallments,
      error: `Suas parcelas tem um valor de
        ${formatCurrency(newInstallmentsTotal - value, { currencySymbol: 'R$ ' })}
         a mais que o total de ${formatCurrency(value, { currencySymbol: 'R$ ' })}`
    };
  }

  return _newInstallments;
};

export { recalculateInstallments, generateInstallments, calculatePercentage, calculatePriceByPercentage };
