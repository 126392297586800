import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'antd';
import SelectCustomerRefurbish from '../../../components/Select/SelectCustomerRefurbish';
import { colors } from '../../../styles/style';

const mappingMobileSubHeader = {
  profissional: {
    projetos: {
      title: 'Meus projetos',
      EndContent: () => <div style={{ width: 24 }} />,
      perfil: {
        title: 'Informações do projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      novo: {
        title: 'Novo Projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      editar: {
        title: 'Editar projeto',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    configuracoes: {
      'dados-pessoais': {
        title: 'Informações de Dados Pessoais',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'meu-negocio': {
        title: 'Informações do Meu Negócio',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'configurar-calendario': {
        title: 'Informações do Calendário de trabalho',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'trocar-senha': {
        title: 'Trocar senha',
        EndContent: () => <div style={{ width: 24 }} />
      },
      equipe: {
        title: 'Minha equipe',
        EndContent: () => <div style={{ width: 24 }} />
      },
      etapas: {
        title: 'Status do projeto',
        EndContent: () => <div style={{ width: 24 }} />
      },
      visitante: {
        title: 'Meus visitantes',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo Visitante',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    notificacoes: {
      title: 'Todas',
      EndContent: () => (
        <Link to="/profissional/notificacoes/configuracoes">
          <FontAwesomeIcon color={colors.neutral600} size="lg" icon={faCog} />
        </Link>
      ),
      configuracoes: {
        title: 'Configurações',
        EndContent: () => (
          <Tooltip
            placement="bottomLeft"
            title={`Você sempre receberá notificações pela Plataforma Vobi,
             mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
          >
            <FontAwesomeIcon color={colors.primary600} icon={faInfoCircle} />
          </Tooltip>
        )
      }
    },
    oportunidades: {
      perfil: {
        title: 'Informações da oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      },
      editar: {
        title: 'Editar oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      },
      novo: {
        title: 'Nova oportunidade',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    contatos: {
      clientes: {
        novo: {
          title: 'Novo Cliente',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar Cliente',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      fornecedores: {
        novo: {
          title: 'Novo fornecedor',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar fornecedor',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    'grupos-e-categorias': {
      grupos: {
        novo: {
          title: 'Novo grupo',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar grupo',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      categorias: {
        novo: {
          title: 'Nova categoria',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar categoria',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      unidades: {
        novo: {
          title: 'Nova unidade',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar unidade',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    'cadastro-financeiro': {
      contas: {
        novo: {
          title: 'Nova conta',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar conta',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      categorias: {
        novo: {
          title: 'Nova categoria',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar categoria',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      'Centro de custo': {
        novo: {
          title: 'Novo centro de custo',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar centro de custo',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    biblioteca: {
      produtos: {
        novo: {
          title: 'Novo produto',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar produto',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      servicos: {
        novo: {
          title: 'Novo serviço',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar serviço',
          EndContent: () => <div style={{ width: 24 }} />
        }
      },
      composicoes: {
        novo: {
          title: 'Nova composição',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Ver composição',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    'meu-site': {
      informacoes: {
        title: 'Informações do portfolio',
        EndContent: () => <div style={{ width: 24 }} />
      },
      configuracoes: {
        title: 'Informações de configurações',
        EndContent: () => <div style={{ width: 24 }} />
      },
      portfolio: {
        title: 'Informações do portfolio',
        EndContent: () => <div style={{ width: 24 }} />,
        novo: {
          title: 'Novo portfolio',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar portfolio',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    produtividade: {
      'gestao-tarefas': {
        title: 'Gestão Tarefas',
        EndContent: () => <div style={{ width: 24 }} />
      },
      'cronograma-geral': {
        title: 'Cronograma',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    ferramentas: {
      'meus-templates': {
        novo: {
          title: 'Novo template',
          EndContent: () => <div style={{ width: 24 }} />
        },
        editar: {
          title: 'Editar template',
          EndContent: () => <div style={{ width: 24 }} />
        }
      }
    },
    'diario-de-obra': {
      title: 'Relatórios',
      novo: {
        title: 'Novo relatório',
        EndContent: () => <div style={{ width: 24 }} />
      },
      editar: {
        title: 'Editar relatório',
        EndContent: () => <div style={{ width: 24 }} />
      },
      visualizar: {
        title: 'Informações do relatório',
        EndContent: () => <div style={{ width: 24 }} />
      }
    }
  },

  cliente: {
    notificacoes: {
      title: () => <SelectCustomerRefurbish />,
      EndContent: () => (
        <Link to="/cliente/notificacoes/configuracoes">
          <FontAwesomeIcon color={colors.neutral600} size="lg" icon={faCog} />
        </Link>
      ),
      configuracoes: {
        title: 'Configurações',
        EndContent: () => (
          <Tooltip
            placement="bottomLeft"
            title={`Você sempre receberá notificações pela Plataforma Vobi,
                      mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
          >
            <FontAwesomeIcon color={colors.primary600} icon={faInfoCircle} />
          </Tooltip>
        )
      }
    },
    configuracoes: {
      title: 'Configurações',
      EndContent: () => (
        <Tooltip
          placement="bottomLeft"
          title={`Você sempre receberá notificações pela Plataforma Vobi,
                mas também é possível receber pelo número de celular e e-mail do seu cadastro.`}
        >
          <FontAwesomeIcon color={colors.primary600} icon={faInfoCircle} />
        </Tooltip>
      ),
      'dados-pessoais': {
        title: 'Cadastro dados pessoais',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    cronograma: {
      title: 'Informações do cronograma',
      EndContent: () => <div style={{ width: 24 }} />
    },
    orcamento: {
      title: 'Informações do orçamento',
      EndContent: () => <div style={{ width: 24 }} />
    },
    propostas: {
      title: 'Informações da proposta',
      EndContent: () => <div style={{ width: 24 }} />
    },
    parcelas: {
      title: 'Informações das parcelas',
      EndContent: () => <div style={{ width: 24 }} />
    },
    compras: {
      title: 'Informações de compras',
      EndContent: () => <div style={{ width: 24 }} />
    },
    arquivos: {
      title: 'Informações do arquivo',
      EndContent: () => <div style={{ width: 24 }} />
    },
    solicitacoes: {
      title: 'Informações das solicitações de pagamento',
      EndContent: () => <div style={{ width: 24 }} />
    },
    obra: {
      'diario-de-obra': {
        title: 'Relatórios',
        EndContent: () => <div style={{ width: 24 }} />
      }
    },
    'diario-de-obra': {
      visualizar: {
        title: 'Informações do relatório',
        EndContent: () => <div style={{ width: 24 }} />
      }
    }
  }
};

export { mappingMobileSubHeader };
