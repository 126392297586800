import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VList, scrollTo } from 'virtuallist-antd';
import { useDrag, useDrop } from 'react-dnd';
import { Resizable } from 'react-resizable';
import * as PropTypes from 'prop-types';
import { Table, TablePdf } from './VirtualizedTable.styled';
import useTable from '../../_Hooks/useTable';
import { spaces } from '../../styles/style';
import addSortIconToColumns from './addSortIconToColumns';
import CenteredLoader from '../Loader/CenteredLoader';

const type = 'DraggableBodyRow';

const vc = ({ height = `calc(100vh - 360px)`, onScroll, onReachEnd }) => {
  let lastVirtualListHeight = 0;
  return VList({
    onScroll: () => {
      if (!onScroll) return;
      const virtualList = document.getElementsByClassName('virtuallist')[0];
      const divTable = document.getElementsByClassName('ant-table-body')?.[0];
      setTimeout(() => {
        const virtualListPaddingTop = Number(virtualList?.style?.paddingTop?.replace('px', ''));
        const virtualListHeight = Number(virtualList?.style?.height?.replace('px', ''));
        if (
          virtualListHeight - virtualListPaddingTop - 100 <= divTable?.offsetHeight &&
          virtualListHeight !== lastVirtualListHeight
        ) {
          onScroll();
          lastVirtualListHeight = virtualListHeight;
        }
      }, 100);
    },
    height,
    onReachEnd,
    resetTopWhenDataChange: false
  });
};

const vc2 = VList({
  height: `calc(100vh - 360px)`,
  resetTopWhenDataChange: false
});

const ResizableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        // eslint-disable-next-line
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} style={{ ...restProps?.style }} />
    </Resizable>
  );
};

ResizableTitle.propTypes = {
  onResize: PropTypes.func,
  width: PropTypes.number
};

const CommonTitle = props => {
  const { width, ...restProps } = props;

  return <th {...restProps} {...(width && { style: { ...restProps?.style } })} />;
};

CommonTitle.propTypes = {
  width: PropTypes.number
};

const CommonRow = props => {
  const { index, className, record, AddComponent, ...restProps } = props;
  const _className = `${className} ${record?.children?.length || record?.type === 3 ? 'row-parent' : ''}`;
  const ref = useRef();

  const components = useMemo(() => vc2.body.row, []);

  const tempProps = useMemo(() => {
    return {
      ref,
      className: `${_className}`,
      ...restProps
    };
  }, [className, restProps]);

  return (
    <>
      {record?.isLoading ? <CenteredLoader /> : components(tempProps, ref)}
      {AddComponent && <AddComponent data={record} index={index} />}
    </>
  );
};
CommonRow.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
  record: PropTypes.instanceOf(Object),
  AddComponent: PropTypes.instanceOf(Object)
};

const DraggableBodyRow = props => {
  const { index, moveRow, className, style, record, isExpanded, AddComponent, ...restProps } = props;
  const _className = `${className} ${record?.type === 3 ? 'row-parent' : ''}`;
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex, record: dragItem } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index || dragItem?.idParent !== record?.idParent ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: item => {
      item.record && record && moveRow && moveRow(item, { ...record, isExpanded, index });
    }
  });
  const [, drag] = useDrag({
    type,
    item: { type, index, record },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  useEffect(() => {
    drop(drag(ref));
  }, [drag, drop]);

  const components = useMemo(() => vc2.body.row, []);

  const tempProps = useMemo(() => {
    return {
      ref,
      className: `${_className}${isOver ? dropClassName : ''}`,
      style: { cursor: 'pointer', ...style },
      ...restProps
    };
  }, [className, dropClassName, restProps, style, isOver]);

  return (
    <>
      {components(tempProps, ref)}
      {AddComponent && <AddComponent data={record} index={index} />}
    </>
  );
};

DraggableBodyRow.propTypes = {
  index: PropTypes.number,
  moveRow: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  record: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool,
  AddComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
};

const handleResize = (index, setColumns) => {
  return (e, { size }) => {
    e.stopPropagation();
    setColumns(pre => {
      const temp = [...pre];
      temp[index] = {
        ...temp[index],
        width: size.width
      };

      return temp;
    });
  };
};

const VirtualizedTable = ({
  moveRowProps = {},
  columns = [],
  list,
  scroll = { y: 'calc(100vh - 360px)', x: '100%' },
  childrenColumnName = 'children',
  expandItem,
  scrollIndex,
  AddComponent,
  hideSelectAll,
  onSelect,
  onSelectAll,
  withCommonRow,
  onClick = f => f,
  onSort = () => {},
  hideId,
  isPrint,
  selectedRowKeys,
  onScroll,
  customEmptyState,
  onReachEnd,
  rowSelection = true,
  refreshColumns = false,
  onExpand = f => f,
  expandedRowKeys = null,
  initialExpandedRowKeys = null,
  delayLoad,
  customKey,
  isLoading = false,
  align,
  resizable = true,
  ...props
}) => {
  const [expanded, setExpanded] = useState(expandedRowKeys || initialExpandedRowKeys);
  const [_columns, setColumns] = useState(
    columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column?.width,
        minWidth: column.minWidth,
        onResize: handleResize(index, setColumns),
        className: column?.className
      })
    }))
  );
  const renderCollapseIcon =
    columns.findIndex(column => column?.renderCollapseIcon || column?.key === 'name') + (rowSelection ? 2 : 0);
  const { moveRow = f => f } = useTable({ list, ...moveRowProps });
  const _vc = vc({ hideId, height: scroll.y, onScroll, onReachEnd });
  const components = useMemo(() => {
    return {
      ..._vc,
      body: {
        ..._vc.body,
        row: withCommonRow ? CommonRow : DraggableBodyRow
      },
      header: {
        cell: resizable ? ResizableTitle : CommonTitle
      }
    };
  }, []);

  useEffect(() => {
    if (isPrint || refreshColumns) {
      setColumns(
        columns.map((col, index) => ({
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            onResize: handleResize(index, setColumns),
            className: column?.className
          })
        }))
      );
    }
  }, [columns]);

  const handleSorting = (pagination, filters, sorter) => {
    let sortValue = {};

    if (sorter?.order === 'ascend') {
      sortValue = {
        key: sorter.field,
        order: sorter?.column?.type === 'number' ? 'asc nulls first' : 'asc',
        sorter: sorter?.column?.sorter
      };
    } else if (sorter?.order === 'descend') {
      sortValue = {
        key: sorter.field,
        order: sorter?.column?.type === 'number' ? 'desc nulls last' : 'desc',
        sorter: sorter?.column?.sorter
      };
    }

    return onSort(sortValue);
  };

  useEffect(() => {
    if (expandItem) {
      if (expanded) {
        setExpanded(prev => [...new Set([...(prev || []), expandItem])]);
      } else {
        setExpanded(list.map(l => l.id));
      }
    }
  }, [expandItem]);

  useEffect(() => {
    if (!expandedRowKeys) return;
    setExpanded(expandedRowKeys);
  }, [expandedRowKeys, initialExpandedRowKeys]);

  useEffect(() => {
    if (scrollIndex || scrollIndex === 0) {
      scrollTo({ row: scrollIndex });
    }
  }, [scrollIndex]);

  const RenderTable = isPrint ? TablePdf : Table;

  return isLoading ? (
    <CenteredLoader />
  ) : (
    !delayLoad && (
      <RenderTable
        expandIconColumnIndex={renderCollapseIcon}
        $isPrint={isPrint}
        $withCommonRow={withCommonRow}
        locale={{ emptyText: customEmptyState || 'Nenhum item' }}
        components={
          isPrint
            ? {
                body: {
                  row: CommonRow
                },
                header: {
                  cell: resizable ? ResizableTitle : CommonTitle
                }
              }
            : components
        }
        rowKey={record => (customKey ? customKey(record) : record?.id)}
        onExpand={onExpand}
        columns={addSortIconToColumns(_columns)}
        dataSource={list}
        pagination={false}
        scroll={isPrint ? { y: '100%', x: '100%' } : scroll}
        $minHeight={scroll?.y}
        $padBottom={Boolean(AddComponent)}
        onRow={(record, index) => ({
          record,
          index,
          moveRow,
          AddComponent,
          onClick: () => onClick(record),
          row: _vc.body.row,
          isExpanded: (expanded?.includes(record.id) && record.type === 3) || !record?.idParent
        })}
        rowSelection={
          rowSelection
            ? {
                indentSize: 16,
                checkStrictly: false,
                hideSelectAll,
                onSelect,
                onSelectAll,
                selectedRowKeys,
                getCheckboxProps: row => ({
                  id: `checkbox-${row.id}`,
                  style: {
                    padding: spaces.space1,
                    marginLeft: `-${spaces.space1}`
                  }
                })
              }
            : false
        }
        expandable={{
          expandRowByClick: true,
          childrenColumnName,
          expandedRowKeys: expanded,
          onExpandedRowsChange: expandedRows => {
            setExpanded(expandedRows);
          }
        }}
        onChange={handleSorting}
        showSorterTooltip={false}
        $alignItems={align}
        {...props}
      />
    )
  );
};

VirtualizedTable.propTypes = {
  moveRowProps: PropTypes.instanceOf(Object),
  columns: PropTypes.instanceOf(Array),
  list: PropTypes.instanceOf(Object),
  scroll: PropTypes.instanceOf(Object),
  childrenColumnName: PropTypes.string,
  expandItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollIndex: PropTypes.number,
  AddComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hideSelectAll: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  withCommonRow: PropTypes.bool,
  onClick: PropTypes.func,
  onSort: PropTypes.func,
  hideId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPrint: PropTypes.bool,
  selectedRowKeys: PropTypes.instanceOf(Array),
  onScroll: PropTypes.func,
  customEmptyState: PropTypes.instanceOf(Object),
  onReachEnd: PropTypes.func,
  rowSelection: PropTypes.bool,
  refreshColumns: PropTypes.bool,
  onExpand: PropTypes.func,
  expandedRowKeys: PropTypes.instanceOf(Array),
  initialExpandedRowKeys: PropTypes.instanceOf(Array),
  delayLoad: PropTypes.bool,
  customKey: PropTypes.func,
  isLoading: PropTypes.bool,
  align: PropTypes.string,
  resizable: PropTypes.bool
};

export default VirtualizedTable;
