import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import Drawer from './Drawer';
import Form from '../Form/Form';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { transferMapping, transferSchema } from '../../lib/mapping/Form/transferSchema';
import FileUpload from '../File/FileUpload';
import { Div, colors, spaces } from '../../styles/style';
import AttachmentList from '../List/AttachmentList';
import { BadgeText, Paragraph, Subtitle } from '../Text/Text';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import TooltipIcon from '../Tooltip/TooltipIcon';
import UndoReconciliationModal from '../Modal/UndoReconciliationModal';
import CreatedByContent from '../Content/CreatedByContent';

const ButtonUndoReconciliation = ({ setUndoReconciliation, data, transferType }) => {
  const isReconciliationIn = transferType === 'income';
  const showButton = isReconciliationIn ? !!data?.idReconciliationIn : !!data?.idReconciliationOut;

  const handleUndoReconciliation = () => {
    setUndoReconciliation({
      open: true,
      id: isReconciliationIn ? data?.idReconciliationIn : data?.idReconciliationOut
    });
  };

  const text = `Transferência de ${
    isReconciliationIn ? 'entrada' : 'saída'
  } conciliada, clique para desfazer conciliação`;
  return showButton ? (
    <TooltipIcon
      icon={faBadgeCheck}
      text={text}
      id={isReconciliationIn ? 'undo-reconciliation-in' : 'undo-reconciliation-out'}
      iconColor={colors.primary500}
      onClick={handleUndoReconciliation}
      style={{ paddingLeft: spaces.space1, width: spaces.space2, height: spaces.space2 }}
    />
  ) : null;
};

ButtonUndoReconciliation.propTypes = {
  setUndoReconciliation: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  transferType: PropTypes.string
};

const TransferDrawer = ({ onClose = f => f, id, open = true, initialData = {}, isConciliation }) => {
  const { paymentTypeValues } = useSelector(state => state.setup.enums);
  const [data, setData] = useState(initialData);
  const forceUpdate = useRef();
  const [files, setFiles] = useState([]);
  const [disable, setDisable] = useState(false);
  const [undoReconciliation, setUndoReconciliation] = useState({ open: false, id: null });
  const [removeConciliation, setRemoveConciliation] = useState(false);
  const [reload, setReload] = useState(false);
  const tooltipText = `Registre movimentação entre suas contas bancárias. Basta inserir a conta origem
  (em que o valor saiu) e a conta destino (em que o valor entrou), descrição, valor e data da transferência .`;

  const { handleGet, handleCreate, handleUpdate, loading } = useCRUD({
    model: `payment`,
    immediatelyLoadData: false
  });

  const { list: accountList } = useCRUD({
    model: `bankAccount`,
    options: { where: { isFinancialEntry: true } },
    immediatelyLoadData: true
  });

  useEffect(() => {
    id &&
      handleGet({
        refetchPathOptions: '/transfer',
        refetchOptions: {
          ...(id && {
            where: { transferId: id }
          })
        }
      }).then(value => {
        setDisable(!!(value[0]?.idReconciliationIn || value[0]?.idReconciliationOut));
        setData(value[0]);
        if (value[0]?.countFile > 0)
          handleGet({
            refetchPathOptions: `/${value[0]?.id[0]}`,
            refetchOptions: {
              attributes: ['id'],
              include: ['files']
            }
          }).then(dataFiles => {
            setFiles(dataFiles?.files);
          });
      });
  }, [id]);

  const handleSubmit = submitData => {
    if (!id) {
      return handleCreate({
        postPathOptions: '/transfer',
        values: {
          ...submitData,
          value: formatNumber(submitData?.value),
          billType: paymentTypeValues.transfer.value,
          files
        },
        refresh: false,
        displayToast: 'Transferência criada com sucesso.'
      }).then(resp => onClose(true, id, resp));
    }
    return handleUpdate({
      values: { ...submitData, value: formatNumber(submitData?.value), files },
      updatePathOptions: `/transfer/${id}`,
      displayToast: 'Transferência salva com sucesso.',
      refresh: false
    }).then(resp => onClose(true, id, resp));
  };

  const formDescription = values => {
    setData(prev => {
      if (
        values.idTransferIn &&
        values.idTransferOut &&
        (prev.idTransferIn !== values.idTransferIn || prev.idTransferOut !== values.idTransferOut)
      ) {
        forceUpdate.current = true;
        const inDescription = accountList.find(account => account.id === values.idTransferIn);
        const outDescription = accountList.find(account => account.id === values.idTransferOut);
        return {
          ...values,
          information: `${outDescription?.name} - ${inDescription?.name}`,
          value: formatNumber(values.value)
        };
      }
      return prev;
    });
  };

  const handleAddFile = newFiles => {
    setFiles(prev => [...prev, ...newFiles]);
  };

  useEffect(() => {
    setDisable(!!(data?.idReconciliationIn || data?.idReconciliationOut));
    setRemoveConciliation(false);
    if (removeConciliation) setReload(true);
  }, [removeConciliation]);

  return (
    <>
      <Drawer
        title={
          <Div gap={spaces.space0}>
            {`${id ? `Transferência` : `Nova transferência entre contas`}`}
            {id ? (
              <Paragraph type="small" color={colors.neutral400}>
                #${id}
              </Paragraph>
            ) : null}
          </Div>
        }
        loading={loading}
        open={open}
        formId="transfer-drawer"
        onClose={() => onClose(reload, id)}
        width={880}
      >
        {loading ? (
          <CenteredLoader />
        ) : (
          <Div direction="column" gap={spaces.space3} $fullWidth align="start">
            <Div justify="space-between">
              <Div gap={spaces.space1} style={{ padding: `${spaces.space1} 0px` }}>
                <Subtitle>
                  <strong>Informações da transferência</strong>
                </Subtitle>
                {!id ? <TooltipIcon text={tooltipText} icon={faInfoCircle} iconColor={colors.primary500} /> : null}
              </Div>
              {data && !!(data?.idReconciliationIn || data?.idReconciliationOut) ? (
                <BadgeText $justify="flex-end" color={colors.green100} count="LANÇAMENTO CONCILIADO" />
              ) : null}
            </Div>
            {removeConciliation ? (
              <CenteredLoader />
            ) : (
              <Form
                id="transfer-drawer"
                displayButtons={false}
                onSubmit={handleSubmit}
                mapping={() =>
                  transferMapping({
                    accountList,
                    isConciliation,
                    initialData,
                    disable,
                    ButtonUndoReconciliation,
                    data,
                    setUndoReconciliation
                  })
                }
                schema={transferSchema}
                onFormChange={formDescription}
                data={{ ...data, value: formatCurrency(data?.value || 0, { currencySymbol: 'R$' }) }}
                forceUpdate={forceUpdate}
                keepOldValues
              />
            )}
            <Div direction="column" gap={spaces.space2} $fullWidth align="start">
              <Subtitle>Anexos</Subtitle>
              <Div gap={spaces.space1}>
                <FileUpload
                  style={{ marginLeft: `-${spaces.space1}` }}
                  id="file-input"
                  onChange={handleAddFile}
                  multiple
                  showGallery={false}
                  text="Adicionar anexo"
                  listType={null}
                  buttonProps={{ type: 'primary' }}
                  fullWidth
                />
                <Paragraph type="small">(Tamanho máximo 10mb)</Paragraph>
              </Div>

              <AttachmentList preventUpload files={files} setFiles={setFiles} />
            </Div>
            <CreatedByContent data={data} showLine noPadding />
            {undoReconciliation?.open && (
              <UndoReconciliationModal
                installment={{ idReconciliation: undoReconciliation?.id }}
                onClose={_reload => {
                  setUndoReconciliation(false);
                  if (!_reload) return;
                  setRemoveConciliation(true);
                  if (undoReconciliation?.id === data?.idReconciliationIn) {
                    setData(prev => ({ ...prev, idReconciliationIn: undefined }));
                  } else {
                    setData(prev => ({ ...prev, idReconciliationOut: undefined }));
                  }
                }}
              />
            )}
          </Div>
        )}
      </Drawer>
    </>
  );
};

TransferDrawer.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.number,
  open: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  isConciliation: PropTypes.bool
};

export default TransferDrawer;
