const gtmIds = {
  dashboard: 'menu-dashboard',
  customers: 'menu-clientes',
  contacts: 'menu-clientes',
  operational: 'menu-operacional',
  opportunities: 'menu-oportunidades',
  projects: 'menu-projetos',
  payments: 'menu-pagamentos',
  tools: 'menu-ferramentas',
  productivity: 'menu-produtividade',
  agenda: 'menu-agenda',
  support: 'menu-suporte',
  catalog: 'menu-catalogo',
  help: 'menu-help'
};

export default gtmIds;
