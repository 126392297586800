import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useCRUD from './useCRUD';
import { getBrazilianDate, getDateWithCustomHours } from '../lib/helpers/helper';

const useRefurbishPlanningDrawer = ({
  data,
  setIsSubmitting,
  setNewFormValues,
  newFormValues,
  setHasChanges,
  setSelectedItems,
  afterSubmit,
  ganttInstance,
  updateLinkLags,
  cleanUnscheduledFromChildren
}) => {
  const [ganttChanges, setGanttChanges] = useState({
    updatedLinks: {},
    updatedTasks: {},
    deletedLinks: {},
    createdLinks: {}
  });

  const { handleUpdate } = useCRUD({
    model: 'refurbishItems',
    pathOptions: `/bulkUpdateWithPlanningLinks`,
    immediatelyLoadData: false
  });

  const onChangeDateRelatedField = field => val => {
    const { duration: _duration, plStartDate, plEndDate } = {
      ...data,
      ...(newFormValues || {})
    };

    if (
      (field === 'duration' && !plStartDate && !plEndDate) ||
      (field === 'plStartDate' && !_duration && !plEndDate) ||
      (field === 'plEndDate' && !plStartDate && !_duration)
    ) {
      setNewFormValues(prev => ({ ...(prev || {}), [field]: val }));
      return;
    }

    if (field !== 'duration' && !val) {
      setNewFormValues(prev => ({ ...(prev || {}), plStartDate: null, plEndDate: null }));
      return;
    }

    if (field === 'duration' && (!val || Number(val) === 0)) {
      setNewFormValues(prev => ({ ...(prev || {}), duration: 0, plStartDate: null, plEndDate: null }));
      return;
    }

    let start = field === 'plStartDate' ? val : plStartDate;
    let end = field === 'plEndDate' ? val : plEndDate;
    const duration = field === 'duration' ? val || 0 : _duration;

    if (start)
      start = ganttInstance.date.date_part(
        getDateWithCustomHours(start, {
          hours: 3
        })
      );

    if (end)
      end = getDateWithCustomHours(end, {
        hours: 15
      });

    if (field === 'duration' && val < 0) {
      toast.error('A duração não pode ser negativa.');
      setNewFormValues(prev => ({
        ...(prev || {}),
        duration:
          start && end
            ? ganttInstance.calculateDuration({
                start_date: ganttInstance.date.convert_to_utc(start),
                end_date: ganttInstance.date.convert_to_utc(end)
              })
            : 0
      }));

      return;
    }

    if ((field === 'duration' && plStartDate) || (field === 'plStartDate' && _duration)) {
      end = getDateWithCustomHours(
        ganttInstance.calculateEndDate({
          start_date: ganttInstance.date.convert_to_utc(start),
          duration: duration - 1
        }),
        {
          hours: 15
        }
      );
    } else if ((field === 'duration' && !plStartDate) || (field === 'plEndDate' && !plStartDate)) {
      start = getDateWithCustomHours(
        ganttInstance.calculateEndDate({
          start_date: ganttInstance.date.convert_to_utc(end),
          duration: -duration + 1
        }),
        {
          hours: 3
        }
      );
    }

    const task = ganttInstance.getTask(data.id);

    task.start_date = start;
    task.end_date = end;
    task.unscheduled = !start || !end;

    updateLinkLags(task);

    cleanUnscheduledFromChildren(task);

    ganttInstance.updateTask(task.id);
    ganttInstance.autoSchedule(task.id);
  };

  const addDatesToCurrentTask = () => {
    const currentTask = ganttInstance.getTask(data.id);
    if (!currentTask?.unscheduled) return;

    currentTask.end_date = getDateWithCustomHours(
      ganttInstance.calculateEndDate({
        start_date: ganttInstance.date.convert_to_utc(currentTask.start_date),
        duration: currentTask.originalDuration - 1
      }),
      {
        hours: 15
      }
    );

    currentTask.unscheduled = false;
    ganttInstance.updateTask(currentTask.id);
  };

  useEffect(() => {
    if (!ganttInstance || !data) return;
    const handleLinkUpdate = (linkId, link) => {
      setGanttChanges(prev => {
        if (prev.createdLinks[linkId]) {
          return {
            ...prev,
            createdLinks: { ...prev.createdLinks, [linkId]: link }
          };
        }
        return {
          ...prev,
          updatedLinks: { ...prev.updatedLinks, [linkId]: link }
        };
      });

      setSelectedItems(prev =>
        prev.map(item => {
          if (item.id === linkId) {
            return { ...item, lag: link.lag };
          }
          return item;
        })
      );
      addDatesToCurrentTask();
      ganttInstance.autoSchedule();
      setHasChanges(true);
    };

    const handleLinkDelete = (linkId, link) => {
      setGanttChanges(prev => {
        const { updatedLinks, createdLinks, deletedLinks, ...rest } = prev;
        const newUpdatedLinks = { ...updatedLinks };
        const newCreatedLinks = { ...createdLinks };
        const newDeletedLinks = { ...deletedLinks };

        if (createdLinks[linkId]) {
          // If the link was in createdLinks, just remove it from there
          delete newCreatedLinks[linkId];
        } else {
          // If it wasn't in createdLinks, remove from updatedLinks and add to deletedLinks
          delete newUpdatedLinks[linkId];
          newDeletedLinks[linkId] = link;
        }

        return {
          ...rest,
          updatedLinks: newUpdatedLinks,
          createdLinks: newCreatedLinks,
          deletedLinks: newDeletedLinks
        };
      });
      addDatesToCurrentTask();
      ganttInstance.autoSchedule();
      setHasChanges(true);
    };

    const handleLinkAdd = (linkId, link) => {
      setGanttChanges(prev => ({
        ...prev,
        createdLinks: { ...prev.createdLinks, [linkId]: link }
      }));

      ganttInstance.autoSchedule();
      setHasChanges(true);
    };

    const handleTaskUpdate = (taskId, task) => {
      setGanttChanges(prev => ({
        ...prev,
        updatedTasks: { ...prev.updatedTasks, [taskId]: task }
      }));

      if (taskId === data?.id) {
        const startDate = getBrazilianDate(task?.start_date).format('YYYY-MM-DD');
        const endDate = getBrazilianDate(task?.end_date).format('YYYY-MM-DD');

        setNewFormValues(prev => ({
          ...(prev || {}),
          plStartDate: startDate,
          plEndDate: endDate,
          duration: task?.duration
        }));
      }
      setHasChanges(true);
    };

    ganttInstance.attachEvent('onAfterLinkUpdate', handleLinkUpdate);
    ganttInstance.attachEvent('onAfterLinkDelete', handleLinkDelete);
    ganttInstance.attachEvent('onAfterLinkAdd', handleLinkAdd);
    ganttInstance.attachEvent('onAfterTaskUpdate', handleTaskUpdate);

    // eslint-disable-next-line consistent-return
    return () => {
      ganttInstance.detachEvent('onAfterLinkUpdate');
      ganttInstance.detachEvent('onAfterLinkDelete');
      ganttInstance.detachEvent('onAfterLinkAdd');
      ganttInstance.detachEvent('onAfterTaskUpdate');
    };
  }, [ganttInstance, data]);

  const prepareData = parsedData => {
    const { responsible, supplier, quantity, code, parent, type: _type, user, ...rest } = parsedData;
    return { ...rest, plIdResponsible: responsible?.id || null };
  };

  const handleSubmit = (submitData, files) => {
    setIsSubmitting(true);
    const preparedData = prepareData({ ...submitData, isCurrentTask: true });

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        ...(preparedData?.plStartDate && preparedData?.plEndDate ? ganttChanges.updatedTasks : []),
        [data?.id]: preparedData
      },
      files
    };

    handleUpdate({
      values: { ...payload, idRefurbish: data?.idRefurbish, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const updateLink = (link, updates) => {
    setSelectedItems(prev =>
      prev?.map(item => {
        if (item?.id === link?.id) {
          const updatedItem = { ...item, ...updates };

          if (ganttInstance && updatedItem.id) {
            const ganttLink = ganttInstance.getLink(updatedItem.id);
            if (ganttLink) {
              ganttLink.source = updatedItem.source;
              ganttLink.type = updatedItem.type;
              ganttLink.target = data.id;
              ganttLink.lag = updatedItem.lag;
              ganttInstance.updateLink(updatedItem.id);
            }
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const removeLink = link => {
    if (ganttInstance && link?.id) {
      ganttInstance.deleteLink(link?.id);
    }
    setSelectedItems(prev => prev.filter(item => item?.id !== link?.id));
  };

  const createLink = selectedItemId => {
    const newLink = {
      source: Number(selectedItemId),
      target: Number(data.id),
      type: ganttInstance.config.links.finish_to_start,
      lag: 0
    };

    if (!ganttInstance.isLinkAllowed(newLink)) {
      toast.error(`O link não pôde ser criado pois resultaria em uma dependência circular.`);
      return;
    }
    const linkId = ganttInstance.addLink(newLink);

    const newItem = {
      source: Number(selectedItemId),
      target: Number(data.id),
      lag: 0,
      type: ganttInstance.config.links.finish_to_start,
      id: linkId
    };
    setSelectedItems(prev => [...prev, newItem]);
  };

  return {
    createLink,
    removeLink,
    updateLink,
    handleSubmit,
    onChangeDateRelatedField,
    ganttInstance
  };
};

export default useRefurbishPlanningDrawer;
