import styled, { css } from 'styled-components';
import { Select as AntSelect } from 'antd';

import { colors, fonts } from '../../styles/style';

const SelectStyled = styled(AntSelect)`
  text-align: initial;
  width: 100%;

  .ant-select-selection-overflow-item-rest > span {
    background-color: ${colors.primary50};
    color: ${colors.primary500};
  }
  ${props =>
    props.mode === 'multiple' &&
    css`
      .ant-select-selection-item {
        background-color: ${colors.primary50};
        color: ${colors.primary500};
        svg {
          color: ${colors.primary500};
        }
      }
    `}

  .ant-select-selector {
    box-shadow: none !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutral600} !important;
    }
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    ${props =>
      !props.noHeight &&
      css`
        height: ${props.height ? `${props.height}px` : '32px'};
      `}

    align-items: center;
  }

  ${props =>
    props.$itemHeight &&
    css`
      .ant-select-selection-item {
        height: ${props.$itemHeight};
      }
    `}

  ${props =>
    props.$dark &&
    css`
      .ant-select-selection-search-input {
        color: #fff !important;
      }

      .ant-select-selector {
        background-color: ${colors.neutral800} !important;
        color: #fff !important;
        border: 1px solid #fff !important;
        box-shadow: none !important;
      }

      .ant-select-arrow {
        color: #fff;
      }

      .ant-select-clear {
        background-color: ${colors.neutral800};
        color: #fff;
      }
    `}
`;

const SelectDebouceStyled = styled(AntSelect)`
  width: 100%;

  .ant-select-selector {
    box-shadow: none !important;
    border-color: ${colors.neutral200} !important;

    &:hover,
    &:focus {
      border-color: ${colors.neutral600} !important;
    }

    &.ant-select-item-option-content {
      font-size: ${fonts.sizeXs};
    }
  }
`;
export { SelectStyled, SelectDebouceStyled };
