import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import { colors } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import useCRUD from '../../_Hooks/useCRUD';
import { Container } from './TaskButton.styles';

const DuplicateTaskButton = ({ idTask, onDuplicated }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { handleCreate: duplicateTask } = useCRUD({
    model: 'task/duplicate',
    immediatelyLoadData: false
  });

  return (
    <>
      <Container onClick={() => setShowConfirmModal(true)}>
        <Tooltip placement="bottom" title="Duplicar" mouseEnterDelay={0.6}>
          <FontAwesomeIcon icon={faClone} color={colors.primary600} />
        </Tooltip>
      </Container>
      {showConfirmModal && (
        <ConfirmModal
          text="Deseja duplicar esta tarefa?"
          onClose={() => setShowConfirmModal(false)}
          onSubmit={() => {
            duplicateTask({
              values: { id: idTask },
              refresh: false
            }).then(response => {
              setShowConfirmModal(false);
              toast.success(`Tarefa ${response.name} duplicada com sucesso`);
              onDuplicated(response);
            });
          }}
        />
      )}
    </>
  );
};

DuplicateTaskButton.propTypes = {
  idTask: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDuplicated: PropTypes.func
};

export default DuplicateTaskButton;
