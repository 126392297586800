import styled from 'styled-components';
import { colors, fonts, radius, spaces } from '../../styles/style';

export const Content = styled.div`
  display: flex;
  max-width: 500px;
  border-radius: ${radius.radius1};
  overflow: hidden;

  #idTask-div {
    ${props => props.$taskDivMarginLeft && `margin-left: ${props.$taskDivMarginLeft} !important;`}
  }

  #date-div {
    ${props => props.$dateDivMarginLeft && `margin-left: ${props.$dateDivMarginLeft} !important;`}
  }

  #performed-hours {
    border: 1px solid ${colors.neutral100};
    height: ${spaces.space4};
    font-size: ${fonts.sizeMd};
    color: ${colors.neutral400};
    text-align: right;
  }

  #date {
    text-align: center;
    font-size: ${fonts.sizeMd};
    color: ${colors.neutral400};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spaces.space2};

  .ant-typography {
    font-size: ${fonts.sizeLg};
    color: ${colors.neutral600};
  }

  svg {
    margin-right: ${spaces.space1};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid ${colors.neutral100};
  padding: ${spaces.space1} ${spaces.space2};
  position: absolute;
  left: 0;

  .ant-btn-primary {
    font-size: ${fonts.sizeMd};
    width: 120px;
  }
`;
