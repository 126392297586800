import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import useCRUD from '../../_Hooks/useCRUD';
import { Container } from './TaskButton.styles';

const RemoveTaskButton = ({ idTask, onDeleted }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { handleDelete } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  return (
    <>
      <Container onClick={() => setShowConfirmModal(true)}>
        <Tooltip placement="bottom" title="Excluir" mouseEnterDelay={0.6}>
          <FontAwesomeIcon icon={faTrash} color={colors.primary600} />
        </Tooltip>
      </Container>
      {showConfirmModal && (
        <ConfirmModal
          text="Deseja realmente excluir esta tarefa?"
          onClose={() => setShowConfirmModal(false)}
          onSubmit={() => {
            handleDelete({ id: idTask, refresh: false, displayToast: true }).then(response => {
              setShowConfirmModal(false);
              onDeleted(response);
            });
          }}
        />
      )}
    </>
  );
};

RemoveTaskButton.propTypes = {
  idTask: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleted: PropTypes.func
};

export default RemoveTaskButton;
