import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import ConfirmModal from '../Modal/ConfirmModal';
import Dropdown from './DropdownV2';
import EditTaskModal from '../Modal/EditTaskModal';
import LinkTaskModal from '../Modal/linkTaskModal';
import CopyLinkButton from '../Button/CopyLinkButton';
import useCRUD from '../../_Hooks/useCRUD';
import DropDownButton from '../Button/DropDownButton';
import { DropdownContainer, StyledMenu } from './DropdownV2.styled';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import LoadingModal from '../Modal/LoadingModal';

const TaskActionDropdown = ({
  item,
  afterSubmit = f => f,
  generalTask,
  isTask,
  referKey,
  referModel,
  idReference,
  idCompany
}) => {
  const [showModal, setShowModal] = useState();
  const dropdownModel = generalTask ? 'generalTask' : 'task';

  const { handleCreate, handleUpdate, handleDelete, loading } = useCRUD({
    model: isTask ? 'task' : referModel,
    immediatelyLoadData: false
  });

  const actions = {
    post: handleCreate,
    put: handleUpdate,
    delete: handleDelete
  };

  const handleCrudClick = (verb, params) => () => {
    actions[verb]({
      ...params,
      id: item?.id,
      values: { ...item, ...(isTask && { source: generalTask ? 'General' : 'Project' }) },
      refresh: false
    }).then(resp => {
      if (!resp?.error && afterSubmit) {
        afterSubmit({ item: resp || item, id: item?.id });
      }
    });
  };

  const handleClick = ({ modal, params }) => () => {
    setShowModal({ modal, params });
  };

  const custom = {
    copyLink: (
      <CopyLinkButton onClose={f => f} eventButton="item" urlContext="t" idData={item?.id} text>
        Copiar link
      </CopyLinkButton>
    )
  };

  const modal = {
    confirmModal: () => (
      <ConfirmModal
        onSubmit={() =>
          handleDelete({
            id: item?.id,
            displayToast: 'Operação realizada com sucesso!',
            refresh: false
          }).then(resp => {
            if (!resp?.error && afterSubmit) {
              afterSubmit({ item });
            }
          })
        }
        onClose={() => setShowModal(null)}
        text={`Deseja realmente apagar essa ${isTask ? 'tarefa' : 'etapa'}?`}
      />
    ),
    editItemModal: () => (
      <EditTaskModal
        id={item?.id}
        options={{ where: { [referKey]: idReference } }}
        idCompany={idCompany}
        {...{ [referKey]: idReference }}
        onClose={() => setShowModal(null)}
        afterSubmit={afterSubmit}
      />
    ),
    linkTaskModal: params => (
      <LinkTaskModal
        {...params}
        isTemplate={referKey === 'idTemplate'}
        idReference={idReference}
        row={item}
        onClose={() => setShowModal(null)}
        reload={afterSubmit}
        generalTask={generalTask}
      />
    )
  };

  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      {allDropdown[isTask ? dropdownModel : 'default']?.map(option => (
        <React.Fragment key={`menu${option.label}`}>
          <Menu.Item key={`menuItem${option.label}`}>
            {custom[option.button] || (
              <DropDownButton
                optionData={option}
                id={option.id}
                text
                onClick={
                  option.verb && !option.modal ? handleCrudClick(option.verb, option.params) : handleClick(option)
                }
              />
            )}
          </Menu.Item>
        </React.Fragment>
      ))}
    </StyledMenu>
  );

  return (
    <>
      {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}
      <DropdownContainer mobileDirection="column">
        <Dropdown slim trigger={['click']} menu={menu} padding={0} />
      </DropdownContainer>
      <LoadingModal open={loading} />
    </>
  );
};

TaskActionDropdown.propTypes = {
  item: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  generalTask: PropTypes.bool,
  isTask: PropTypes.bool,
  referKey: PropTypes.string,
  referModel: PropTypes.string,
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idCompany: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TaskActionDropdown;
