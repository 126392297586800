import styled, { css } from 'styled-components';
import { breakpoints, colors, spaces } from '../../styles/style';

export const BackgroundColorText = styled.span`
  ${props =>
    props?.$color &&
    css`
       {
        background-color: ${props?.$color};
        color: ${colors.white};
        padding: 0 ${spaces.space1};
        display: flex;
        width: fit-content;
        border-radius: ${spaces.space0};
        align-items: center;

        @media (max-width: ${breakpoints.tablet}) {
          padding: ${spaces.space0} ${spaces.space1};
        }
      }
    `}
`;

export const BackgroundColorDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
