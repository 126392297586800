import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Alert } from 'antd';
import chunk from 'lodash/chunk';
import config from './importConfig';
import { ptBR } from './DataImporter.ptBR';
import useCRUD from '../../_Hooks/useCRUD';
import { toKebabCase as toKebabCaseFn } from '../../lib/helpers/helper';
import ProjectOrOpportunityEventService from '../../lib/gtm/opportunity';
import Modal from '../Modal/Modal';
import { colors } from '../../styles/style';
import parentAssociation from '../../lib/helpers/parentAssociation';

const customErrors = ['API-B-00195'];

const StyledAlertDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px;
  gap: 10px;
`;

const Success = ({ success }) => {
  if (!success) return null;
  return (
    <StyledAlertDiv>
      <Alert message="" description={`${success.imported} linhas importadas`} type="success" showIcon />
      {Object.keys(success.createsNews).map(
        item =>
          success.createsNews[item] > 0 && (
            <Alert message="" description={`Novo: ${success.createsNews[item]} ${item} `} type="success" showIcon />
          )
      )}
    </StyledAlertDiv>
  );
};
Success.propTypes = {
  success: PropTypes.instanceOf(Object)
};

const Errors = ({ errors }) => {
  if (!errors) return null;

  const { imported, errorMap } = errors;
  const isCustomError = customErrors.includes(errorMap['400'] && errorMap['400'].code);

  return (
    <StyledAlertDiv>
      {imported > 0 ? (
        <Alert message="" description={`Importação realizada até a linha ${imported + 1}`} type="warning" showIcon />
      ) : (
        <Alert
          message=""
          description={isCustomError ? errorMap['400'].messages[0] : 'Nenhuma linha foi importada'}
          type="warning"
          showIcon
        />
      )}
      {Object.keys(errorMap).map(key => {
        return (
          !isCustomError &&
          errorMap[key].messages.map(msg => (
            <Alert key={key} message="Erros na importação" description={msg} type="error" showIcon />
          ))
        );
      })}
    </StyledAlertDiv>
  );
};

Errors.propTypes = {
  errors: PropTypes.instanceOf(Object)
};

const SpreadsheetImport = ({
  isOpen,
  onClose,
  model,
  idRefurbish,
  onLoad = f => f,
  type,
  notUpdate,
  configParams,
  origin,
  body,
  showToastError,
  canNotify = true,
  toKebabCase = true
}) => {
  const [importing, setImporting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openImport, setOpenImport] = useState(isOpen);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState(null);

  const { validators, formatters, fields, title, rowHook, customChunk } =
    (config[model]?.[type] || config[model])(configParams) || {};
  ptBR.uploadStep.title = title || ptBR.uploadStep.title;
  const { user } = useSelector(state => state.authReducer) || {};
  const importedCountRef = useRef(0);
  const importedChunkRef = useRef(0);
  const createdModelData = useRef({});
  const gtmEventService = ProjectOrOpportunityEventService();

  useEffect(() => {
    setOpenImport(isOpen);
  }, [isOpen]);

  const { handleCreate: handleCreateNotification } = useCRUD({
    model: 'send-notification',
    immediatelyLoadData: false
  });

  const { handleCreate } = useCRUD({
    model: toKebabCase ? toKebabCaseFn(model) : model,
    pathOptions: '/import',
    showToast: false,
    immediatelyLoadData: false
  });
  const addErrors = (_errors, key, msg, code) => {
    const msgs = _errors[key]?.messages || [];

    return { ..._errors, ...{ [key]: { messages: [...msgs, msg], code } } };
  };

  const handleNotify = () => {
    if (!canNotify) return null;

    return handleCreateNotification({
      postPathOptions: '/import',
      values: {
        model,
        idRefurbish
      },
      refresh: false
    });
  };

  const handleCloseModal = () => {
    setOpenImport(false);
    setImporting(false);
    setSuccess(null);
    setErrors(null);
    importedCountRef.current = 0;
    importedChunkRef.current = 0;
    createdModelData.current = {};
  };
  const mapErrors = responseData => {
    if (!responseData) return {};
    let _errors = {};

    if (responseData?.statusCode === 400 && !responseData?.forEach) {
      return addErrors(_errors, 400, `${responseData?.message}`, responseData?.code);
    }
    if (responseData.statusCode === 500 || !responseData.forEach) {
      return addErrors(_errors, 500, 'Ocorreu um erro durante a importação');
    }

    responseData.forEach(p => {
      if (p.details) {
        p.details.forEach(d => {
          const { message } = d || {};
          const { key, valids } = d.context || {};
          const { label } = fields.find(item => item.key === key) || {};

          if (message?.indexOf('is not allowed to be empty') > 0) {
            _errors = addErrors(_errors, key, `"${label}" é obrigatório`);
          } else if (Array.isArray(valids)) {
            const vals = valids.join(', ');
            _errors = addErrors(_errors, key, `"${label}" aceita apenas os valores: ${vals}`);
          } else {
            _errors = addErrors(_errors, key, message);
          }
        });
      }

      if (p?.name === 'CustomError' && p.message) {
        _errors = addErrors(_errors, 400, p.message);
      }
    });

    return _errors;
  };

  const sendGtmEvent = (status, message) => {
    gtmEventService.onImport({
      entity: model,
      idUser: user.id,
      idCompany: user.company.id,
      itemCount: importedCountRef.current,
      status,
      message,
      origin
    });
  };

  const formatDataCreateModel = createdData => {
    createdModelData.current = Object.keys(createdData).reduce((acc, key) => {
      return { ...acc, [key]: (acc[key] || 0) + (createdData[key] || 0) };
    }, createdModelData.current);
  };

  const createChunk = async (data, file) => {
    const rows =
      validators || formatters
        ? data.map(row => {
            const validatedRow = row;
            Object.entries(validators || {}).forEach(([key, validator]) => {
              const field = typeof row[key] === 'string' ? row[key].toLowerCase() : row[key];
              validatedRow[key] = Object.prototype.hasOwnProperty.call(validator, field)
                ? validator[field]
                : validator.default;
            });

            Object.entries(formatters || {}).forEach(([key, formatter]) => {
              validatedRow[key] = formatter(validatedRow[key]);
            });

            return validatedRow;
          })
        : data;

    return handleCreate({
      values: {
        rows,
        idRefurbish,
        notUpdate,
        v2: configParams?.v2,
        fileName: file?.name,
        chunk: importedChunkRef.current,
        ...(body && body)
      },
      refresh: false,
      customCatch: true
    })
      .then(response => {
        response.newCreate && formatDataCreateModel(response.newCreate);

        importedCountRef.current += rows.length;
      })
      .catch(err => {
        throw err;
      });
  };
  const onSuccess = () => {
    setLoading(false);
    toast.success('Importação realizada com sucesso');
    if (importedCountRef.current > 0) handleNotify();
    setSuccess({
      imported: importedCountRef.current,
      createsNews: createdModelData.current || {}
    });
    sendGtmEvent('success');
  };
  const onError = err => {
    showToastError && toast.error('Erro ao processar a importação');
    setLoading(false);

    sendGtmEvent('error', err?.message || err?.response?.data?.message || err?.response?.message);
    if (importedCountRef.current > 0) handleNotify();
    setErrors({
      imported: importedCountRef.current,
      errorMap: mapErrors(err?.response?.data)
    });
  };

  const handleImportSubmit = async ({ validData, file }) => {
    setLoading(true);
    setImporting(true);
    let withError = false;
    const parentAssociatedRows = configParams?.v2 ? parentAssociation({ data: validData, key: 'number' }) : null;
    const arrarInChunk = (customChunk || chunk)(parentAssociatedRows || validData, 100);
    importedCountRef.current = 0;
    // eslint-disable-next-line
    for (const _chunk of arrarInChunk) {
      try {
        // eslint-disable-next-line
        await createChunk(_chunk, file);
        importedChunkRef.current += 1;
      } catch (error) {
        onError(error);
        withError = true;
        break;
      }
    }
    if (!withError) onSuccess();
    onLoad();
  };

  const handleReset = () => {
    setErrors(null);
    setSuccess(null);
    setLoading(false);
    setImporting(false);
    setOpenImport(true);
    importedCountRef.current = 0;
    createdModelData.current = {};
  };

  return (
    <>
      <ReactSpreadsheetImport
        isOpen={openImport}
        onClose={() => {
          setOpenImport(false);
          onClose();
        }}
        onSubmit={(data, file) => handleImportSubmit({ ...data, file })}
        fields={fields}
        translations={ptBR}
        rowHook={rowHook}
        customTheme={{
          colors: {
            rsi: {
              50: colors.primary100,
              100: colors.primary200,
              200: colors.primary300,
              300: colors.primary400,
              400: colors.primary500,
              500: colors.primary600,
              600: colors.primary700,
              700: colors.primary800,
              800: colors.primary900,
              900: colors.primary1000
            }
          }
        }}
      />
      <Modal
        open={importing}
        loading={loading}
        loadingText="Aguarde, estamos importando os dados!"
        onClose={handleCloseModal}
        submitText="Escolher outro arquivo"
        onSubmit={handleReset}
        title="Resultado da importação"
        cancelText="Fechar"
      >
        <>
          <Success success={success} onReset={handleReset} />
          <Errors errors={errors} onReset={handleReset} />
        </>
      </Modal>
    </>
  );
};

SpreadsheetImport.propTypes = {
  model: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  idRefurbish: PropTypes.number,
  onLoad: PropTypes.func,
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  notUpdate: PropTypes.bool,
  configParams: PropTypes.instanceOf(Object),
  origin: PropTypes.string,
  body: PropTypes.instanceOf(Object),
  showToastError: PropTypes.bool,
  canNotify: PropTypes.bool,
  toKebabCase: PropTypes.bool
};
export default SpreadsheetImport;
