import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from './Modal';
import useCRUD from '../../_Hooks/useCRUD';
import LinkedTaskSelection from '../Select/LinkedTaskSelection';
import HowDoesItWorksButton from '../Button/HowDoesItWorksButton';
import Label from '../Label/Label';
import { Div, radius, spaces } from '../../styles/style';

const RenderLinkedTypeStyle = styled.div`
  display: contents;
  span {
    margin-left: ${spaces.space2};
    border-radius: ${radius.radius1};
    background: ${props => props.color};
  }
`;

const RenderLinkedType = ({ options }) => {
  return (
    <RenderLinkedTypeStyle color={options.step.refurbishSteps[0].color}>
      {options.name}
      <span>{options.step.name}</span>
    </RenderLinkedTypeStyle>
  );
};

RenderLinkedType.propTypes = {
  options: PropTypes.instanceOf(Object)
};

const LinkTaskModal = ({ row, onClose = f => f, reload = () => {}, generalTask, ...props }) => {
  const { id } = row;
  const [linkedTask, setLinkedTask] = useState();
  const { loading, handleCreate, handleDelete } = useCRUD({
    model: 'task',
    immediatelyLoadData: false
  });

  const handleDeleteLink = () => {
    handleDelete({
      values: { idChild: id, idParent: row?.idParent },
      deleteOptions: { where: { id } },
      deletePathOptions: '/connection'
    }).then(() => reload({}));
  };

  const handleLinkedTask = () => {
    if (!linkedTask) {
      handleDeleteLink();
    } else {
      handleCreate({
        values: { idChild: id, idParent: linkedTask, source: generalTask ? 'General' : 'Project' },
        postPathOptions: '/connection',
        refresh: false
      }).then(() => reload({}));
    }
    onClose();
  };

  return (
    <Modal
      onSubmit={handleLinkedTask}
      onClose={() => onClose(true)}
      open
      sectionStyle={{ padding: 0 }}
      title={
        <Div gap={spaces.space2}>
          Vincular tarefa com sua antecessora
          <HowDoesItWorksButton id="howPredecessorsWorks" />
        </Div>
      }
      width={530}
      loading={loading}
    >
      <Label>Essa tarefa será dependente das datas da tarefa antecessora escolhida</Label>
      <LinkedTaskSelection
        style={{ marginTop: '16px' }}
        id={id}
        setLinkedTask={setLinkedTask}
        linkedTask={linkedTask}
        {...props}
      />
    </Modal>
  );
};

LinkTaskModal.propTypes = {
  onClose: PropTypes.func,
  row: PropTypes.instanceOf(Object),
  reload: PropTypes.func,
  onRefresh: PropTypes.func,
  generalTask: PropTypes.bool
};

export default LinkTaskModal;
