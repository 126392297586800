import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { breakpoints, colors, radius, spaces } from '../../styles/style';
import SelectDropdown from '../Dropdown/SelectDropdown';

const EditableDiv = styled.div`
  padding: ${props => (props.$showBord ? spaces.space0 : null)};
  ${props =>
    props.$withBorder
      ? css`
          border: 1px solid ${colors.neutral200};
          border-radius: ${radius.radius1};
        `
      : css`
          border: 1px solid transparent;
        `};
  white-space: ${props => (props.noWrap ? 'nowrap' : null)};
  min-height: ${props => props.$minHeight || '28px'};
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 100%;
  justify-content: ${props => props.align || ''};
  ${props => props.$backgroundColor && `background-color: ${props.$backgroundColor};`}

  > svg {
    display: none;
    margin-left: ${spaces.space1};
  }

  &:hover {
    cursor: pointer;
    border: ${props => (props.$showBord && !props.disabled ? `1px solid ${colors.neutral500}` : null)};
    border-radius: ${radius.radius1};
    > svg {
      display: block;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 8px;
  }
`;

const ColumnWrapper = ({
  value,
  _key,
  model,
  options = { where: { isActive: true } },
  onSelect,
  loadList,
  children,
  cleanValue = 1,
  showClean = true,
  disabled,
  align,
  id,
  allowPropagation = false,
  withBorder,
  backgroundColor,
  minHeight,
  ...props
}) => {
  const [isEdit, setEdit] = useState(false);

  const handleClick = e => {
    if (!allowPropagation) e.stopPropagation();
    setEdit(true);
  };

  const handleRemove = e => {
    e.stopPropagation();
    onSelect(cleanValue);
    setEdit(false);
  };

  return isEdit && !disabled ? (
    <SelectDropdown
      loadList={loadList}
      key={`${model}${value?.id}`}
      model={model}
      options={options}
      onSelect={val => {
        onSelect(val[_key || 'id'], val?.isNewRecord, val);
        setEdit(false);
      }}
      data={value}
      isOpen
      onClose={() => setEdit(false)}
      cleanValue={cleanValue}
      showClean={showClean}
      {...props}
    >
      {children}
    </SelectDropdown>
  ) : (
    <>
      <EditableDiv
        id={id}
        role="presentation"
        onClick={handleClick}
        align={align}
        $showBord={!children}
        disabled={disabled}
        $withBorder={withBorder}
        $backgroundColor={backgroundColor}
        $minHeight={minHeight}
      >
        {children || value?.name || '-'}
        {showClean && !disabled && (children || value) ? (
          <FontAwesomeIcon icon={faTimes} onClick={handleRemove} />
        ) : null}
      </EditableDiv>
    </>
  );
};

ColumnWrapper.propTypes = {
  value: PropTypes.instanceOf(Object),
  _key: PropTypes.string,
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  loadList: PropTypes.instanceOf(Array),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cleanValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  showClean: PropTypes.bool,
  id: PropTypes.string,
  align: PropTypes.string,
  allowPropagation: PropTypes.bool,
  withBorder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  minHeight: PropTypes.string
};

export default ColumnWrapper;
