import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { Divider } from 'antd';
import { colors, spaces } from '../../styles/style';
import Button from './Button';

const IconCog = styled(FontAwesomeIcon)`
  color: ${colors.primary600};
  padding-right: ${spaces.space1};
`;

const SelectDivider = styled(Divider)`
  margin: ${props => (props.noMargin ? 0 : spaces.space1)} 0;
`;

const AddSelectButton = ({ onClick, noMargin }) => {
  return (
    <>
      <SelectDivider noMargin={noMargin} />
      <Button
        type="primary"
        text
        style={{ height: '100%' }}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
      >
        <IconCog icon={faCog} />
        Editar opções
      </Button>
    </>
  );
};

AddSelectButton.propTypes = {
  onClick: PropTypes.func,
  noMargin: PropTypes.bool
};

export default AddSelectButton;
