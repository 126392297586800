import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundColorDiv, BackgroundColorText } from './BackgroundColorLabel.styled';

const BackgroundColorLabel = ({ children, color }) => {
  return (
    <BackgroundColorDiv>
      {color ? <BackgroundColorText $color={color}>{children}</BackgroundColorText> : children}
    </BackgroundColorDiv>
  );
};

BackgroundColorLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Object)]),
  color: PropTypes.string
};

export default BackgroundColorLabel;
