import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Tooltip } from 'antd';

import { colors } from '../../styles/style';

import Draggable from '../Draggable/Draggable';
import EditTaskModal from './EditTaskModal';
import eventBus from '../../lib/helpers/eventBus';
import { DraggableDiv, StyledFloatingButton } from './AddTaskModal.styles';
import DropdownV2 from '../Dropdown/DropdownV2';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';
import Button from '../Button/Button';
import AddPerformedHourDropdown from '../Dropdown/AddPerformedHourDropdown';

const today = new Date(new Date().setHours(0, 0, 0, 0));
const tomorrow = new Date(new Date().setHours(24, 0, 0, 0));

const AddTaskModal = ({
  refurbish,
  filter,
  generalTask,
  handleAdd,
  isMobileOrTablet,
  onRefresh,
  afterSubmit,
  idCompany
} = {}) => {
  const [editModalId, setEditModalId] = useState(null);
  const [defaultPositionX, setDefaultPositionX] = useState(window.innerWidth - 200);
  const addText = generalTask ? 'Tarefa adicionada no topo da lista.' : 'Tarefa adicionada.';

  const onMenuCollapsed = data => {
    const offset = data?.detail?.collapsed ? 200 : 375;
    setDefaultPositionX(window.innerWidth - offset);
  };

  useEffect(() => {
    eventBus.on('menuCollapsed', onMenuCollapsed);
    eventBus.dispatch('isMenuCollapsed');
    return () => eventBus.remove('menuCollapsed', onMenuCollapsed);
  }, []);

  const getInitData = _filter =>
    _filter?.endDate?.gte?.valueOf?.() === today?.valueOf?.() &&
    _filter?.endDate?.lte?.valueOf?.() === tomorrow?.valueOf?.()
      ? { status: 1, idRefurbishStep: 1, idRefurbish: refurbish?.id, startDate: today, endDate: tomorrow }
      : { status: 1, idRefurbishStep: 1, idRefurbish: refurbish?.id };

  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      {!generalTask && (
        <React.Fragment key="menuStep">
          <Menu.Item key="itemMenuStep">
            <Button id="draggable-add-step" type="link" onClick={() => handleAdd(null, false, {})}>
              Adicionar Etapa
            </Button>
          </Menu.Item>
        </React.Fragment>
      )}
      <React.Fragment key="menuTask">
        <Menu.Item key="itemMenuTask">
          <Button id="draggable-add-task" type="link" onClick={() => setEditModalId(0)}>
            Adicionar Tarefa
          </Button>
        </Menu.Item>
      </React.Fragment>
      <React.Fragment key="menuHour">
        <Menu.Item key="itemMenuHour">
          <AddPerformedHourDropdown idCompany={idCompany} afterSubmit={afterSubmit} generalTask={generalTask} />
        </Menu.Item>
      </React.Fragment>
    </StyledMenu>
  );

  const DraggableComponent = !isMobileOrTablet ? Draggable : DraggableDiv;

  const AddTaskBtn = () => (
    <DraggableComponent onClick={() => {}} defaultPosition={{ x: defaultPositionX, y: 0 }}>
      <div
        style={{
          width: '48px',
          height: '48px',
          position: 'fixed',
          backgroundColor: colors.primary600,
          display: 'flex',
          alignItems: 'center',
          borderRadius: 24,
          bottom: 24,
          zIndex: 5
        }}
      >
        <DropdownV2 slim trigger={['click']} menu={menu}>
          <StyledFloatingButton type="primary" text>
            <Tooltip placement="top" title="Nova Etapa/Tarefa">
              <FontAwesomeIcon icon={faPlus} rotation={90} size="2x" color={colors.white} />
            </Tooltip>
          </StyledFloatingButton>
        </DropdownV2>
      </div>
    </DraggableComponent>
  );

  return (
    <>
      <AddTaskBtn />
      {editModalId != null && (
        <EditTaskModal
          id={editModalId}
          canChangeRefurbish={!refurbish?.id}
          initData={getInitData(filter)}
          idRefurbish={refurbish?.id}
          referKey="idRefurbish"
          generalTask={generalTask}
          title={!editModalId ? 'Nova tarefa' : 'Editar'}
          onClose={task => {
            setEditModalId(null);
            if (task?.id) {
              const _msg = editModalId ? 'Tarefa editada com sucesso.' : addText;
              toast.success(_msg, {
                position: 'top-right',
                autoClose: 10000
              });
              if (onRefresh) onRefresh(task);
            }
          }}
          copyLinkParam={null}
        />
      )}
    </>
  );
};

AddTaskModal.propTypes = {
  refurbish: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
  generalTask: PropTypes.bool,
  handleAdd: PropTypes.func,
  onRefresh: PropTypes.func,
  isMobileOrTablet: PropTypes.bool,
  idCompany: PropTypes.number,
  afterSubmit: PropTypes.func
};

export default AddTaskModal;
